import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { getEventLabel } from 'src/utils/event-type';
import { getLanguageLabel } from 'src/utils';
import backendApi from './config/backend';
import { IBookingEvent } from '../types';
import { useGetBookings } from './bookings-api';
import { useGetOfferingSubjectRatings } from './assessment-api';
import cache from './config/cache';
import { useGetClusterRecommendations } from './recommendation-api';

type OfferingsSortBy = 'DATE' | 'RECOMMENDATION';

const getOfferings = async (
  sort?: OfferingsSortBy,
  clustering?: 'ON' | 'OFF',
): Promise<IBookingEvent[]> => {
  const result = await backendApi.get('/offerings', {
    params: {
      sort,
      clustering,
    },
  });
  return result.data;
};

export const useGetOfferings = (sort: OfferingsSortBy) => {
  const { data: bookings } = useGetBookings();
  const { data: ratings } = useGetOfferingSubjectRatings();
  const { t } = useTranslation();

  const clustering = useGetClusterRecommendations();

  const query = useQuery<IBookingEvent[]>(
    ['offerings', sort],
    () => getOfferings(sort, clustering),
    {
      staleTime: cache.ONE_HOUR,
      select: (data) => {
        //  TODO: remove this when duplicated offerings are removed from backend
        const uniqueOfferingsById = [
          ...new Map(data.map((item) => [item?.id, item])).values(),
        ];

        return uniqueOfferingsById.map((offering, index, list) => {
          const rating = ratings?.find(
            (rating) =>
              rating.offeringSubjectId === offering.offeringSubject?.id,
          );
          const hasOtherDates =
            list.filter(
              (item) =>
                item.offeringSubject?.id === offering.offeringSubject?.id,
            ).length > 1;

          return {
            ...offering,
            isRecommended:
              bookings?.some(
                (booking) => booking?.offeringId === offering?.id,
              ) || offering.offeringSubject?.beginnerFriendly
                ? false
                : offering.isRecommended,
            isBeginnerFriendly: offering.offeringSubject?.beginnerFriendly,
            hasOtherDates,
            isRecurring: hasOtherDates && offering.offeringSubject?.recurring,
            isNew: offering?.offeringSubject?.isNew,
            isFull: offering?.maxParticipants === offering?.numberOfBookings,
            ranking: sort === 'RECOMMENDATION' ? index + 1 : undefined,
            offeringSubject: {
              ...offering.offeringSubject,
              ...(rating && {
                rating: rating.averageRating,
                bookings: rating.totalBookings,
              }),
            },
            topicsLabel: offering?.topics?.map((topic) =>
              t(`v2.global.chip_label_topic_${topic}`),
            ),
            typeLabel: getEventLabel(offering.type),
            languageLabel: getLanguageLabel(offering.language),
          };
        });
      },
    },
  );

  return query;
};
