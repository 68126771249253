import { useTranslation } from 'react-i18next';
import { orderBy } from 'lodash';
import { EventType, IBookingEvent } from 'src/types';

const useEventTypeFilter = (upcomingEvents: Array<IBookingEvent>) => {
  const { t } = useTranslation();

  // eslint-disable-next-line unicorn/prefer-set-has
  const availableEventTypes = upcomingEvents.flatMap(
    (event: IBookingEvent) => event.type,
  );

  const options = Object.entries(EventType)
    .filter(([, value]) => availableEventTypes.includes(value))
    .map(([, value]) => ({
      value,
      name: t(
        `v2.global.label_${
          value === EventType.EXCHANGE_GROUP
            ? 'exchangeGroup'
            : value.toLowerCase()
        }`,
      ),
    }));

  const typeFilterOptions = orderBy(options, 'name');

  return {
    name: 'type',
    label: t('v2.global.filters_dropdown_label_formats_filterCount'),
    options: typeFilterOptions,
    multiple: true,
    selectAll: {
      name: t('v2.global.filterChip_label_eventType'),
      value: '',
    },
    defaultValue: '',
  };
};

export default useEventTypeFilter;
