import { makeStyles } from 'tss-react/mui';
import { ITheme } from 'src/style/theme';

export const useStyles = makeStyles()((theme: ITheme) => ({
  legalLinksWrapper: {
    display: 'flex',
    alignItems: 'center',
    margin: 'auto',
    padding: '40px 0',
    gridArea: 'footer',
  },
  linkItem: {
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      color: theme.colors.edward,
      '& path ': {
        fill: theme.colors.edward,
      },
    },
  },
  linkLabel: {
    color: theme.colors.edward,
    padding: 16,
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },
  divider: {
    color: theme.colors.edward,
  },
}));
