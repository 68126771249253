import { makeStyles } from 'tss-react/mui';

interface StyleProps {
  hasSecondaryBanner?: boolean;
}

export const useStyles = makeStyles<StyleProps>()(
  (theme, { hasSecondaryBanner }) => ({
    secondaryHeader: {
      textAlign: 'center',
      fontWeight: 700,
      lineHeight: '18px',
      padding: '14px 0',
      fontSize: '14px',
    },
    secondaryHeaderScrolled: {
      position: 'fixed',
      zIndex: 2000,
      width: 657,
      top: 68,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        display: 'none',
      },
    },
    tertiaryHeaderScrolled: {
      position: 'fixed',
      zIndex: 2000,
      width: 657,
      top: hasSecondaryBanner ? 180 : 132,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        display: 'none',
      },
    },
    primaryHeader: {
      display: 'flex',
      padding: 16,
      height: 95,
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      flexWrap: 'wrap',
      color: theme.palette.common.white,
    },
    primaryHeaderScrolled: {
      top: hasSecondaryBanner ? 114 : 68,
      gap: 4,
      padding: '8px 0',
      height: 'auto',
      position: 'fixed',
      alignSelf: 'flex-start',
      width: 657,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        gap: 2,
        top: 68,
      },
      zIndex: 10,
      transition: 'all .5s ease-in-out',
    },
    hide: {
      display: 'none',
    },
  }),
);
