import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SmallCard from '../shared/small-card/small-card';
import { Feedback } from './feedback-recommendations.tsx';
import useStyles from './styles';
import StarReview from '../shared/star-review/star-review';

const FeedbackCard = ({ rating, description, offeringName }: Feedback) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  return (
    <SmallCard className={classes.feedbackCardCustom}>
      <StarReview rating={rating} />
      <Typography variant="caption">{t(description)}</Typography>
      <Typography variant="buttonSmall">{t(offeringName)}</Typography>
    </SmallCard>
  );
};

export default FeedbackCard;
