import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';
import OfferingFormatDetails from './offering-format-details';
import useGetOfferingFormatDetails from '../use-get-format-details';
import FormatInfoDrawerHeader from '../format-info-drawer-header';

const EventsInfo = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const formatDetails = useGetOfferingFormatDetails();

  return (
    <Box className={classes.root}>
      <FormatInfoDrawerHeader
        title={t(
          'v2.dashboard.section_eventList_label_subtitle_workshopsAndWebinars',
        )}
        subtitle={t('v2.offeringOverview.drawer_label_subtitle')}
      />
      {formatDetails.map((formatDetail, index) => (
        <OfferingFormatDetails
          key={formatDetail.offeringFormat}
          offeringFormatDetails={formatDetail}
          className={classes.accordionBar}
          defaultOpen={index === 0}
        />
      ))}
    </Box>
  );
};

export default EventsInfo;
