import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Widget from 'src/components/shared/widget/widget';
import SmallCard from 'src/components/shared/small-card/small-card';
import { useAppConfiguration } from 'src/hooks/use-app-configuration';
import useBookingWidget from './use-booking-widget';

const BookingWidget = () => {
  const {
    handleDismiss,
    handleSeeDetails,
    handleConfirm,
    widgetOffering,
    trackWidgetExpanded,
  } = useBookingWidget();
  const { showBookingWidget } = useAppConfiguration();
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const drawerBleedingByLanguage = {
    en: 116,
    de: 126,
  }[language as 'en' | 'de'];

  if (!showBookingWidget || !widgetOffering) return null;

  return (
    <Widget
      title={t('v2.dashboard.recoTab_widget_label_title')}
      onDismiss={handleDismiss}
      onExpand={trackWidgetExpanded}
      bleeding={drawerBleedingByLanguage}
      delay={90}
      content={
        <Box display="grid" gap="24px">
          {t('v2.dashboard.recoTab_widget_label_body')}
          <SmallCard onClick={() => handleSeeDetails(widgetOffering)}>
            <SmallCard.Offering
              offering={widgetOffering}
              onConfirm={handleConfirm}
            />
          </SmallCard>
        </Box>
      }
    />
  );
};

export default BookingWidget;
