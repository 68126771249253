import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { getLanguageLabel } from 'src/utils';
import { SupportedLanguages } from 'src/translations/i18n';
import { camelCase } from 'lodash';
import backendApi from './config/backend';
import { TopicKey } from './topics-api';
import cache from './config/cache';
import { useGetClusterRecommendations } from './recommendation-api';

type MentorDetailType =
  | 'work_experience'
  | 'education'
  | 'education_background'
  | 'psychological_approach'
  | 'description';

interface MentorDetail {
  id: string;
  language: SupportedLanguages;
  type: MentorDetailType;
  value: string | { title: string; subtitle: string }[];
}

export interface Mentor {
  id: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  email: string;
  profileImage: string;
  mentorDetails: MentorDetail[];
  languages?: Array<string>;
  focusTopics?: TopicKey[];
  isRecommended: boolean;
  ranking?: number;
  bookingUrl?: string;
  focusTopicsLabels?: string[];
  languagesLabels?: string[];
  description?: string;
  education?: string;
}

interface MentorApiResponse {
  data: Mentor[];
}

interface GetMentors {
  language: string;
  clustering: 'ON' | 'OFF';
}

const getMentors = async ({
  language,
  clustering,
}: GetMentors): Promise<Mentor[]> => {
  const mentors: MentorApiResponse = await backendApi.get('/mentors', {
    params: { language, clustering },
  });

  return mentors?.data;
};

export const useGetMentors = () => {
  const {
    i18n: { language },
    t,
  } = useTranslation();
  const clustering = useGetClusterRecommendations();

  return useQuery({
    queryKey: ['mentors', language, clustering],
    queryFn: () => getMentors({ language, clustering }),
    staleTime: cache.ONE_HOUR,
    select: (data) => {
      return data?.map((mentor: Mentor, index: number) => {
        const focusTopicsLabels = mentor?.focusTopics?.map((item) =>
          t(`v2.global.chip_label_topic_${item}`),
        );

        const languagesLabels = mentor?.languages?.map(
          (lang) => getLanguageLabel(lang) as string,
        );

        const description = mentor?.mentorDetails?.find(
          ({ type }) => type === 'description',
        )?.value;

        const education = mentor?.mentorDetails?.find(
          ({ type }) => type === 'education',
        )?.value;

        const psychologicalApproach = mentor?.mentorDetails?.find(
          ({ type }) => type === 'psychological_approach',
        )?.value;

        const workExperience = mentor?.mentorDetails?.find(
          ({ type }) => type === 'work_experience',
        )?.value;

        const educationBackground = mentor?.mentorDetails?.find(
          ({ type }) => type === 'education_background',
        )?.value;

        const mentorEducationLabel = t(
          `v2.mentorOverview.drawer_mentorDetails_label_${camelCase(
            mentor?.mentorEducation,
          )}`,
        );

        return {
          ...mentor,
          ranking: index + 1,
          focusTopicsLabels,
          languagesLabels,
          description,
          education,
          psychologicalApproach,
          workExperience,
          educationBackground,
          mentorEducationLabel,
          isRecommended: index < 2,
        };
      });
    },
  });
};
