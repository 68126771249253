import { useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { find } from 'lodash';
import { useNavigate } from 'react-router-dom';
import useSearchParams from 'src/hooks/use-search-params';
import useDrawerScroll from 'src/hooks/use-drawer-scroll';
import { useGetUser } from 'src/apis/users-api';
import { useGetBookings, useCreateBooking } from 'src/apis/bookings-api';
import useAppDrawer from 'src/app/use-app-drawer';
import { useGetOfferings } from 'src/apis/offerings-api';
import OfferingDrawer from './offering-drawer';
import OfferingDrawerFooter from './offering-drawer-footer';
import { useStyles } from './styles';

const useOfferingDrawer = () => {
  const { searchParams, setSearchParam } = useSearchParams();
  const utmCampaign = searchParams.get('utm_campaign') ?? undefined;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const eventId = searchParams.get('event-id');
  const { scrolled, onScroll } = useDrawerScroll({
    resetBy: eventId,
  });
  const { data: myBookings } = useGetBookings();
  const { data: user } = useGetUser();
  const { data: offerings, isLoading } = useGetOfferings();
  const offering = offerings?.find((item) => item.id === eventId);
  const { classes } = useStyles();

  const onClose = () => {
    setSearchParam({ 'event-id': null, name: null, utm_campaign: null });
  };

  const { mutate: createBooking } = useCreateBooking({ onClose });

  const [isCreditsModalOpen, toggleCreditsModalOpen] = useReducer(
    (open) => !open,
    false,
  );

  const offeringPrice = offering?.price ?? 0;
  const shouldRequestSessions = user && user.creditBalance < offeringPrice;

  const isBooked = Boolean(
    find(myBookings, (item) => item.offeringId === eventId),
  );

  const isFullyBooked = offering?.maxParticipants <= offering?.numberOfBookings;

  const notAvailableOfferings =
    user?.subscription?.plan?.tiers?.find(
      (plan) => plan.type === 'COMMUNITY_SESSION',
    )?.option === 'NotAvailable';

  const ctaDisabled = isBooked || isFullyBooked || notAvailableOfferings;

  const ctaSecondary =
    isBooked && t('v2.offeringOverview.drawer_label_button_manageBooking');

  const handleManageBookings = () => navigate('/bookings');

  const handleBookEvent = () => {
    if (shouldRequestSessions) {
      return toggleCreditsModalOpen();
    }

    createBooking({
      ...offering,
      bookingSource: utmCampaign,
    });
  };

  return useAppDrawer({
    params: ['event-id', 'name', 'utm_campaign'],
    value: eventId,
    onScroll,
    content: !isLoading && offering && (
      <OfferingDrawer
        scrolled={scrolled}
        onClose={onClose}
        isBuyMoreModalOpen={isCreditsModalOpen}
        toggleBuyMoreModalOpen={toggleCreditsModalOpen}
        offering={offering}
      />
    ),
    footer: !isLoading && offering && (
      <OfferingDrawerFooter
        onConfirm={handleBookEvent}
        ctaDisabled={ctaDisabled}
        ctaSecondary={ctaSecondary}
        onCtaSecondary={handleManageBookings}
        disabledTooltip={
          notAvailableOfferings &&
          t('v2.global.button_tooltip_noAccess_wellhub')
        }
      />
    ),
    forceLoadingTime: 500,
    notFound: !isLoading && !offering,
    ctaWrapperClassName: classes.ctaWrapperClassName,
  });
};

export default useOfferingDrawer;
