import { useQuery } from '@tanstack/react-query';
import { sortByOrder } from 'src/utils';
import backendApi from './config/backend';
import cache from './config/cache';

export type TopicKey =
  | 'stressmanagement'
  | 'leadership'
  | 'burnoutanddepression'
  | 'fearsandworries'
  | 'familypartnershipandotherrelationships'
  | 'jobandcareer'
  | 'goalsandvalues'
  | 'selfesteem'
  | 'identityanddiversity'
  | 'bodyandmind';

export interface Topic {
  id: string;
  createdAt: string;
  updatedAt: string;
  key: string;
  deleted: boolean;
}

interface TopicsApi {
  content: Topic[];
}

const SORTED_TOPIC_KEYS = [
  'stressmanagement',
  'leadership',
  'burnoutanddepression',
  'fearsandworries',
  'familypartnershipandotherrelationships',
  'jobandcareer',
  'goalsandvalues',
  'selfesteem',
  'identityanddiversity',
  'bodyandmind',
];

const getTopics = async (): Promise<TopicsApi> => {
  const result = await backendApi.get('/topics');
  return result.data;
};

export const useGetTopics = () => {
  return useQuery<TopicsApi>({
    queryKey: ['topics'],
    queryFn: getTopics,
    staleTime: cache.ONE_HOUR,
    select: (data) => {
      return sortByOrder(data?.content, SORTED_TOPIC_KEYS, 'key');
    },
  });
};
