import { Box, Button, Typography } from '@mui/material';
import { IBookingEvent } from 'src/types';
import { useTranslation } from 'react-i18next';
import { getEventLabel } from 'src/utils/event-type';
import { getEventDate } from 'src/utils/date-utils';
import TimeIcon from 'src/assets/icons/time.svg?react';
import DateIcon from 'src/assets/icons/date.svg?react';
import SessionIcon from 'src/assets/icons/session.svg?react';
import useStyles from './styles';

interface SmallCardOfferingProps {
  offering?: IBookingEvent;
  onConfirm?: (event: React.MouseEvent<HTMLElement>) => void;
}

const SmallCardOffering = ({ offering, onConfirm }: SmallCardOfferingProps) => {
  const { classes } = useStyles({});
  const { t } = useTranslation();
  const { type, duration, name, sessions } = offering ?? {};

  const date = getEventDate(sessions[0].startsAt);
  const typeLabel = getEventLabel(type);
  const durationLabel = t('v2.global.label_minutes', { count: duration });
  const sessionLabel = t('v2.global.label_session', {
    count: sessions.length,
  });

  return (
    <>
      <Box className={classes.header}>
        <Typography variant="buttonSmall">{typeLabel}</Typography>
        <Typography variant="buttonSmall" className={classes.duration}>
          <TimeIcon />
          {durationLabel}
        </Typography>
      </Box>

      <Typography variant="h4" className={classes.title}>
        {name}
      </Typography>

      <Box className={classes.subtitle}>
        <Typography variant="buttonSmall" className={classes.subtitleDetail}>
          <DateIcon />
          {date}
        </Typography>
        <Typography variant="buttonSmall" className={classes.subtitleDetail}>
          <SessionIcon />
          {sessionLabel}
        </Typography>
      </Box>

      {onConfirm && (
        <Box className={classes.prebook}>
          <Button variant="contained" onClick={onConfirm}>
            {t('v2.global.button_label_confirmAttendance')}
          </Button>
          <Typography variant="buttonDefault" className={classes.seeDetails}>
            {t('v2.global.button_label_seeEventDetails')}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default SmallCardOffering;
