import React from 'react';
import clsx from 'clsx';
import Button from '@mui/material/Button';
import { Box, Dialog, Typography } from '@mui/material';
import MuiLink from '@mui/material/Link';
import { useStyles } from './styles';

export interface ModalWithConfirmationProps {
  isOpen: boolean;
  title?: React.ReactNode;
  text?: React.ReactNode;
  href?: string;
  confirmBtnLabel?: string;
  cancelBtnlabel?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  onClose: () => void;
  snackbar?: React.ReactNode;
  header?: React.ReactNode;
  customClasses?: Record<string, string>;
  children?: React.ReactNode;
}

const ModalWithConfirmation = ({
  isOpen,
  title,
  text,
  href,
  confirmBtnLabel,
  cancelBtnlabel,
  onClose,
  onConfirm,
  onCancel,
  snackbar,
  header,
  customClasses = {},
  children,
}: ModalWithConfirmationProps) => {
  const { classes } = useStyles();

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      classes={{ paper: clsx(classes.paper, customClasses?.paper) }}
    >
      <>
        {header}
        {(title || text) && (
          <Box className={clsx(classes.topSection, customClasses?.topSection)}>
            <Typography
              className={clsx(classes.title, customClasses?.title)}
              variant="h2"
            >
              {title}
            </Typography>
            <Typography
              className={clsx(classes.description, customClasses?.description)}
            >
              {text}
            </Typography>
            {snackbar}
          </Box>
        )}

        {children}

        {confirmBtnLabel && (
          <Box
            className={clsx(
              classes.bottomSection,
              customClasses?.bottomSection,
            )}
          >
            {href ? (
              <Button
                className={clsx(
                  classes.submitButton,
                  customClasses?.submitButton,
                )}
                href={href}
                target="_blank"
                onClick={onConfirm}
              >
                {confirmBtnLabel}
              </Button>
            ) : (
              <Button
                className={clsx(
                  classes.submitButton,
                  customClasses?.submitButton,
                )}
                color="primary"
                variant="contained"
                onClick={onConfirm}
              >
                {confirmBtnLabel}
              </Button>
            )}

            <MuiLink component="a" className={classes.links} onClick={onCancel}>
              {cancelBtnlabel}
            </MuiLink>
          </Box>
        )}
      </>
    </Dialog>
  );
};

export default ModalWithConfirmation;
