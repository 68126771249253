import React from 'react';
import { useTranslation } from 'react-i18next';
import ModalWithConfirmation from 'src/components/shared/modal/modal-with-confirmation/modal-with-confirmation';
import useSelfPay from '../self-pay/use-self-pay';

interface RequestSessionsModalProps {
  showModal: boolean;

  toggleShow: (event: React.MouseEvent<HTMLElement>) => void;
}

const RequestSessionsModal = ({
  showModal,
  toggleShow,
}: RequestSessionsModalProps) => {
  const { t } = useTranslation();
  const selfPay = useSelfPay();

  const submitCreditRequest = () => {
    selfPay.onOpen({
      paramsRemoved: ['psychological-expert-id', 'name', 'utm_campaign'],
    });
    toggleShow();
  };

  const title = t('v2.global.modal_creditsPayment_requestCredits_label_title');
  const text = t('v2.global.modal_creditsPayment_requestCredits_label_body');
  const confirmBtnLabel = t('v2.global.button_label_buyCredits');

  return (
    <ModalWithConfirmation
      onClose={toggleShow}
      onCancel={toggleShow}
      isOpen={showModal}
      onConfirm={submitCreditRequest}
      title={title}
      text={text}
      confirmBtnLabel={confirmBtnLabel}
      cancelBtnlabel={t('v2.global.button_label_cancel')}
    />
  );
};

export default RequestSessionsModal;
