import { useTranslation } from 'react-i18next';
import DrawerHeader from 'src/components/shared/drawer-header/drawer-header';
import RecurringIcon from 'src/assets/icons/recurring.svg?react';
import { Box } from '@mui/material';
import MuiLink from '@mui/material/Link';
import { useGetBookings } from 'src/apis/bookings-api';
import { IBooking, IBookingEvent } from 'src/types';
import { useStyles } from './styles';
import OfferingHeaderDetails from './offering-header-details';

interface OfferingHeaderShrinkingProps {
  offering: IBookingEvent;
  scrolled?: boolean;
}

const OfferingHeaderShrinking = ({
  scrolled,
  offering,
}: OfferingHeaderShrinkingProps) => {
  const { t } = useTranslation();
  const {
    maxParticipants,
    numberOfBookings,
    id,
    isRecommended,
    isRecurring,
    isBeginnerFriendly,
  } = offering ?? {};
  const isFullyBooked = maxParticipants <= numberOfBookings;
  const offeringType = offering.type;
  const { data: myBookings } = useGetBookings();
  const isBooked = Boolean(
    myBookings?.find(({ offeringId }: IBooking) => offeringId === id),
  );

  const { classes } = useStyles({
    offeringType,
  });

  const showBanner = {
    fullyBooked: isFullyBooked,
    booked: isBooked && !isFullyBooked,
    recommended: isRecommended && !isBooked && !isFullyBooked,
    forStarters: isBeginnerFriendly && !isBooked && !isFullyBooked,
  };

  const handleShowOtherDates = () => {
    document
      .querySelector('#other-dates')
      ?.scrollIntoView({ block: 'center', behavior: 'smooth' });
  };

  const items = [
    {
      component: t('v2.global.label_eventFullyBooked'),
      className: classes.fullyBooked,
      show: showBanner.fullyBooked,
      type: 'secondary',
    },
    {
      component: t('v2.global.label_eventBooked'),
      className: classes.booked,
      show: showBanner.booked,
      type: 'secondary',
    },
    {
      component: t('v2.global.label_recommendedForYou'),
      className: classes.recommended,
      show: showBanner.recommended,
      type: 'secondary',
    },
    {
      component: t('v2.global.label_forStarters'),
      className: classes.forStarters,
      show: showBanner.forStarters,
      type: 'secondary',
    },
    {
      component: <OfferingHeaderDetails offering={offering} />,
      className: classes.offeringHeader,
      classNameScrolled: classes.offeringHeaderScrolled,
      isPrimaryHeader: true,
      type: 'primary',
    },
    {
      component: (
        <Box className={classes.reccuringLabel}>
          <RecurringIcon />
          {t('v2.eventDetails.drawer_recurringEvent_banner_label')}
          <MuiLink onClick={handleShowOtherDates}>
            {t('v2.eventDetails.drawer_recurringEvent_banner_cta')}
          </MuiLink>
        </Box>
      ),
      className: classes.recurring,
      classNameScrolled: classes.recurringScrolled,
      type: 'tertiary',
      show: isRecurring,
    },
  ];

  return <DrawerHeader items={items} scrolled={scrolled} />;
};

export default OfferingHeaderShrinking;
