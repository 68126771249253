import { Box, Typography, useMediaQuery } from '@mui/material';
import MentalWellnessIcon from 'src/assets/icons/mental-wellness.svg?react';
import CalmnessInnerPeaceIcon from 'src/assets/icons/calmness-inner-peace.svg?react';
import JoyfulMindsetIcon from 'src/assets/icons/joyful-mindset.svg?react';
import StressResilienceIcon from 'src/assets/icons/stress-resilience.svg?react';
import InfoIcon from 'src/assets/icons/Info.svg?react';
import BurnoutIcon from 'src/assets/icons/battery.svg?react';
import ChevronDownICon from 'src/assets/icons/chevron-down.svg?react';
import { useTranslation } from 'react-i18next';
import { createRef, useRef, useState } from 'react';
import {
  WellbeingDimensionKey,
  getWellbeingRecommendationKey,
  wellbeingRecommendationColors,
} from 'src/utils/wellbeing';
import { useWellbeingProgressStyles } from './styles';
import useWellbeingDrawer from '../use-wellbeing-drawer';
import useContainerDimensions from './use-container-dimensions';
import DimensionScore from './dimension-score';

interface WellbeingAttributes {
  mentalWellness: number;
  calmness: number;
  joyfulMindset: number;
  stressResilience: number;
  burnout?: number;
}

interface WellbeingProgressProps extends WellbeingAttributes {
  showDetails?: boolean;
  type: 'horizontal' | 'vertical';
}

const wellbeingIcons = {
  mentalWellness: <MentalWellnessIcon />,
  calmness: <CalmnessInnerPeaceIcon />,
  joyfulMindset: <JoyfulMindsetIcon />,
  stressResilience: <StressResilienceIcon />,
  burnout: <BurnoutIcon />,
};

const WellbeingProgress = (props: WellbeingProgressProps) => {
  const { type, showDetails } = props;
  const WELLBEING_DETAILS = [
    'mentalWellness',
    'calmness',
    'joyfulMindset',
    'stressResilience',
    'burnout',
  ];
  const elementRefs = useRef(
    WELLBEING_DETAILS.map(() => createRef<HTMLDivElement>()),
  );

  const isSmallDevice = useMediaQuery((theme) => theme.breakpoints.down(850));

  const wellbeingBarsDimensions = useContainerDimensions(elementRefs.current);

  const firstTwoRows = wellbeingBarsDimensions.slice(0, 2);

  const firstTwoRowsHeight = firstTwoRows.reduce(
    (acc, cur) =>
      isSmallDevice ? acc + cur.height : Math.max(acc, cur.height),
    0,
  );

  const { t } = useTranslation();
  const defaultShowMore = type !== 'horizontal';
  const [showMore, setShowMore] = useState(defaultShowMore);

  const { classes } = useWellbeingProgressStyles({
    showDetails,
    type,
    showMore,
    firstTwoRowsHeight,
  });

  const wellbeingDrawer = useWellbeingDrawer();

  const wellbeingDetails = WELLBEING_DETAILS.filter(Boolean).map((key) => {
    const dimension = key as WellbeingDimensionKey;
    const recommendationKey = getWellbeingRecommendationKey(
      props[dimension],
      dimension,
    );
    const disabled = typeof props[dimension] !== 'number';

    const feedbackText = disabled
      ? t('v2.mentalWellbeing.detailPage_label_scale_noResult')
      : t(`v2.mentalWellbeing.detailPage_label_scale_${recommendationKey}`);

    return {
      key,
      disabled,
      feedbackText,
      feedbackColor: wellbeingRecommendationColors[recommendationKey],
      icon: wellbeingIcons[dimension],
      score: props[dimension],
      description: t(
        `v2.mentalWellbeing.detailPage__topicDetailDrawer_label_${dimension}`,
      ),
    };
  });

  const handleShowWellbeingDetails = () => {
    wellbeingDrawer.onOpen();
  };

  const handleShowMore = () => {
    setShowMore(!showMore);
  };

  return (
    <Box className={classes.wellbeingProgress}>
      <Box className={classes.wellbeingProgressBars}>
        <Box className={classes.detailsTitle}>
          <Typography variant={type === 'horizontal' ? 'h3' : 'h4Bold'}>
            {showDetails
              ? t('v2.mentalWellbeing.detailPage__topicDetailDrawer_title')
              : t(
                  'v2.mentalWellbeing.detailPage_resultCard_label_topics_title',
                )}
          </Typography>

          {!showDetails && type === 'vertical' && (
            <Box
              className={classes.action}
              onClick={handleShowWellbeingDetails}
            >
              <InfoIcon />
              <Typography variant="buttonSmall">
                {t('v2.global.button_label_learnMore')}
              </Typography>
            </Box>
          )}

          {type === 'horizontal' && (
            <Box className={classes.action} onClick={handleShowMore}>
              <Typography variant="buttonSmall">
                {t(`v2.global.button_${showMore ? 'showLess' : 'showMore'}`)}
              </Typography>
              <ChevronDownICon className={classes.showMoreIcon} />
            </Box>
          )}
        </Box>

        <Box className={classes.progress}>
          {wellbeingDetails.map((wellbeinDetail, index) => (
            <Box ref={elementRefs.current[index]} key={wellbeinDetail.key}>
              <DimensionScore
                {...wellbeinDetail}
                dimension={wellbeinDetail.key}
                showDetails={showDetails}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default WellbeingProgress;
