import { ReactNode, useEffect, useState, UIEvent } from 'react';
import useSearchParams from 'src/hooks/use-search-params';
import {
  analyticEvents,
  DrawerOpenedProperties,
} from 'src/utils/analytic-events';
import { useLocation, useNavigate } from 'react-router-dom';

interface UseAppDrawerProps {
  params: string[];
  value?: string;
  track?: DrawerOpenedProperties;
  footer?: ReactNode;
  content: ReactNode;
  loading?: boolean;
  ctaWrapperClassName?: string;
  forceLoadingTime?: number;
  onScroll?: (event: UIEvent<HTMLElement>) => void;
  notFound?: boolean;
}

interface OnOpenProps {
  state?: Record<string, string>;
  paramsRemoved?: string[];
}
interface UseAppDrawerResult {
  onClose: () => void;
  onOpen: (openProps?: OnOpenProps) => void;
  footer?: ReactNode;
  content: ReactNode;
  loading?: boolean;
  ctaWrapperClassName?: string;
  showBack: boolean;
  onBack: () => void;
}

const setKeysToNull = (keys: string[] = []) =>
  keys.reduce(
    (acc: Record<string, null>, param: string) => ({
      ...acc,
      [param]: null,
    }),
    {},
  );

const useAppDrawer = ({
  params,
  value,
  track,
  content,
  footer,
  forceLoadingTime,
  ctaWrapperClassName,
  onScroll,
  notFound,
}: UseAppDrawerProps): UseAppDrawerResult => {
  const { setSearchParam, searchParams } = useSearchParams();
  const [loading, setLoading] = useState<boolean>(!!forceLoadingTime);
  const location = useLocation();
  const navigate = useNavigate();
  const [idParam] = params ?? [];

  const drawerUrlParam = searchParams.get(idParam);

  const onClose = () => {
    const removeParams = setKeysToNull(params);
    setSearchParam({ ...removeParams });
  };

  const onOpen = (onOpenProps?: OnOpenProps) => {
    if (!drawerUrlParam) {
      setSearchParam(
        {
          [idParam]: value ?? drawerUrlParam,
          ...setKeysToNull(onOpenProps?.paramsRemoved),
        },
        onOpenProps?.state,
      );

      if (track) {
        analyticEvents.drawerOpened(track);
      }
    }
  };

  useEffect(() => {
    if (notFound && drawerUrlParam) {
      onClose();
      navigate('/page-not-found');
    }
  }, [notFound]);

  useEffect(() => {
    if (drawerUrlParam) {
      onOpen();
    }
    if (forceLoadingTime) {
      setTimeout(() => {
        setLoading(false);
      }, forceLoadingTime);

      return () => {
        setLoading(true);
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerUrlParam]);

  const showBack = !!location?.state?.previous;

  const onBack = () => {
    navigate(-1);
  };

  return {
    onClose,
    onOpen,
    content,
    footer: !loading && footer,
    loading,
    ctaWrapperClassName,
    showBack,
    onBack,
    onScroll,
  };
};

export default useAppDrawer;
