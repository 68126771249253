import { makeStyles } from 'tss-react/mui';

interface IFloatingTheme {
  drawerBleeding: number;
  open: boolean;
}

const useStyles = makeStyles<IFloatingTheme>()(
  (theme, { drawerBleeding, open }) => ({
    root: {
      position: 'absolute',
      top: 'unset',
      bottom: 'unset',
      left: 'unset',
      right: 'unset',
    },
    swipeArea: {
      maxWidth: 400,
      right: 400,
      left: 'auto',
    },
    paper: {
      overflow: 'visible',
      pointerEvents: 'auto!important' as any,
      left: 'auto',
      borderRadius: 0,
      boxShadow: '0px 8px 24px 0px rgba(27, 27, 28, 0.15)',
      maxWidth: 400,
    },
    pullerWrapper: {
      position: 'absolute',
      top: -drawerBleeding,
      borderTopLeftRadius: 8,
      visibility: 'visible',
      right: 0,
      left: 0,
      cursor: 'pointer',
      boxShadow: '0px 8px 24px 0px rgba(27, 27, 28, 0.15)',
      '&:after': {
        content: '""',
        position: 'absolute',
        left: 0,
        height: 25,
        bottom: -24,
        background: theme.colors.white,
        width: '100%',
      },
    },
    puller: {
      position: 'relative',
      background: 'white',
      borderRadius: '8px 0px 0px 0px',
      width: '100%',
      padding: '24px 24px 0  24px',
      display: 'inline-flex',
      borderTop: `8px solid ${theme.colors.curiousBlue}`,
      gap: 8,
      '& svg': {
        flexShrink: 0,
        '& path': {
          fill: theme.colors.darkestGray,
        },
        transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
      },
    },
    content: {
      width: '100%',
      height: '100%',
      padding: '4px 24px 32px 24px',
      display: 'flex',
      flexDirection: 'column',
      gap: 16,
      zIndex: 2,
      marginTop: -25,
      [theme.breakpoints.down('sm')]: {
        marginTop: 0,
      },
      '&:before': {
        content: '""',
        position: 'absolute',
        visibility: 'visible',
        left: 0,
        [theme.breakpoints.down('sm')]: {
          height: 0,
        },
        height: 25,
        top: -10,
        background: theme.colors.white,
        width: '100%',
        zIndex: -1,
      },
    },
    dismiss: {
      width: 'fit-content',
      alignSelf: 'center',
      marginTop: 'auto',
      padding: '12px 24px',
      color: theme.colors.edward,
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  }),
);

export default useStyles;
