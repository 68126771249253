import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import Accordion from 'src/components/shared/expansion-panel/expansion-panel';
import AvatarItem from 'src/components/shared/avatar-item/avatar-item';
import { Mentor } from 'src/apis/mentors-api';
import EducationSVG from './assets/education.svg?react';
import { useStyles } from './styles';

interface CareerAndEducationProps {
  mentor: Mentor;
}
interface SectionProps {
  title: string;
  list: { title: string; subtitle: string }[];
}
const Section = ({ title, list = [] }: SectionProps) => {
  const { classes } = useStyles();

  if (!list.length) return null;

  return (
    <>
      <Typography className={classes.educationSubtitle} variant="buttonSmall">
        {title}
      </Typography>
      {list.map((item) => (
        <Fragment key={item.title}>
          {' '}
          <AvatarItem header={item.title} subHeader={item.subtitle} />
          <div className={classes.line} />
        </Fragment>
      ))}
    </>
  );
};
const CareerAndEducation = ({ mentor }: CareerAndEducationProps) => {
  const { t } = useTranslation();

  // Extract data
  const data = mentor?.mentorDetails ?? [];
  const educationBackgroundValues =
    data
      .filter((item) => item.type === 'education_background')
      .flatMap((item) => item.value) ?? [];

  const workExperienceValues =
    data
      .filter((item) => item.type === 'work_experience')
      .flatMap((item) => item.value) ?? [];

  // Check availability of details
  const hasCareerDetails = !!workExperienceValues.length;
  const hasEducationDetails = !!educationBackgroundValues.length;

  if (!hasCareerDetails && !hasEducationDetails) return null;

  return (
    <Accordion
      title={t('v2.global.label_careerAndEducation')}
      icon={<EducationSVG />}
      content={
        <>
          {hasCareerDetails && (
            <Section
              title={t('v2.global.label_workExperience')}
              list={workExperienceValues}
            />
          )}

          {hasEducationDetails && (
            <Section
              title={t('v2.global.label_university')}
              list={educationBackgroundValues}
            />
          )}
        </>
      }
    />
  );
};

export default CareerAndEducation;
