import { makeStyles } from 'tss-react/mui';
import { ITheme } from 'src/style/theme';

export const useStyles = makeStyles()((theme: ITheme) => ({
  links: {
    paddingRight: 4,
    paddingLeft: 4,
    '&:hover': {
      color: theme.colors.mediumDarkGreen,
    },
  },
  errorMsg: {
    color: theme.palette.error.main,
  },
}));
