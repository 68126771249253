import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { useGetUser } from 'src/apis/users-api';
import useSelfPay from 'src/components/self-pay/use-self-pay';
import { getAvailableSessions } from 'src/utils';
import IndividualSessionsIcon from 'src/assets/icons/individual-sessions.svg?react';
import AvailableSessions from './available-sessions';
import useStyles from './styles';

interface AvailableIndividualSessionsProps {
  duration?: number;
  hasBuyMore?: boolean;
  className?: string;
}

const AvailableIndividualSessions = ({
  duration,
  hasBuyMore,
  className,
}: AvailableIndividualSessionsProps) => {
  const { t } = useTranslation();
  const { classes } = useStyles({});
  const { data: user } = useGetUser();
  const selfPay = useSelfPay();

  const count = getAvailableSessions(user?.creditBalance);

  const showSelfPay = () => {
    selfPay.onOpen();
  };

  return (
    <AvailableSessions
      duration={duration}
      hasBackground={hasBuyMore}
      className={className}
    >
      <IndividualSessionsIcon />
      <Typography variant="subtitleBold">
        {t('v2.dashboard.individualCalls_label_details_availableCalls', {
          count,
        })}
      </Typography>
      {hasBuyMore && (
        <Typography
          variant="subtitleBold"
          onClick={showSelfPay}
          className={classes.buyMore}
        >
          {t('v2.account.wallet_label_buyMoreCredits')}
        </Typography>
      )}
    </AvailableSessions>
  );
};

export default AvailableIndividualSessions;
