import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  starReview: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  stars: {
    display: 'flex',
    alignItems: 'center',
  },
  emptyStar: {
    '& path': {
      fill: theme.colors.iron,
    },
  },
}));

export default useStyles;
