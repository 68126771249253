import { ReactNode, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { Collapse } from 'react-collapse';
import clsx from 'clsx';
import { useStyles } from './styles';
import ArrowSVG from './assets/arrow.svg?react';
import './expansionPanel.css';

interface AccordionProps {
  title: ReactNode;
  content: ReactNode;
  icon?: ReactNode;
  defaultOpen?: boolean;
  className?: string;
}

const ExpansionPanel = ({
  title,
  content,
  icon,
  defaultOpen = false,
  className,
}: AccordionProps) => {
  const [open, setOpen] = useState(defaultOpen);
  const { classes } = useStyles({ open });

  const onOpen = () => {
    setOpen(!open);
  };

  return (
    <>
      <Box onClick={onOpen} className={clsx(classes.bar, className)}>
        <Box className={classes.expansionPanelHeader}>
          {icon}
          <Typography
            className={classes.expansionPanelTitle}
            variant="buttonDefault"
          >
            {title}
          </Typography>
        </Box>
        <ArrowSVG className={classes.arrow} />
      </Box>
      <Collapse isOpened={open}>{content}</Collapse>
    </>
  );
};

export default ExpansionPanel;
