export interface ICompaniesHotlineData {
  key: string;
  country: string;
  emergencyHotline?: {
    label: string;
    phoneNumber: string;
    websiteLabel?: string;
    websiteUrl: string;
  };
  suicideHotline?: {
    label: string;
    phoneNumber: string;
  };
  violenceHotline?: {
    label: string;
    phoneNumber: string;
  };
  clinicAddresses?: {
    lable: string;
    websiteUrl: string;
  };
}

export const COMPANIES_HOTLINE_DATA = [
  {
    key: 'label_forUsersInGermany',
    country: 'DE',
    emergencyHotline: {
      label: 'label_emergency',
      phoneNumber: 'phoneNumber_emergency',
      websiteUrl: 'website_telefonSeelsorge',
    },
    suicideHotline: {
      label: 'label_telefonSeelsorge',
      phoneNumber: 'phoneNumber_telefonSeelsorge',
    },
    violenceHotline: {
      label: 'label_violenceAgainstWomen',
      phoneNumber: 'phoneNumber_violenceAgainstWomen',
    },
    clinicAddresses: {
      lable: 'label_germanDepressionHelp',
      websiteUrl: 'website_germanDepressionHelp',
    },
  },
  {
    key: 'label_forUsersInAustria',
    country: 'AT',
    emergencyHotline: {
      label: 'label_emergency',
      phoneNumber: 'phoneNumber_emergency_at',
      websiteLabel: 'label_website',
      websiteUrl: 'website_telefonSeelsorge_at',
    },
  },
  {
    key: 'label_forUsersInSwitzerland',
    country: 'CH',
    emergencyHotline: {
      label: 'label_emergency',
      phoneNumber: 'phoneNumber_emergency_ch',
      websiteLabel: 'label_website',
      websiteUrl: 'website_147_ch',
    },
  },
];
