import { Trans, useTranslation } from 'react-i18next';
import { camelCase } from 'lodash';
import { Box, Typography } from '@mui/material';
import InfoBox from 'src/components/shared/info-box/info-box';
import MuiLink from '@mui/material/Link';
import PrivacySVG from 'src/assets/icons/privacy-banner.svg?react';
import anonymisedData from 'src/assets/images/anonymised-data.webp';
import dataCentre from 'src/assets/images/data-center.webp';
import anonymisedAnalytics from 'src/assets/images/anonymised-analytics.webp';
import gdpr from 'src/assets/images/gdpr.webp';
import { privacyPolicyURL } from 'src/constants';
import { useStyles } from './styles';

const icons = {
  anonymisedData: (
    <img src={anonymisedData} loading="lazy" alt="anonimised data" />
  ),
  dataCentres: <img src={dataCentre} loading="lazy" alt="data center" />,
  anonymisedAnalytics: (
    <img src={anonymisedAnalytics} loading="lazy" alt="anonymised analytics" />
  ),
  gdpr: <img src={gdpr} loading="lazy" alt="gdpr" />,
};

const PrivacyInfo = () => {
  const { i18n, t } = useTranslation();
  const { classes } = useStyles();

  const privacySections = [
    'anonymisedData',
    'DataCentres',
    'anonymisedAnalytics',
    'GDPR',
  ].map((item) => ({
    title: t(`v2.privacy.drawer_content_${item}_label_title`),
    description: t(`v2.privacy.drawer_content_${item}_label_subtitle`),
    illustration: icons[camelCase(item) as keyof typeof icons],
  }));

  const language = i18n.language as 'en' | 'de';

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.header}>
        <PrivacySVG />
        <Typography component="h2" className={classes.title}>
          {t('v2.privacy.drawer_label_title')}
        </Typography>
        <Typography className={classes.subtitle}>
          <Trans
            i18nKey="v2.privacy.drawer_label_subtitle"
            components={[
              <MuiLink
                href={privacyPolicyURL[language]}
                target="_blank"
                rel="noopener noreferrer"
              />,
            ]}
          />
        </Typography>
      </Box>

      <Box className={classes.privacySections}>
        {privacySections.map((item) => (
          <InfoBox {...item} className={classes.infoBox} key={item.title} />
        ))}
      </Box>
    </Box>
  );
};

export default PrivacyInfo;
