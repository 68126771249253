import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  feedbackRecommendations: {
    display: 'grid',
    gap: 24,
  },
  feedbackCard: {
    maxWidth: 600,
  },
  feedbackCardCustom: {
    pointerEvents: 'none',
  },
  stars: {
    display: 'inline-flex',
    gap: 8,
    alignItems: 'center',
  },
  emptyStar: {
    '& path': {
      fill: theme.colors.iron,
    },
  },
  ratingNumber: {
    marginBottom: 2.5,
  },
  blue: {
    color: theme.colors.curiousBlue,
    display: 'inline',
  },
}));

export default useStyles;
