import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useGetBookings } from 'src/apis/bookings-api';
import { useGetOfferings } from 'src/apis/offerings-api';
import {
  LibraryItem,
  useGetLibraryItemRecommendation,
} from 'src/apis/library-item-api';
import {
  RecommendationType,
  useGetRecommendedFormat,
} from 'src/apis/recommendation-api';
import { selectExcludedRecommendations } from 'src/store/excluded-recommendations';
import { IBookingEvent } from 'src/types';
import useRebookMentorData from 'src/components/rebook-mentor-card/use-rebook-mentor-data';
import { Mentor, useGetMentors } from 'src/apis/mentors-api';

interface UseRecommendationsResult {
  recommendedIndividualSessions: Mentor[];
  recommendedGroupSessions: IBookingEvent[];
  recommendationType: RecommendationType;
  recommendedLibrary: LibraryItem[];
  isLoading: boolean;
}

const useRecommendations = (): UseRecommendationsResult => {
  const { i18n } = useTranslation();
  const offerings = useGetOfferings('RECOMMENDATION');
  const recommendedFormat = useGetRecommendedFormat();
  const mentors = useGetMentors();
  const isLoading =
    offerings.isLoading || recommendedFormat.isLoading || mentors.isLoading;
  const { data: bookings } = useGetBookings();
  const { data: libraryItems } = useGetLibraryItemRecommendation({
    language: i18n.language,
  });
  const exclusions = useSelector(selectExcludedRecommendations);
  const { showRebookMentorCard, rebookOneOnOne } = useRebookMentorData();

  const recommendedIndividualSessions = [
    ...(showRebookMentorCard ? [rebookOneOnOne] : []),
    ...(mentors?.data?.filter(({ id }) => rebookOneOnOne?.id !== id) ?? []),
  ]
    ?.filter((mentor) => !exclusions?.ids?.includes(mentor?.id))
    ?.filter((mentor) => mentor?.bookingUrl)
    ?.slice(0, 4);

  const recommendedLibrary = libraryItems
    ?.filter((item) => !exclusions?.ids?.includes(item?.id))
    ?.slice(0, 5);

  const recommendedCommunitySessions = offerings?.data?.filter(
    (session) =>
      !session.isFull &&
      !bookings?.map(({ offeringId }) => offeringId)?.includes(session?.id) &&
      !exclusions?.ids?.includes(session?.id),
  );

  const recommendedGroupSessions =
    recommendedCommunitySessions?.length > 5
      ? recommendedCommunitySessions.slice(0, 5)
      : recommendedCommunitySessions;

  return {
    recommendedIndividualSessions,
    recommendedGroupSessions,
    recommendationType: recommendedFormat.data,
    recommendedLibrary,
    isLoading,
  };
};

export default useRecommendations;
