import { makeStyles } from 'tss-react/mui';

interface StyleProps {
  hasBackground?: boolean;
}

const useStyles = makeStyles<StyleProps>()((theme, { hasBackground }) => ({
  availableSessionsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 24,
  },
  availableSessions: {
    padding: hasBackground ? '9.5px 16px' : 0,
    display: 'flex',
    flexWrap: hasBackground ? 'wrap' : 'nowrap',
    alignItems: 'center',
    gap: 8,
    backgroundColor: hasBackground ? theme.colors.wildSand : 'transparent',
    borderRadius: 8,
    border: hasBackground ? `1px solid ${theme.colors.iron}` : 0,
    color: theme.colors.darkestGreen,
    width: 'fit-content',
    [theme.breakpoints.down(457)]: {
      justifyContent: 'center',
      textAlign: hasBackground ? 'center' : 'left',
    },
  },
  buyMore: {
    color: theme.colors.mediumGreen,
    cursor: 'pointer',
    '&:before': {
      content: '"|"',
      padding: '0 8px',
      color: theme.colors.iron,
      [theme.breakpoints.down(457)]: {
        display: 'none',
      },
    },
  },
  duration: {
    display: 'inline-flex',
    color: theme.colors.darkestGreen,
    alignItems: 'center',
    gap: 8,
  },
  groupSessionsIcon: {
    width: 22,
    height: 22,
    '& circle': {
      stroke: theme.colors.darkestGreen,
    },
  },
  groupSessionsLabel: {
    display: 'inline-flex',
    flexWrap: 'wrap',
    '& span': {
      textAlign: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      gap: 8,
    },
  },
}));

export default useStyles;
