import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LikeMindedState } from './store';

interface ExcludedRecommnedationsSlice {
  excludedRecommnedations?: string[];
}

const EXCLUDED_RECOMMENDATIONS_KEY = 'recommendationsExcluded';

const initialExcluded = () => {
  const recommendationExcluded = localStorage.getItem(
    EXCLUDED_RECOMMENDATIONS_KEY,
  );

  if (recommendationExcluded) {
    try {
      return JSON.parse(recommendationExcluded);
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  return [];
};

const initialState = {
  ids: initialExcluded(),
} as ExcludedRecommnedationsSlice;

const excludedRecommendationsSlice = createSlice({
  name: 'excludedRecommnedations',
  initialState,
  reducers: {
    excludeRecommendation(state, action: PayloadAction<string>) {
      state.ids = [...state.ids, action.payload];

      localStorage.setItem(
        EXCLUDED_RECOMMENDATIONS_KEY,
        JSON.stringify(state.ids),
      );
    },
  },
});

export const excludedRecommendationsReducer =
  excludedRecommendationsSlice.reducer;
export const selectExcludedRecommendations = (state: LikeMindedState) =>
  state.excludedRecommendations;
export const { excludeRecommendation } = excludedRecommendationsSlice.actions;
