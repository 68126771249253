import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MuiLink from '@mui/material/Link';
import emergencyContactBanner from 'src/assets/images/emergency-contact.webp';
import LinkToSVG from 'src/assets/icons/link-to.svg?react';
import ExpansionPanel from '../shared/expansion-panel/expansion-panel';
import {
  COMPANIES_HOTLINE_DATA,
  ICompaniesHotlineData,
} from './companies-hotline-data';
import SectionContent from './section-content';
import useStyles from './styles';

const EmergencyContactInfo = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const sectionList = COMPANIES_HOTLINE_DATA.map(
    (item: ICompaniesHotlineData) => ({
      title: t(`v2.crisisInformation.drawer_${item.key}`),
      content: <SectionContent item={item} />,
    }),
  );

  return (
    <Box className={classes.emergencyContact}>
      <img
        src={emergencyContactBanner}
        alt="emergency-contact-banner"
        className={classes.image}
      />
      <Typography variant="h3">
        {t('v2.crisisInformation.drawer_label_title')}
      </Typography>
      <Typography variant="body1">
        {t('v2.crisisInformation.drawer_label_body')}
      </Typography>

      <MuiLink
        className={classes.helplineLink}
        target="_blank"
        rel="noopener noreferrer"
        href={t(
          'v2.crisisInformation.drawer_website_international_findHelpline',
        )}
      >
        {t('v2.crisisInformation.drawer_label_internationalHelplines')}
        <LinkToSVG />
      </MuiLink>

      {sectionList.map((section, index) => (
        <Box key={section.title}>
          <ExpansionPanel defaultOpen={index === 0} {...section} />
        </Box>
      ))}
    </Box>
  );
};

export default EmergencyContactInfo;
