import { Box, Typography } from '@mui/material';
import { WebappBookingSource } from 'src/apis/bookings-api';
import { IBookingEvent } from 'src/types';
import useOfferingCard from 'src/components/offering-card/use-offering-card';
import { useStyles } from './styles';
import SmallCard from '../small-card/small-card';

interface RecommendedSessionsProps {
  title: React.ReactNode;
  subtitle: React.ReactNode;
  list?: IBookingEvent[];
  source?: 'WEBAPP_LIBRARY_DRAWER' | 'WEBAPP_PE_DRAWER';
  webappBookingSource: WebappBookingSource;
}

const DrawerRecommendedSessions = ({
  title,
  subtitle,
  list = [],
  source,
  webappBookingSource,
}: RecommendedSessionsProps) => {
  const { classes } = useStyles();
  const { handleShowOfferingDrawer } = useOfferingCard(source);

  const handleClick = (offering: IBookingEvent) => {
    handleShowOfferingDrawer(offering, { webappBookingSource });
  };

  return (
    <Box>
      <Typography variant="h324Bold">{title}</Typography>
      <Typography className={classes.subtitle}>{subtitle}</Typography>

      <Box className={classes.list}>
        {list.map((item) => (
          <SmallCard key={item.id} onClick={() => handleClick(item)}>
            <SmallCard.Offering offering={item} />
          </SmallCard>
        ))}
      </Box>
    </Box>
  );
};

export default DrawerRecommendedSessions;
