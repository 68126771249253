import { configureStore, PreloadedState } from '@reduxjs/toolkit';
import { useDispatch, RootStateOrAny } from 'react-redux';
import { commonReducer } from './common';
import { excludedRecommendationsReducer } from './excluded-recommendations';

const rootReducer = {
  common: commonReducer,
  excludedRecommendations: excludedRecommendationsReducer,
};

export const store = configureStore({
  reducer: rootReducer,
});

export type LikeMindedState = ReturnType<typeof store.getState>;
type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();

export const setupStore = (preloadedState?: PreloadedState<RootStateOrAny>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
};
