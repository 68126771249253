import i18n from 'src/translations/i18n';

export interface Option {
  id: string;
  title: string;
  price: number;
  rate?: string;
  previousPrice?: number;
}

const getTitle = (count: number) =>
  i18n.t('v2.global.label_sessions_dynamicSessionCountTag', {
    count,
  });

const getRate = (priceSession: string) =>
  i18n.t('v2.global.label_pricePerSession_dynamicSessionCountTag', {
    priceSession,
  });

const getOptions = (): Option[] => {
  const { VITE_ENV } = import.meta.env;
  const isTesting = VITE_ENV !== 'production';

  const testingOption = {
    id: 'test_fZebMPacJ3wc9Ak3cc',
    title: getTitle(1),
    price: 1,
  };

  if (isTesting) {
    return [testingOption];
  }

  return [
    {
      id: '28o15k31pf9X4Vy009',
      title: getTitle(1),
      previousPrice: 109,
      price: 99,
    },
    {
      id: 'cN215katRf9Xco04gq',
      title: getTitle(3),
      previousPrice: 285,
      price: 255,
      rate: getRate('85€'),
    },
    {
      id: '3csdS67hF6Drew8dR1',
      title: '4 Sessions',
      previousPrice: 350,
      price: 328,
      rate: getRate('82€'),
    },
    {
      id: '7sIaFU6dB4vjds4eV2',
      title: '5 Sessions',
      price: 399,
      rate: getRate('80€'),
    },
  ];
};

export default getOptions;
