import useDrawerScroll from 'src/hooks/use-drawer-scroll';
import useSearchParams from 'src/hooks/use-search-params';
import useAppDrawer from 'src/app/use-app-drawer';
import { useGetMentors } from 'src/apis/mentors-api';
import MentorProfile from '../mentor-drawer/mentor-drawer';

const useMentorDrawer = () => {
  const { searchParams } = useSearchParams();
  const mentorId = searchParams.get('psychological-expert-id');
  const { data: oneOnOne, isLoading } = useGetMentors();

  const mentor = oneOnOne?.find((item) => item.id === mentorId);

  const { scrolled, onScroll } = useDrawerScroll({
    resetBy: mentorId,
  });

  return useAppDrawer({
    params: ['psychological-expert-id', 'name', 'utm_campaign'],
    value: mentorId,
    onScroll,
    content: !isLoading && mentor && (
      <MentorProfile mentor={mentor} scrolled={scrolled} />
    ),
    forceLoadingTime: 500,
    notFound: !isLoading && !mentor,
  });
};

export default useMentorDrawer;
