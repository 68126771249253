import { makeStyles } from 'tss-react/mui';
import { EventType } from 'src/types';

interface StyleProps {
  offeringType?: EventType;
}

export const useStyles = makeStyles<StyleProps>()(
  (theme, { offeringType }, classes) => ({
    root: {
      height: '284px',
      width: '628px',
    },
    reccuringLabel: {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexWrap: 'wrap',
      gap: 4,
      '& a': {
        fontSize: 14,
      },
    },
    recurring: {
      background: theme.colors.wildSand,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '8px',
    },
    recurringScrolled: {
      position: 'fixed',
      maxHeight: 50,
      bottom: 0,
      zIndex: 1,
    },
    detailsContainer: {
      width: '100%',
    },
    headerContainer: {
      alignItems: 'left',
      position: 'relative',
    },
    fullyBooked: {
      background: theme.colors.midYellow,
    },
    booked: {
      background: theme.colors.lightGreen,
    },
    recommended: {
      background: theme.colors.aliceBlue,
      color: theme.colors.bayofMany,
    },
    forStarters: {
      background: theme.colors.lightGreen,
      color: theme.colors.darkestGreen,
    },
    offeringHeader: {
      backgroundColor: theme.eventColors[offeringType as EventType],
    },
    hide: {
      visibility: 'hidden',
    },
    offeringHeaderScrolled: {
      [`& .${classes.dateContainer}`]: {
        paddingTop: 0,
      },
      '& svg': {
        height: 20,
      },
    },
    contentContainer: {
      padding: '24px 24px 160px 24px',
    },
    mentorWrapper: {
      display: 'flex',
      padding: '16px 0',
    },
    label: {
      flex: '1 100%',
      fontWeight: 'bold',
      color: theme.colors.darkestGray,
    },
    infoWrapper: {
      paddingBottom: 24,
    },
    infoTopicsWrapper: {
      paddingBottom: 8,
    },
    infoMentorWrapper: {
      paddingTop: 24,
    },
    expansionPanel: {
      paddingBottom: 24,
      borderBottom: `1px solid ${theme.colors.lightGray}`,
    },
    mentorInfoWrapper: {
      display: 'grid',
      gridGap: 16,
    },
    sectionLabel: {
      fontWeight: 'bold',
      color: theme.colors.darkGray,
      padding: '8px 0',
    },
    topicChip: {
      margin: '0 8px 8px 0',
    },
    sessionIconsContainer: {
      display: 'flex',
      marginTop: 16,
    },
    sessionItem: {
      width: 180,
      marginBottom: 16,
      display: 'flex',
      alignItems: 'center',
    },
    dateContainer: {
      paddingTop: 10,
      display: 'flex',
      alignItems: 'center',
      color: theme.colors.darkestGray,
      '& path': {
        fill: theme.colors.darkestGray,
      },
    },
    iconWrapper: {
      verticalAlign: 'middle',
      marginTop: 8,
    },
    dateTime: {
      [theme.breakpoints.down('sm')]: {
        fontSize: 16,
      },
    },
    descriptionWrapper: {
      padding: '8px 0',
    },
    mentorImage: {
      width: 48,
      height: 48,
      objectFit: 'cover',
      borderRadius: 60,
      marginRight: theme.spacing(2),
    },
    mentorTitleSection: {
      display: 'block',
    },
    languageLabel: {
      fontWeight: 'bold',
    },
    divider: {
      color: theme.colors.lightGray,
    },
    icon: {
      marginRight: 4,
    },
    eventsSection: {
      padding: '8px 0',
    },
    eventsSectionTitle: {
      textTransform: 'uppercase',
      color: theme.colors.darkGray,
    },
    eventsList: {
      paddingLeft: 0,
      display: 'block',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        flexDirection: 'row',
      },
    },
    eventName: {
      fontWeight: 'bold',
    },
    eventLabel: {
      padding: '16px 0',
    },
    mentorSubtitle: {
      padding: '8px 0',
    },
    actionWrapper: {
      [theme.breakpoints.up('xs')]: {
        marginTop: '15%',
        width: '35%',
        textAlign: 'right',
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: 24,
        textAlign: 'center',
      },
    },
    pageHeaderLink: {
      fontWeight: 'bold',
      paddingLeft: 4,
    },
    actionLabel: {
      fontWeight: 'bold',
      color: theme.palette.primary.main,
      paddingRight: 8,
      alignItems: 'center',
    },
    specializedContainer: {
      paddingTop: 24,
      display: 'flex',
      flexDirection: 'column',
    },
    specializedLabel: {
      color: theme.colors.darkGray,
      letterSpacing: 0.5,
      textTransform: 'uppercase',
    },
    chip: {
      marginRight: 8,
      marginBottom: 8,
    },
    videoContainer: {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      },
      maxHeight: 140,
      marginBottom: 32,
    },
    hostedCardWrapper: {
      paddingTop: 24,
    },
    educationHeader: {
      display: 'flex',
      alignItems: 'center',
    },
    educationSubtitle: {
      paddingTop: 24,
      paddingBottom: 8,
      color: theme.colors.darkGray,
      textTransform: 'uppercase',
    },
    line: {
      width: '100%',
      height: 1,
      backgroundColor: theme.colors.lightGray,
      marginTop: 16,
      marginBottom: 16,
    },
    hostTopicsLabel: {
      color: theme.colors.darkGray,
      fontWeight: 700,
      paddingBottom: 8,
      paddingTop: 24,
    },
    canSupport: {
      color: theme.colors.darkGray,
    },
    yourHostLabel: {
      color: theme.colors.darkGray,
      fontWeight: 700,
    },
    offeringDescription: {
      'white-space': 'pre-line',
    },
    otherDateList: {
      display: 'flex',
      flexWrap: 'wrap',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gap: 16,
      maxWidth: 450,
      marginTop: 8,
    },
    otherDate: {
      width: 200,
      minWidth: 'fit-content',
    },
    feedbackRecommendations: {
      marginTop: 40,
    },
    offeringRating: {
      display: 'grid',
      gap: 8,
      marginBottom: 16,
    },
    bookedBy: {
      color: theme.colors.curiousBlue,
    },
  }),
);
