import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { IBookingEvent } from 'src/types';
import { getTimeOfDay } from 'src/utils/date-utils';

const useTimeFilter = (upcomingEvents: IBookingEvent[]) => {
  const { t } = useTranslation();

  const existingTimes = upcomingEvents?.flatMap((event) =>
    event.sessions.map((session) => getTimeOfDay(moment(session.startsAt))),
  );

  const options = [
    {
      value: 'morning',
      name: t('v2.global.filterChip_label_eventTimes_menu_morning'),
    },
    {
      value: 'afternoon',
      name: t('v2.global.filterChip_label_eventTimes_menu_afternoon'),
    },
    {
      value: 'evening',
      name: t('v2.global.filterChip_label_eventTimes_menu_evening'),
    },
  ].filter(
    (option) => option.value === '' || existingTimes.includes(option.value),
  );

  return {
    name: 'time',
    label: t('v2.global.filters_dropdown_label_allTimes_filterCount'),
    options,
    multiple: true,
    selectAll: {
      value: '',
      name: t('v2.global.filterChip_label_eventTimes'),
    },
    defaultValue: '',
    paperWidth: 350,
  };
};

export default useTimeFilter;
