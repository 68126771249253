import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import SessionSVG from 'src/components/event-details/assets/session.svg?react';
import LanguageSVG from 'src/components/event-details/assets/language.svg?react';
import SeatSVG from 'src/components/event-details/assets/seats.svg?react';
import { useStyles } from 'src/components/event-details/styles';
import { colors } from 'src/style/colors';
import { IconsListItemProps } from 'src/components/event-details/event-details';
import { EventType, IBookingEvent } from 'src/types';
import { getLanguageLabel } from 'src/utils';

const IconsListItem = ({
  icon,
  label,
  isDisabled,
  wrapWidth,
}: IconsListItemProps) => {
  const { classes } = useStyles({ wrapWidth });

  return (
    <Box className={classes.iconItemContainer}>
      <Box component="span" className={classes.iconWrapper}>
        {icon}
      </Box>
      <Typography
        component="div"
        variant="subtitleBold"
        className={classes.labelWrapper}
        style={{
          fontWeight: 700,
          color: isDisabled ? colors.darkGray : colors.darkestGray,
        }}
      >
        {label}
      </Typography>
    </Box>
  );
};

const OfferingDetailsDrawer = (props: { offering: IBookingEvent }) => {
  const { offering } = props;
  const { classes } = useStyles({});
  const { t } = useTranslation();

  const sessionLabel = t('v2.global.label_session', {
    count: offering.sessions.length,
  });

  const seatsLeft = offering.maxParticipants - offering.numberOfBookings;
  const seats =
    offering.type === EventType.WEBINAR
      ? t('v2.global.label_eventSeatsUnlimited')
      : t('v2.global.label_eventSeatsLeft', { count: seatsLeft });

  return (
    <Box className={classes.listContainer}>
      <Box className={classes.iconsContainer}>
        <IconsListItem
          icon={<LanguageSVG />}
          label={`${offering.language && getLanguageLabel(offering.language)}`}
        />
      </Box>

      <Box className={classes.iconsContainer}>
        <IconsListItem icon={<SessionSVG />} label={sessionLabel} />
      </Box>

      <Box className={classes.iconsContainer}>
        <IconsListItem icon={<SeatSVG />} label={seats} wrapWidth={100} />
      </Box>
    </Box>
  );
};

export default OfferingDetailsDrawer;
