import { makeStyles } from 'tss-react/mui';
import { ITheme } from 'src/style/theme';
import { colors, supportColors } from 'src/style/colors';

export const useStyles = makeStyles()((theme: ITheme) => ({
  infoBanner: {
    display: 'flex',
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    borderRadius: theme.spacing(1),
    marginTop: 'auto',
    textAlign: 'left',
  },
  textStyle: {
    marginLeft: theme.spacing(1),
    lineHeight: '18px',
  },
  infoIcon: {
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
  infoStyle: {
    background: colors.skyBlue,
    color: colors.darkBlue,
    width: 'fit-content',
  },
  warningStyle: {
    background: supportColors.supportBgWarning,
    color: supportColors.supportColorWarning,
  },
}));
