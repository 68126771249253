import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LogoLargeSVG from 'src/assets/icons/likeminded-logo-large.svg?react';
import { useAppConfiguration } from 'src/hooks/use-app-configuration';
import MenuSVG from 'src/assets/icons/menu.svg?react';
import CloseSVG from 'src/assets/icons/close.svg?react';
import MenuMobile from '../menu-mobile/menu-mobile';
import { useStyles } from './styles';

function getScrollbarSize() {
  const scrollDiv = document.createElement('div');
  scrollDiv.style.width = '99px';
  scrollDiv.style.height = '99px';
  scrollDiv.style.position = 'absolute';
  scrollDiv.style.top = '-9999px';
  scrollDiv.style.overflow = 'scroll';
  document.body.append(scrollDiv);
  const scrollbarSize = scrollDiv.offsetWidth - scrollDiv.clientWidth;
  scrollDiv.remove();
  return scrollbarSize;
}

const scrollbarSize = getScrollbarSize();

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { withNavbarLogo } = useAppConfiguration();

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
      document.body.style.paddingRight = `${scrollbarSize}px`;
    } else {
      document.body.style.overflow = 'auto';
      document.body.style.paddingRight = '0';
    }
  }, [isMenuOpen]);

  return (
    <>
      <div className={isMenuOpen ? classes.lineBorderless : classes.line}>
        <div>
          {withNavbarLogo ? (
            <>
              {/* @ts-ignore next */}
              <Box component={Link} to="/">
                <LogoLargeSVG className={classes.logo} />
              </Box>
            </>
          ) : (
            <Box component="span">
              <LogoLargeSVG className={classes.logo} />
            </Box>
          )}

          <div className={classes.action}>
            {isMenuOpen ? (
              <Button
                className={classes.actionButton}
                size="small"
                endIcon={<CloseSVG />}
                disableRipple
                onClick={() => setIsMenuOpen(false)}
              >
                {t('Navbar.close')}
              </Button>
            ) : (
              <Button
                className={classes.actionButton}
                size="small"
                endIcon={<MenuSVG />}
                disableRipple
                onClick={() => setIsMenuOpen(true)}
              >
                {t('Navbar.menu')}
              </Button>
            )}
          </div>
        </div>
      </div>
      {isMenuOpen && (
        <div className={classes.menu}>
          <MenuMobile setIsMenuOpen={setIsMenuOpen} />
        </div>
      )}
    </>
  );
};

export default Navbar;
