import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  descriptionWrapper: {
    maxWidth: 718,
    paddingTop: 16,
    paddingBottom: 40,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  learnMore: {
    color: theme.colors.mediumGreen,
    fontWeight: 700,
    cursor: 'pointer',
    marginLeft: 8,
    '&:hover': {
      color: theme.colors.mediumDarkGreen,
    },
  },
  learnMoreWrapper: {
    marginTop: 18,
    '&:hover': {
      '& path': {
        fill: theme.colors.mediumDarkGreen,
      },
    },
  },
  iconWrapper: {
    verticalAlign: 'middle',
    '& path': {
      fill: theme.colors.mediumGreen,
    },
  },
  title: {
    display: 'flex',
    gap: 8,
    flexWrap: 'wrap',
  },
}));
