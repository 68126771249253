import useAppDrawer from 'src/app/use-app-drawer';
import { useStyles } from './styles';
import LibraryDrawerContent from './library-drawer/library-drawer-content';
import useLibraryDrawerFooter from './library-drawer/use-library-drawer-footer';

interface UseLibraryDrawerProperties {
  id: string;
}

const useLibraryDrawer = ({ id }: UseLibraryDrawerProperties) => {
  const { classes } = useStyles({});

  const footer = useLibraryDrawerFooter();

  return useAppDrawer({
    params: ['library-drawer'],
    value: id,
    content: <LibraryDrawerContent />,
    footer,
    forceLoadingTime: 1000,
    ctaWrapperClassName: classes.audioCtaWrapper,
  });
};

export default useLibraryDrawer;
