import { createTheme, ThemeOptions } from '@mui/material/styles';
import { Palette, PaletteOptions } from '@mui/material/styles/createPalette';
import { eventColors } from 'src/style/colors';
import { colors, supportColors, alertColor, alertBackground } from './colors';
import { EventType } from '../types';
import typography, { CustomTypographyVariants } from './typography';
import overrides from './overrides';

interface IPalette extends Palette {
  customColors: Record<string, string>;
}

export type AlertSeverity = 'setting' | 'message' | 'warning' | 'error';

type EventTypeColors = Record<EventType | 'default', string>;
type Colors = Record<keyof typeof colors, string>;

interface CustomThemeOptions {
  palette: IPalette | PaletteOptions | undefined;
  eventColors: EventTypeColors;
  alertColor: Record<AlertSeverity, string>;
  alertBackground: Record<AlertSeverity, string>;
  colors: Colors;
  typography: any;
}

export type ITheme = ThemeOptions & CustomThemeOptions;

declare module '@mui/material/styles' {
  interface Theme extends CustomThemeOptions {}
  interface ThemeOptions extends CustomThemeOptions {}
}
declare module '@mui/material/styles' {
  interface TypographyVariants extends CustomTypographyVariants {}
  // allow configuration using `createTheme`
  interface TypographyVariantsOptions extends CustomTypographyVariants {}
}
// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides extends CustomTypographyVariants {}
}

declare module '@mui/material/styles' {
  interface Palette {
    skyBlue: Palette['primary'];
  }

  interface PaletteOptions {
    skyBlue?: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    skyBlue: true;
  }
}

const theme = createTheme({
  eventColors,
  alertColor,
  alertBackground,
  colors,
  components: {
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
  },
  palette: {
    background: {
      default: colors.white,
    },
    primary: {
      main: colors.darkestGreen,
    },
    secondary: {
      main: colors.white,
    },
    error: {
      main: supportColors.supportColorAlert,
    },
    warning: {
      main: colors.darkestGray,
    },
    info: {
      main: colors.darkestGray,
    },
    success: {
      main: colors.darkestGreen,
    },
    text: {
      primary: colors.darkestGray,
      secondary: colors.white,
    },
    skyBlue: {
      main: colors.skyBlue,
      contrastText: colors.bayofMany,
    },
  },
  typography,
  shape: {
    borderRadius: 8,
  },
});

theme.components = overrides(theme);

export default theme;
