import clsx from 'clsx';
import { Box, Typography } from '@mui/material';
import { useStyles } from './styles';

interface TagItem {
  icon: React.ReactNode;
  label: string;
}

const Tag = ({ icon, label }: TagItem) => {
  const { classes } = useStyles();

  return (
    <Box>
      <Typography component="span" className={classes.iconWrapper}>
        {icon}
      </Typography>
      <Typography component="span" className={classes.labelWrapper}>
        {label}
      </Typography>
    </Box>
  );
};

interface TagListProps {
  list: TagItem[];
  className?: string;
}

const TagList = ({ list, className }: TagListProps) => {
  const { classes } = useStyles();
  return (
    <Box className={clsx(classes.tagList, className)}>
      {list.map((item) => (
        <Tag key={item.label} icon={item.icon} label={item.label} />
      ))}
    </Box>
  );
};

Tag.List = TagList;

export default Tag;
