import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useGetOfferings } from 'src/apis/offerings-api';
import { getEventDate } from 'src/utils/date-utils';
import { IBookingEvent } from 'src/types';
import useOfferingCard from 'src/components/offering-card/use-offering-card';
import { useStyles } from './styles';

interface OfferingOtherDatesProps {
  offeringId: string;
  offeringSubjectId: string;
}

const OfferingOtherDates = ({
  offeringId,
  offeringSubjectId,
}: OfferingOtherDatesProps) => {
  const { t } = useTranslation();
  const { classes } = useStyles({});
  const { data: offeringList } = useGetOfferings();
  const { handleShowOfferingDrawer } = useOfferingCard('OTHER_DATE');

  const recurringOfferings = offeringList?.filter(
    (item) => item?.offeringSubject?.id === offeringSubjectId,
  );

  const handleShowOtherDate = (item: IBookingEvent) => {
    handleShowOfferingDrawer(item);
    const sideDrawer = document.querySelector('#side-drawer') as HTMLElement;
    sideDrawer.scrollTo(0, 0);
  };

  return (
    <Box id="other-dates">
      <Typography variant="buttonSmall" className={classes.sectionLabel}>
        {t('v2.global.label_availableDates')}
      </Typography>
      <Box className={classes.otherDateList}>
        {recurringOfferings
          ?.filter((item) => item.id !== offeringId) // Filter out the current offering
          .map((item) => (
            <Button
              key={item.id}
              variant="outlined"
              className={classes.otherDate}
              onClick={() => handleShowOtherDate(item)}
            >
              {getEventDate(item.sessions[0]?.startsAt)}
            </Button>
          ))}
      </Box>
    </Box>
  );
};

export default OfferingOtherDates;
