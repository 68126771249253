import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  mainContainer: {
    marginTop: 40,
  },
  availableGroupSessions: {
    marginTop: 24,
  },
}));
