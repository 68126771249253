import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  root: {
    height: '284px',
    width: '628px',
  },
  profileContainer: {
    width: '100%',
    padding: 24,
  },
  headerContainer: {
    alignItems: 'left',
  },
  mentorWrapper: {
    display: 'flex',
  },
  languageWrapper: {
    padding: '24px 0 16px 0',
  },
  descriptionWrapper: {
    padding: '24px 0',
  },
  availableSessions: {
    margin: '32px 0 8px 0',
  },
  descriptionTitle: {
    paddingTop: 32,
    paddingBottom: 8,
    color: theme.colors.darkGray,
    textTransform: 'uppercase',
  },
  expansionPanelTitle: {
    color: theme.colors.darkestGray,
    paddingLeft: 9,
  },
  description: {
    paddingBottom: 16,
  },
  mentorImage: {
    width: 48,
    height: 48,
    objectFit: 'cover',
    borderRadius: 60,
    marginRight: theme.spacing(2),
  },
  mentorTitleSection: {
    display: 'block',
  },
  subtitle: {
    fontWeight: 'bold',
  },
  languageLabel: {
    fontWeight: 'bold',
  },
  divider: {
    color: theme.colors.lightGray,
  },
  iconWrapper: {
    verticalAlign: 'middle',
    marginTop: 8,
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  eventsSection: {
    padding: '8px 0',
  },
  sectionTitle: {
    padding: '8px 0',
    color: theme.colors.darkestGray,
  },
  eventsList: {
    paddingLeft: 0,
    display: 'block',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'row',
    },
  },
  eventName: {
    fontWeight: 'bold',
  },
  eventLabel: {
    padding: '16px 0',
  },
  actionWrapper: {
    [theme.breakpoints.up('xs')]: {
      marginTop: '15%',
      width: '35%',
      textAlign: 'right',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 24,
      textAlign: 'center',
    },
  },
  pageHeaderLink: {
    fontWeight: 'bold',
    paddingLeft: 4,
  },
  actionLabel: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    paddingRight: 8,
    alignItems: 'center',
  },
  sectionContainer: {
    paddingTop: 24,
    display: 'flex',
    flexDirection: 'column',
  },
  specializedLabel: {
    color: theme.colors.darkGray,
    letterSpacing: 0.5,
    textTransform: 'uppercase',
  },
  chip: {
    marginRight: 8,
    marginBottom: 8,
  },
  videoContainer: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    maxHeight: 140,
    marginBottom: 32,
  },
  educationHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  educationSubtitle: {
    paddingTop: 24,
    paddingBottom: 8,
    color: theme.colors.darkGray,
    textTransform: 'uppercase',
  },
  line: {
    width: '100%',
    height: 1,
    backgroundColor: theme.colors.lightGray,
    marginTop: 16,
    marginBottom: 16,
  },
  recommended: {
    background: theme.colors.aliceBlue,
    color: theme.colors.bayofMany,
  },
  requestSessionsContainer: {
    margin: '16px 0',
    padding: 24,
    borderRadius: 8,
    textAlign: 'center',
    backgroundColor: theme.colors.gainsboro,
    [theme.breakpoints.up('sm')]: {
      height: 276,
    },
  },
  requestSessionsCTA: {
    width: 'fit-content',
    height: 42,
    fontSize: 14,
    fontWeight: 'bold',
    marginTop: 24,
    borderRadius: 8,
  },
}));
