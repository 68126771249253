import { makeStyles } from 'tss-react/mui';
import { ITheme } from 'src/style/theme';

export const useStyles = makeStyles()((theme: ITheme) => ({
  root: {
    display: 'flex',
  },
  subHeaderContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  subHeader: {
    color: theme.colors.darkGray,
  },
  avatar: {
    backgroundColor: theme.colors.lightGreen,
    color: theme.colors.darkestGray,
    minHeight: 48,
    minWidth: 48,
    maxHeight: 48,
    maxWidth: 48,
    borderRadius: 24,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 16,
    fontWeight: 'bold',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'flex',
    lineClamp: 1,
    WebkitLineClamp: 1,
  },
}));
