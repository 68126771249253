import { makeStyles } from 'tss-react/mui';
import { ITheme } from 'src/style/theme';

interface StyleProps {
  disabled: boolean;
  error: any;
  type: any;
}

const useStyles = makeStyles<StyleProps>()(
  (theme: ITheme, { disabled, error, type }) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    inputContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderColor: theme.colors.darkestGray,
      '&:focus-within': {
        borderColor: theme.colors.gainsboro,
      },
      ...(disabled && {
        borderColor: theme.colors.darkGray,
      }),
      ...(error && {
        borderColor: theme.colors.darkestGreen,
      }),
      ...(type === 'tel' && {
        borderBottomWidth: 0,
        borderBottomStyle: 'none',
      }),
    },
    input: {
      ...theme.typography.body,
      flex: 1,
      padding: theme.spacing(2),
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
      [theme.breakpoints.up('sm')]: {
        paddingTop: theme.spacing(2),
      },
      border: 0,
      borderRadius: 0,
      outline: 'none',
      backgroundColor: 'transparent',
      color: theme.colors.darkestGray,
      '&::placeholder': {
        color: theme.colors.lightGray,
      },
      ...(disabled && {
        pointerEvents: 'none',
        color: theme.colors.darkestGray,
      }),
    },
    label: {
      textTransform: 'uppercase',
      ...(disabled && {
        color: theme.colors.lightGray,
      }),
    },
    optionalLabel: {
      textTransform: 'none',
      ...(disabled && {
        color: theme.colors.lightGray,
      }),
    },
    error: {
      marginTop: '8px',
    },
  }),
);

export default useStyles;
