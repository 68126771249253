import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useStyles } from '../group-sessions/styles';
import LibraryFormatDetails from './library-format-details';
import useGetLibraryDetails from './use-get-library-details';
import FormatInfoDrawerHeader from '../format-info-drawer-header';

const LibraryInfo = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const formatDetails = useGetLibraryDetails();
  return (
    <Box className={classes.root}>
      <FormatInfoDrawerHeader
        title={t('v2.offeringOverview.drawer_library_label_title')}
        subtitle={t('v2.offeringOverview.drawer_library_label_body')}
      />
      {formatDetails.map((formatDetail, index) => (
        <LibraryFormatDetails
          title={formatDetail.libraryFormat}
          libraryFormatDetails={formatDetail}
          className={classes.accordionBar}
          defaultOpen={index === 0}
        />
      ))}
    </Box>
  );
};

export default LibraryInfo;
