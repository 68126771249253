import { useTranslation } from 'react-i18next';
import MuiLink from '@mui/material/Link';
import { Box, Typography } from '@mui/material';
import LogoSVG from 'src/assets/icons/likeminded-logo.svg?react';
import { imprintURL } from 'src/constants';
import { Outlet } from 'react-router-dom';
import { useStyles } from './styles';
import LanguageSelect from './language-select';

const UnauthorizedLayout = () => {
  const { t, i18n } = useTranslation();
  const { classes } = useStyles();
  const { VITE_LANDING_URL } = import.meta.env;

  const language = i18n.language as 'en' | 'de';

  return (
    <Box className={classes.mainWrapper} component="main">
      <Box className={classes.contentWrapper}>
        <div className={classes.table}>
          <div className={classes.tableRow}>
            <Box
              className={classes.layoutheader}
              px={3}
              pt={{ xs: 3, md: 7 }}
              pb={{ xs: 9, md: 7 }}
            >
              <a href={VITE_LANDING_URL}>
                <LogoSVG />
              </a>
              <LanguageSelect />
            </Box>
          </div>

          <Outlet />

          <Box
            className={classes.tableCellFooter}
            component={Typography}
            px={3}
            py={{ xs: 5, md: 7 }}
            // @ts-ignore next
            variant="buttonSmall"
          >
            <MuiLink
              href={imprintURL[language]}
              rel="noopener noreferrer"
              target="_blank"
              className={classes.link}
            >
              {t('v2.global.button_imprint')}
            </MuiLink>
            {t('Shared.copyright')}
          </Box>
        </div>
      </Box>

      <Box className={classes.illustrationSection} />
    </Box>
  );
};

export default UnauthorizedLayout;
