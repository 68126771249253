import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Box, Hidden, Typography } from '@mui/material';
import usePrivacyInfo from 'src/components/privacy-info-drawer/use-privacy-info';
import ShieldSVG from 'src/assets/icons/shield.svg?react';
import EmergencyContactSVG from 'src/assets/icons/emergency-contact.svg?react';
import BadHotlineSVG from 'src/assets/icons/bad-hotline.svg?react';
import useBadHotline, {
  useShowBADHotline,
} from 'src/components/bad-hotline-drawer/use-bad-hotline';
import useEmergencyContactInfo from 'src/components/emergency-contact-info/use-emergency-contact-info';
import { useStyles } from './styles';
import LanguageSelect from '../language-select';

interface TopBarButtonsProps {
  className?: string;
}

const TopBarButtons = ({ className }: TopBarButtonsProps) => {
  const { classes } = useStyles({});
  const privacy = usePrivacyInfo();
  const badHotline = useBadHotline();
  const emergencyContact = useEmergencyContactInfo();
  const { t } = useTranslation();

  const showBADHotlineButton = useShowBADHotline();

  const showPrivacy = () => privacy.onOpen();
  const showBadHotline = () => badHotline.onOpen();

  const showEmergencyContact = () => emergencyContact.onOpen();

  return (
    <Box className={classes.buttons}>
      <Hidden mdDown>
        <LanguageSelect />
      </Hidden>
      {showBADHotlineButton ? (
        <>
          <Box
            className={clsx(classes.button, className)}
            onClick={showBadHotline}
            data-testid="bad-hotline-button"
          >
            <BadHotlineSVG />
            <Typography variant="buttonDefault">
              {t('v2.badHotline.button_label')}
            </Typography>
          </Box>
          <Box
            className={clsx(classes.button, classes.privacyButton, className)}
            onClick={showPrivacy}
          >
            <ShieldSVG />
            <Typography variant="buttonDefault">
              {t('v2.global.button_privacy')}
            </Typography>
          </Box>
        </>
      ) : (
        <>
          <Box
            className={clsx(classes.button, classes.privacyButton, className)}
            onClick={showPrivacy}
            data-testid="privacy-button"
          >
            <ShieldSVG />
            <Typography variant="buttonDefault">
              {t('v2.global.button_privacy')}
            </Typography>
          </Box>

          <Box
            className={clsx(classes.button, className)}
            onClick={showEmergencyContact}
            data-testid="emergency-contact-button"
          >
            <EmergencyContactSVG />
            <Typography variant="buttonDefault">
              {t('v2.crisisInformation.button_label')}
            </Typography>
          </Box>
        </>
      )}
    </Box>
  );
};

export default TopBarButtons;
