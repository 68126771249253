import { makeStyles } from 'tss-react/mui';
import { colors } from 'src/style/colors';

export const useStyles = makeStyles()((theme) => ({
  drawer: {
    zIndex: 1100,
  },
  drawerHeader: {
    height: 68,
    position: 'fixed',
    width: 657,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      borderTopLeftRadius: 0,
    },
    borderBottom: `1px solid ${colors.lightGray}`,
    backgroundColor: colors.white,
    borderTopLeftRadius: 16,
    zIndex: 1000,
    display: 'flex',
    alignItems: 'center',
  },
  closeBtnWrapper: {
    position: 'absolute',
    right: 6,
    fontSize: 16,
    color: colors.mediumGreen,
    '&:hover': {
      color: colors.mediumDarkGreen,
      '& path': {
        fill: colors.mediumDarkGreen,
      },
    },
  },
  backBtnWrapper: {
    position: 'absolute',
    left: 6,
    fontSize: 16,
    color: colors.mediumGreen,
    '&:hover': {
      color: colors.mediumDarkGreen,
      '& path': {
        fill: colors.mediumDarkGreen,
      },
    },
  },
  icon: {
    marginRight: 8,
    '& path': {
      fill: colors.mediumGreen,
    },
  },
  content: {
    paddingTop: 68,
    [theme.breakpoints.down('sm')]: {
      minHeight: '100vh',
    },
  },
  drawerFooter: {
    width: 657,
    minHeight: 68,
    bottom: 0,
    position: 'fixed',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      borderRadius: 0,
    },
    borderTop: `1px solid ${colors.lightGray}`,
    backgroundColor: colors.white,
    zIndex: 1000,
    display: 'inline-grid',
    alignItems: 'center',
    borderBottomLeftRadius: 16,
  },
  ctaWrapper: {
    textAlign: 'right',
    margin: '0 40px',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
      gap: 24,
    },
    [theme.breakpoints.down(600)]: {
      justifyContent: 'center',
      padding: '24px 0',
    },
  },
}));
