import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { IconButton, Box, Typography } from '@mui/material';
import { useAppConfiguration } from 'src/hooks/use-app-configuration';
import LogoutIcon from 'src/assets/icons/logout.svg?react';
import { useGetAuthUser, useLogout } from 'src/apis/authentication-api';
import { useStyles } from './styles';
import navigationItems from '../navigation-items';

interface MenuButtonProps {
  label: string;
  active?: boolean;
  Icon: React.ReactNode;
  path: string;
}

const MenuButton = ({ Icon, label, active, path }: MenuButtonProps) => {
  const { classes } = useStyles({ active });

  const newBooking = !!localStorage.getItem('HAS_NEW_BOOKING');

  return (
    <Box className={classes.menuButton}>
      <IconButton
        className={clsx(
          classes.menuIcon,
          path === '/bookings' && active === false && newBooking && classes.new,
        )}
        size="medium"
      >
        <Icon />
      </IconButton>
      <Typography className={classes.menuLabel} variant="captionSmall">
        {label}
      </Typography>
    </Box>
  );
};

const MenuDesktop = () => {
  const { classes } = useStyles({});
  const { t } = useTranslation();
  const location = useLocation();
  const { withSideNavigation, withLogoutBtn } = useAppConfiguration();
  const logout = useLogout();
  const { data: authUser } = useGetAuthUser();
  const roles = authUser?.roles;

  const navigation =
    roles?.includes('admin') && import.meta.env.VITE_ENV !== 'production'
      ? navigationItems
      : navigationItems.filter((item) => !item.path.includes('admin'));

  return (
    <>
      <Box display="table" width="100%" height="100%" minWidth={70}>
        <Box display="table-row">
          <Box display="table-cell" height="100%">
            {withSideNavigation && (
              <Box className={classes.menuList}>
                {navigation.map((item) => {
                  const path = Array.isArray(item.path)
                    ? item.path[0]
                    : item.path;

                  const isActive = Array.isArray(item.path)
                    ? item.path.includes(location.pathname)
                    : location.pathname.includes(path);

                  return (
                    <Box
                      className={clsx(
                        classes.menuItem,
                        isActive && classes.menuItemActive,
                      )}
                      key={item.title}
                    >
                      <NavLink className={classes.menuLink} to={path}>
                        <MenuButton
                          active={isActive}
                          label={t(item.title)}
                          Icon={item.icon}
                          hasNewContent={item.hasNewContent}
                          path={path}
                        />
                      </NavLink>
                    </Box>
                  );
                })}
              </Box>
            )}
          </Box>
        </Box>

        {withLogoutBtn && (
          <Box className={classes.menuItem}>
            <NavLink className={classes.menuLink} onClick={logout}>
              <MenuButton label={t('Menu.logOut')} Icon={LogoutIcon} />
            </NavLink>
          </Box>
        )}
      </Box>
    </>
  );
};

export default MenuDesktop;
