import { Auth } from 'aws-amplify';
import axios, { AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';
import { captureException } from '@sentry/react';

const { VITE_API_URL, VITE_ENV } = import.meta.env;

const backendApi = axios.create({
  withCredentials: true,
  baseURL: VITE_API_URL,
});

export const backendApiPublic = axios.create({
  withCredentials: true,
  baseURL: VITE_API_URL,
});

const onRequest = {
  success: async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
    if (VITE_ENV !== 'test') {
      const session = await Auth.currentSession();
      const token = session?.accessToken?.jwtToken;
      config.headers!.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  error: (error: AxiosError) => {
    captureException(error);
    return Promise.reject(error);
  },
};

const onResponse = {
  success: (response: AxiosResponse) => {
    return response?.data;
  },
  error: (error: AxiosError) => {
    captureException(error);
    return Promise.reject(error);
  },
};

backendApi.interceptors.request.use(onRequest.success, onRequest.error);
backendApi.interceptors.response.use(onResponse.success, onResponse.error);

backendApiPublic.interceptors.request.use((config) => config, onRequest.error);
backendApiPublic.interceptors.response.use(
  onResponse.success,
  onResponse.error,
);

export default backendApi;
