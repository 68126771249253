import React, { useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Box, Typography } from '@mui/material';
import { useStyles } from './styles';

interface InfoBoxProps {
  illustration?: React.ReactNode;
  title: React.ReactNode;
  description: React.ReactNode;
  className?: string;
  showMaxLines?: number;
  noBorder?: boolean;
}

const InfoBox = ({
  illustration,
  title,
  description,
  className,
  showMaxLines,
  noBorder = false,
}: InfoBoxProps) => {
  const { t } = useTranslation();
  const defaultShowMore = Boolean(showMaxLines);
  const [showMore, toggleShowMore] = useReducer(
    (state) => !state,
    defaultShowMore,
  );

  const maxLines = showMore ? showMaxLines : 99;
  const { classes } = useStyles({ maxLines, noBorder });

  return (
    <Box className={clsx(classes.wrapper, className)}>
      {illustration}
      <Box className={classes.text}>
        <Typography className={classes.title} variant="subtitleBold">
          {title}
        </Typography>

        <Box className={classes.description}>{description}</Box>
        {defaultShowMore && (
          <Typography
            variant="buttonSmall"
            className={classes.showMore}
            onClick={toggleShowMore}
          >
            {showMore
              ? t('v2.global.button_showMore')
              : t('v2.global.button_showLess')}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default InfoBox;
