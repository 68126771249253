import { colors } from 'src/style/colors';

type WellbeingLevel = 'veryPoor' | 'poor' | 'acceptable' | 'good';
export type WellbeingDimensionKey =
  | 'mentalWellness'
  | 'calmness'
  | 'joyfulMindset'
  | 'stressResilience'
  | 'burnout';

type WellbeingDimensionValue =
  | 'needsCare'
  | 'couldImprove'
  | 'doingGood'
  | 'doingGreat';

type WellbeingDimension = Record<WellbeingDimensionValue, [number, number]>;
type WellbeingDimensions = Record<WellbeingDimensionKey, WellbeingDimension>;

export const wellbeingRecommendationColors = {
  needsCare: colors.dusky,
  couldImprove: colors.tangerine,
  doingGood: colors.curiousBlue,
  doingGreat: colors.chateauGreen,
};

export const wellbeingDimensions: WellbeingDimensions = {
  mentalWellness: {
    needsCare: [0, 13],
    couldImprove: [14, 17],
    doingGood: [18, 21],
    doingGreat: [22, 25],
  },
  calmness: {
    // 6 - phq4AnxietyScore
    needsCare: [0, 1],
    couldImprove: [2, 3],
    doingGood: [4, 5],
    doingGreat: [5, 6],
  },
  joyfulMindset: {
    // 6 -phq4DepressionScore
    needsCare: [0, 1],
    couldImprove: [2, 3],
    doingGood: [4, 5],
    doingGreat: [5, 6],
  },
  stressResilience: {
    // 5 - stressScore
    needsCare: [0, 1],
    couldImprove: [2, 3],
    doingGood: [3, 4],
    doingGreat: [4, 5],
  },
  burnout: {
    needsCare: [0, 2.5],
    couldImprove: [2.6, 3.5],
    doingGood: [3.6, 4.5],
    doingGreat: [4.6, 6],
  },
};

export const getWellbeingRecommendationKey = (
  value: number,
  metric: WellbeingDimensionKey,
): WellbeingDimensionValue => {
  const { needsCare, couldImprove, doingGood } = wellbeingDimensions[metric];

  if (value <= needsCare[1]) {
    return 'needsCare';
  }
  if (value <= couldImprove[1]) {
    return 'couldImprove';
  }
  if (value <= doingGood[1]) {
    return 'doingGood';
  }

  return 'doingGreat';
};

// who5Score
const wellbeing = {
  veryPoor: [0, 13],
  poor: [14, 17],
  acceptable: [18, 21],
  good: [22, 25],
};

export const getWellbeingLevel = (value: number): WellbeingLevel => {
  const { veryPoor, poor, acceptable } = wellbeing;

  if (value <= veryPoor[1]) {
    return 'veryPoor';
  }
  if (value <= poor[1]) {
    return 'poor';
  }
  if (value <= acceptable[1]) {
    return 'acceptable';
  }

  return 'good';
};

export const scale = (
  inputY: number,
  yRange: [number, number],
  xRange: [number, number],
) => {
  const [xMin, xMax] = xRange;
  const [yMin, yMax] = yRange;

  const percent = (inputY - yMin) / (yMax - yMin);
  const outputX = percent * (xMax - xMin) + xMin;

  return outputX;
};
