import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment';
import { dates } from 'src/translations/moment';
import HttpApi from 'i18next-http-backend';
import 'moment/locale/de';
import 'moment/locale/es';

export type SupportedLanguages = 'en' | 'de' | 'es';

export const SUPPORTED_LANGUAGES: SupportedLanguages[] = ['en', 'de', 'es'];

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(HttpApi)
  .init({
    backend: {
      requestOptions: {
        cache: 'no-store',
      },
    },
    supportedLngs: SUPPORTED_LANGUAGES,
    detection: {
      // order and from where user language should be detected
      order: [
        'querystring',
        'cookie',
        'localStorage',
        'sessionStorage',
        'navigator',
      ],

      // keys or params to lookup language from
      lookupFromPathIndex: 0,
      lookupFromSubdomainIndex: 0,

      // cache user language on
      caches: ['localStorage', 'cookie'],
      excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

      // optional expire and domain for set cookie
      cookieMinutes: 43_800,
      cookieDomain: import.meta.env.VITE_DOMAIN,

      // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
      cookieOptions: { path: '/', sameSite: 'strict' },
    },
    fallbackLng: 'en',
  });

i18n.on('languageChanged', (language: SupportedLanguages) => {
  document.documentElement.setAttribute('lang', language);
  moment.updateLocale(language, dates[language]);
});

export const parseJsonTranslation = (jsonSting: string) => {
  try {
    return JSON.parse(jsonSting);
  } catch (error) {
    console.error(error);
  }
};

// eslint-disable-next-line unicorn/prefer-export-from
export default i18n;
