import { Box, Typography } from '@mui/material';
import { ISession } from 'src/types';
import { getEventDateTime } from 'src/utils/date-utils';
import ClockSVG from 'src/assets/icons/clock.svg?react';
import CalendarSVG from 'src/assets/icons/calendar.svg?react';
import { useStyles } from './styles';

interface SessionDetailsProps {
  session: ISession;
}

const SessionDetails = ({ session }: SessionDetailsProps) => {
  const { classes } = useStyles({});

  const { date, time } = getEventDateTime(session.startsAt, 'ddd DD.MM.YYYY');

  return (
    <Box>
      <Typography
        key={session.id}
        variant="buttonDefault"
        className={classes.label}
      >
        {session.name}
      </Typography>
      <Box className={classes.sessionIconsContainer}>
        <Box className={classes.sessionItem}>
          <CalendarSVG className={classes.icon} />
          <Typography component="span" className={classes.iconLabel}>
            {date}
          </Typography>
        </Box>

        <Box className={classes.sessionItem}>
          <ClockSVG className={classes.icon} />
          <Typography component="span" className={classes.iconLabel}>
            {time}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default SessionDetails;
