export const BAD_HOTLINE_TELEPHONE_NUMBER = '0800-0009909';

export const BAD_HOTLINE_FLAG_KEY = 'BAD_HOTLINE';

export const BAD_HOTLINE_TOPICS = [
  {
    key: 'psychosocialCounselling',
    videoKey: 'psychosoziale_beratung',
  },
  {
    key: 'financialDifficulties',
    videoKey: 'schuldnerberatung',
  },
  {
    key: 'careSupport',
    videoKey: 'pflegeberatung',
  },
  {
    key: 'legalAdvice',
    videoKey: 'anwaltliche_erstauskunft',
  },
];
