import { useGetLastAssessment } from 'src/apis/assessment-api';
import WellbeingProgress from './wellbeing-progress';

const WellbeingDetails = () => {
  const { data } = useGetLastAssessment();

  return <WellbeingProgress type="vertical" showDetails {...data} />;
};

export default WellbeingDetails;
