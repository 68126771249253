import { useMutation, useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import backendApi from './config/backend';
import { isBeforeDays } from '../utils/date-utils';
import { useGetMentors, Mentor } from './mentors-api';
import cache from './config/cache';
import { useGetClusterRecommendations } from './recommendation-api';

type LibraryItemType =
  | 'LIBRARY_MEDITATION'
  | 'LIBRARY_EXERCISE'
  | 'LIBRARY_READING'
  | 'LIBRARY_RECORDING';

export type LibraryItemTheme =
  | 'DAILY_PRACTICE'
  | 'DEEP_DIVE'
  | 'FEATURED'
  | 'IMMEDIATE_SUPPORT'
  | 'STARTER';

interface Item {
  id: string;
  kirbyId: string;
  language: string;
  title: string;
  mediaUrl: string;
  imageUrl: string;
  topicKey: string;
  topicKeys: string[];
  topicId: string;
  type: LibraryItemType;
  created: string;
  ranking?: number;
  duration?: number;
  isRecommended?: boolean;
  themes: LibraryItemTheme[];
  isNew?: boolean;
  typeLabel?: string;
  topicsLabel?: string[];
}

interface Reading extends Item {
  readingTime: number;
  category: string;
}

export type RecordingCategory = 'WEBINAR' | 'SHORT_VIDEO';

interface Recording extends Item {
  mentorId: string;
  category: RecordingCategory;
}

interface Meditation extends Item {
  description: string;
}

interface Exercise extends Item {
  description: string;
  pdfUrl: string;
}

export type LibraryItem = Meditation | Exercise | Recording | Reading;

export const sortLibraryItemsByDate = (a: LibraryItem, b: LibraryItem) => {
  const format = ['DD.MM.YYYY', moment.ISO_8601];
  return (
    (b?.created ? moment(b?.created, format).unix() : 0) -
    (a?.created ? moment(a?.created, format).unix() : 0)
  );
};

interface GetLibraryItemRecommendation {
  language: string;
  type?: LibraryItemType;
  clustering?: 'ON' | 'OFF';
}

export const getLibraryItemRecommendation = async ({
  language,
  type,
  clustering,
}: GetLibraryItemRecommendation): Promise<LibraryItem[]> => {
  const libraryItemApi = await backendApi.get('/library-item/recommendation', {
    params: {
      language,
      type,
      clustering,
    },
  });

  return libraryItemApi?.data as LibraryItem[];
};

export const getLibraryItem = async ({
  id,
}: {
  id: string;
}): Promise<LibraryItem> => {
  const libraryItemApi = await backendApi.get(`/library-item/${id}`);

  return libraryItemApi?.data;
};

const consumeLibraryItem = async (id?: string): Promise<any> => {
  const result = await backendApi.post(`/library-item/consumed/${id}`);
  return result?.data;
};

export const useConsumeLibraryItem = () => {
  return useMutation(consumeLibraryItem);
};

const useTransformLibraryItem = () => {
  const { data: mentors } = useGetMentors();
  const { t } = useTranslation();

  const transformLibraryItem = (item: LibraryItem, index?: number) => {
    const mentor = mentors?.find(
      (mentor: Mentor) => mentor?.id === item?.mentorId,
    );

    const typeLabel = {
      LIBRARY_MEDITATION: t('v2.global.label_meditation'),
      LIBRARY_EXERCISE: t('v2.global.label_exercise'),
      LIBRARY_READING: t('v2.exercises.tabMenu_label_readings'),
      LIBRARY_RECORDING: t('v2.exercises.tabMenu_label_videoContent'),
    }[item?.type];

    const categoryLabel = {
      LIBRARY_EXERCISE: '',
      LIBRARY_MEDITATION: '',
      LIBRARY_RECORDING:
        item?.category === 'WEBINAR'
          ? `${t('v2.library.offeringCard_header_video')} ${mentor?.name}`
          : t('v2.library.offeringCard_header_videoShort'),
      LIBRARY_READING: t(
        `v2.exercises.tabView_readings_card_label_${item?.category}`,
      ),
    }[item?.type];

    const topicsLabel = item?.topicKeys.map((topicKey) =>
      t(`v2.global.chip_label_topic_${topicKey}`),
    );

    const itemWithNewProperties = {
      ...item,
      ...(!!index && { ranking: index + 1 }),
      isNew: isBeforeDays({ fromDate: item?.created, days: 30 }),
      typeLabel,
      topicsLabel,
      categoryLabel,
    };

    return mentor
      ? {
          ...itemWithNewProperties,
          mentorName: mentor?.name,
          mentorProfileImage: mentor?.profileImage,
        }
      : itemWithNewProperties;
  };

  return transformLibraryItem;
};

export const useGetLibraryItemRecommendation = ({
  language,
  type,
}: GetLibraryItemRecommendation) => {
  const transformLibraryItem = useTransformLibraryItem();
  const clustering = useGetClusterRecommendations();

  const query = useQuery(
    ['libraryItemRecommendation', language, type, clustering],
    () => getLibraryItemRecommendation({ language, type, clustering }),
    {
      staleTime: cache.ONE_HOUR,
      select: (data: LibraryItem[]) => {
        return data?.map(transformLibraryItem)?.reduce(
          (acc: any, item) => {
            if (!acc.typeCount[item.type]) {
              acc.typeCount[item.type] = 0;
            }

            acc.typeCount[item.type]++;

            if (acc.typeCount[item.type] <= 4) {
              item.isRecommended = true;
            }

            acc.result.push(item);

            return acc;
          },
          { typeCount: {}, result: [] },
        ).result;
      },
    },
  );

  return query;
};

export const useGetLibraryItem = ({ id }: { id: string }) => {
  const transformLibraryItem = useTransformLibraryItem();
  const {
    i18n: { language },
  } = useTranslation();
  const { data: libraryItems } = useGetLibraryItemRecommendation({ language });

  const query = useQuery(['libraryItem', id], () => getLibraryItem({ id }), {
    staleTime: cache.ONE_HOUR,
    enabled: !!id,
    select: (data, index) => {
      const item = transformLibraryItem(data, index);
      return {
        ...item,
        isRecommended: libraryItems?.find(
          (libraryItem) => libraryItem.id === id,
        )?.isRecommended,
      };
    },

    retry: false,
  });

  return query;
};
