import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  wrapper: {
    padding: 25,
    color: theme.colors.darkestGray,
  },
  header: {
    textAlign: 'center',
    marginBottom: 8,
  },
  title: {
    lineHeight: '32px',
    lineSpacing: '0.15px',
    fontSize: '24px',
    fontWeight: 700,
    margin: '16px 0',
  },
  subtitle: {
    fontSize: '16px',
    lineHeight: '21px',
    margin: '16px 0',
  },
  privacySections: {
    display: 'grid',
    gridGap: 16,
  },
  infoBox: {
    paddingLeft: 0,
    borderColor: theme.colors.lightGray,
    '& div': {
      display: 'grid',
      gridGap: 4,
    },
    [theme.breakpoints.down(600)]: {
      padding: 16,
      gap: 8,
      '& div': {
        textAlign: 'center',
      },
    },
  },
}));
