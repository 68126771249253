import { keyframes } from '@emotion/react';
import { makeStyles } from 'tss-react/mui';

const spin = keyframes`
  0% {
    transform: rotate(0deg);
    }

  100% {
      transform: rotate(360deg);
      }
      `;

const useStyles = makeStyles()(() => ({
  animate: {
    width: 'fit-content',
    height: 'fit-content',
    animation: `${spin} 2s linear infinite`,
    '& svg': {
      width: 48,
      height: 48,
    },
  },
  fullScreen: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  baseWidth: {
    width: '10rem',
  },
  center: {
    position: 'absolute',
    top: '45%',
    left: '47%',
    transform: 'translate(-50%, -50%)',
    width: 250,
  },
}));

export default useStyles;
