import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ExpansionPanel from 'src/components/shared/expansion-panel/expansion-panel';
import TimeSVG from 'src/components/event-details/assets/Time.svg?react';
import PriceSVG from 'src/assets/icons/price.svg?react';
import SessionSVG from 'src/components/event-details/assets/session.svg?react';
import useStyles from './styles';
import Tag from '../shared/tag/tag';
import ChipList from '../shared/chip-list/chip-list';
import SectionContent from './section-content';
import { BAD_HOTLINE_TOPICS } from './constants';

const BadHotLineDrawer = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const tagList = [
    { label: t('v2.global.label_badHotline_time'), icon: <TimeSVG /> },
    { label: t('v2.global.label_badHotline_service'), icon: <SessionSVG /> },
    { label: t('v2.global.label_badHotline_price'), icon: <PriceSVG /> },
  ];

  const sectionList = BAD_HOTLINE_TOPICS.map((topic) => ({
    title: t(`v2.global.chip_label_topic_${topic.key}`),
    content: <SectionContent topicKey={topic.key} videoKey={topic.videoKey} />,
  }));

  const topicList = sectionList.map(({ title }) => title);

  return (
    <Box className={classes.badHotline}>
      <Typography variant="h3" className={classes.title}>
        {t('v2.badHotline.drawer_label_title')}
      </Typography>
      <Typography>{t('v2.badHotline.drawer_label_body')}</Typography>

      <Tag.List list={tagList} className={classes.tags} />

      <Typography variant="buttonSmall" className={classes.subtitle}>
        {t('v2.global.label_specialisedIn')}
      </Typography>
      <ChipList list={topicList} />

      <Typography className={classes.info} variant="captionSmall">
        {t('v2.global.label_canSupportOtherTopics')}
      </Typography>

      <Box className={classes.sections}>
        <Typography variant="h324Bold">
          {t('v2.badHotline.drawer_learnMore_label_title')}
        </Typography>
        {sectionList.map((section, index) => (
          <Box key={section.title}>
            <ExpansionPanel defaultOpen={index === 0} {...section} />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default BadHotLineDrawer;
