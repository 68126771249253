import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  topBarContainer: {
    height: 56,
    backgroundColor: theme.colors.white,
    zIndex: 99,
    boxShadow: '0px 6px 12px rgba(27, 27, 28, 0.15)',
    [theme.breakpoints.down('md')]: {
      marginTop: 64,
      height: 'auto',
    },
    display: 'grid',
    gridArea: 'header',
  },
  logoWrapper: {
    marginLeft: 32,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,
    padding: 6,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    '&:hover': {
      backgroundColor: theme.colors.blueGreen,
    },
  },
  assessmentBar: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    paddingRight: 0,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
    },
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  topBarContent: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
  },
  topBarRight: {
    [theme.breakpoints.only('sm')]: {
      marginLeft: 0,
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      flexWrap: 'wrap',
    },
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 'auto',
  },

  buttons: {
    display: 'flex',
    gap: 8,
    margin: '0 24px',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '24px',
    },
  },
  button: {
    background: theme.colors.lightGreen,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    padding: '9.5px 8px',
    cursor: 'pointer',
    borderRadius: 8,
    fontWeight: 700,
    '& svg': {
      flexShrink: 0,
    },

    color: theme.colors.darkestGreen,
    [theme.breakpoints.down('md')]: {
      height: 40,
      justifyContent: 'center',
      width: '100%',
    },
    '&:hover': {
      background: theme.colors.blueGreen,
    },
  },
  privacyButton: {
    '& path': {
      fill: theme.colors.darkestGreen,
    },
  },
  buttonAssessment: {
    [theme.breakpoints.down('md')]: {
      marginBottom: 8,
      top: 70,
    },
  },
  link: {
    color: theme.colors.mediumGreen,
    cursor: 'pointer',
    '&:hover': {
      color: theme.colors.darkestGreen,
    },
    marginRight: 8,
  },
}));
