import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import InfoIcon from 'src/assets/icons/Info.svg?react';
import ProgressBarPills from 'src/components/progress-bar-pills/progress-bar-pills';
import Tooltip from 'src/components/shared/tooltip/tooltip';
import {
  WellbeingDimensionKey,
  wellbeingDimensions,
} from 'src/utils/wellbeing';
import { useDimensionScoreStyles } from './styles';

interface DimensionScoreProps {
  dimension: WellbeingDimensionKey;
  icon: React.ReactNode;
  showDetails: boolean;
  description?: string;
  feedbackText: string;
  score: number;
  disabled: boolean;
  feedbackColor?: string;
}

const DimensionScore = ({
  dimension,
  icon,
  showDetails,
  description,
  feedbackText,
  score,
  disabled,
  feedbackColor,
}: DimensionScoreProps) => {
  const { classes } = useDimensionScoreStyles({
    showDetails,
    disabled,
    feedbackColor,
  });
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  return (
    <Box className={classes.progressGraph}>
      {icon}

      <Box className={classes.mentalAttribute}>
        <Typography
          variant="subtitleBold"
          className={classes.mentalAttributeDescription}
        >
          {t(
            `v2.mentalWellbeing.detailPage_resultCard_label_topics_${dimension}`,
          )}
          <Box component="span" className={classes.mentalAttributeFeedback}>
            • {feedbackText}
            {disabled && (
              <Tooltip
                title={t('v2.global.button_tooltip_emptyBurnout')}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                onClick={() => setOpen(true)}
                onBlur={() => setOpen(false)}
                open={open}
              >
                <Box className={classes.noResultTooltipContent}>
                  <InfoIcon />
                </Box>
              </Tooltip>
            )}
          </Box>
        </Typography>
        <ProgressBarPills
          disabled={disabled}
          value={score}
          originalScale={[
            wellbeingDimensions[dimension as WellbeingDimensionKey]
              ?.needsCare[0],
            wellbeingDimensions[dimension as WellbeingDimensionKey]
              ?.doingGreat[1],
          ]}
        />

        {showDetails && (
          <Typography variant="caption" className={classes.detail}>
            {description}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default DimensionScore;
