import { makeStyles } from 'tss-react/mui';

interface StyleProps {
  maxLines?: number | string;
  noBorder?: boolean;
}

export const useStyles = makeStyles<StyleProps>()(
  (theme, { noBorder, maxLines }) => ({
    wrapper: {
      padding: noBorder ? 0 : 16,
      border: noBorder ? 0 : `2px solid ${theme.colors.greyGreen}`,
      borderRadius: 8,
      display: 'flex',
      gap: 30,
      '& svg': {
        flexShrink: 0,
      },
      [theme.breakpoints.down(600)]: {
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
    text: {
      alignSelf: 'center',
    },
    title: {
      fontSize: noBorder ? 16 : 14,
      lineHeight: noBorder ? '21px' : '18px',
      fontWeight: 700,
    },
    description: {
      fontSize: noBorder ? 16 : 12,
      lineHeight: noBorder ? '21px' : '16px',
      display: '-webkit-box',
      WebkitLineClamp: maxLines ?? 'none',
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
    },
    showMore: {
      marginTop: 12,
      cursor: 'pointer',
      fontWeight: 700,
      color: theme.colors.mediumGreen,
    },
  }),
);
