import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MuiLink from '@mui/material/Link';
import BadHotlineSVG from 'src/assets/icons/bad-hotline.svg?react';
import LinkToSVG from 'src/assets/icons/link-to.svg?react';
import { analyticEvents } from 'src/utils/analytic-events';
import useStyles from './styles';
import { BAD_HOTLINE_TELEPHONE_NUMBER } from './constants';

const trackCall = (from: 'Button' | 'Phone Number') => {
  analyticEvents.badHotlineClicked({ from });
};

const Footer = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const callLink = `tel:${BAD_HOTLINE_TELEPHONE_NUMBER}`;

  return (
    <Box className={classes.footer}>
      <MuiLink
        className={classes.telephoneNumber}
        href={callLink}
        onClick={() => trackCall('Phone Number')}
      >
        <BadHotlineSVG />
        {t('v2.badHotline.drawer_button_badPhoneNumber')}
      </MuiLink>

      <Button
        variant="contained"
        className={classes.callNow}
        href={callLink}
        onClick={() => trackCall('Button')}
      >
        {t('v2.badHotline.drawer_button_callNow')}
        <LinkToSVG />
      </Button>
    </Box>
  );
};

export default Footer;
