import { analyticEvents, Filters } from 'src/utils/analytic-events';
import { useLocation } from 'react-router-dom';
import useSearchParams from './use-search-params';

const FILTER_KEYS: Filters[] = [
  'search',
  'type',
  'topics',
  'language',
  'education',
  'format',
  'duration',
  'time',
  'categories',
];

const useResultsFiltered = () => {
  const { getParams, setSearchParam } = useSearchParams();
  const location = useLocation();

  const resultsFiltered = (params: Partial<Record<Filters, string>>) => {
    setSearchParam(params);

    analyticEvents.resultsFiltered({
      location: location.pathname,
      ...getParams(FILTER_KEYS),
      ...params,
    });
  };

  return { resultsFiltered };
};

export default useResultsFiltered;
