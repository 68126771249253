import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  greetingsContainer: {
    display: 'flex',
    whiteSpace: 'nowrap',
    alignItems: 'center',
    gap: 12,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 24,
    },
  },
}));

export default useStyles;
