import i18next from 'i18next';
import { EventType } from '../types';

export const getEventLabel = (
  type: EventType,
  options?: { plural?: boolean },
) => {
  const eventLabel = {
    [EventType.WEBINAR]: 'v2.global.label_webinar',
    [EventType.ONE_ON_ONE]: 'v2.global.label_1-1Call',
    [EventType.WORKSHOP]: 'v2.global.label_workshop',
    [EventType.EXERCISE]: 'v2.global.label_exercise',
    [EventType.EXCHANGE_GROUP]: 'v2.global.label_exchangeGroup',
    [EventType.MEDITATION]: 'v2.global.label_meditation',
  };

  const label =
    (options?.plural
      ? i18next.t(`${eventLabel[type]}_plural`)
      : i18next.t(eventLabel[type])) ?? '';

  return label;
};
