import { makeStyles } from 'tss-react/mui';
import { useLocation } from 'react-router-dom';
import { Box, Hidden } from '@mui/material';
import LegalLinkFooter from 'src/components/shared/legal-links-footer/legal-links-footer';
import Navbar from './navigation-bar/navigation-bar';
import SideBar from './side-bar';
import { useAppConfiguration } from '../hooks/use-app-configuration';
import TopBar from './top-bar/top-bar';
import TopBarButtons from './top-bar/top-bar-buttons';

interface AuthorizedLayoutProps {
  children?: React.ReactNode;
  className?: string;
}

const useStyles = makeStyles()((theme) => ({
  authorizedLayout: {
    display: 'grid',
    gridTemplateColumns: '84px 1fr',
    gridTemplateRows: '56px 1fr',
    gridTemplateAreas: `
    "header header"
    "sidebar content"
    `,
    height: '100vh',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      gridTemplateAreas: `
      "header header"
      "content content"
      `,
    },
  },
  authorizedLayoutContent: {
    gridArea: 'content',
    overflowY: 'scroll',
    paddingTop: 40,
    display: 'flex',
    flexDirection: 'column',
  },
  footer: {
    marginTop: 'auto',
    display: 'flex',
    justifyContent: 'center',
  },
}));

const AuthorizedLayout = ({ children, className }: AuthorizedLayoutProps) => {
  const { withUnlimitedSessions } = useAppConfiguration();
  const location = useLocation();
  const assessmentPage = location.pathname.includes('assessment');
  const { classes, cx } = useStyles({ withUnlimitedSessions, assessmentPage });

  return (
    <Box component="main" className={classes.authorizedLayout}>
      <TopBar />

      <Hidden mdDown>
        <SideBar />
      </Hidden>

      <Hidden mdUp>
        <Navbar />
      </Hidden>

      <Box
        className={cx(className, classes.authorizedLayoutContent)}
        id="main-content"
      >
        <Hidden mdUp>
          <TopBarButtons />
        </Hidden>

        {children}

        <Box className={classes.footer}>
          <LegalLinkFooter />
        </Box>
      </Box>
    </Box>
  );
};

export default AuthorizedLayout;
