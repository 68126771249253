import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  subtitle: {
    padding: '8px 0',
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 24,
    marginTop: 24,
  },
  offeringCard: {
    marginTop: -44,
  },
}));
