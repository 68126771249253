import { Box, Button } from '@mui/material';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AlertContext from 'src/app/alert-context';
import { useGetUser } from 'src/apis/users-api';
import PaymentOption from './payment-option';
import useStyles from './styles';
import getOptions, { Option } from './get-options';

const PaymentTable = () => {
  const [empty, setEmpty] = useState(false);
  const { classes } = useStyles({});
  const [selected, setSelected] = useState('');
  const { t, i18n } = useTranslation();
  const { showAlert } = useContext(AlertContext);
  const { data: user } = useGetUser();

  const handleOpenCheckout = () => {
    if (!selected) {
      setEmpty(true);
      showAlert({
        severity: 'error',
        message: t('v2.paymentCheckout.drawer_snackbar_label_choosePackage'),
      });

      return;
    }

    const params = {
      prefilled_email: user?.email ?? '',
      locale: i18n.language,
      client_reference_id: user?.id ?? '',
    };

    const searchParams = new URLSearchParams(params).toString();
    const { VITE_STRIPE_BUY_URL } = import.meta.env;

    window.location.href = `${VITE_STRIPE_BUY_URL}/${selected}?${searchParams}`;
  };

  const handleSelect = (id: string) => {
    setEmpty(false);
    setSelected(id);
  };

  return (
    <>
      <Box className={classes.paymentTable}>
        {getOptions().map((option: Option) => (
          <PaymentOption
            key={option.title}
            id={option.id}
            title={option.title}
            price={option.price}
            rate={option.rate}
            previousPrice={option.previousPrice}
            empty={empty}
            checked={selected === option.id}
            onSelect={handleSelect}
            description={t('v2.paymentCheckout.drawer_label_taxes')}
          />
        ))}
      </Box>
      <Box className={classes.footer}>
        <Button
          variant="contained"
          color="primary"
          className={classes.footerButton}
          onClick={handleOpenCheckout}
          // onClick={testSession}
        >
          {t('v2.paymentCheckout.drawer_cta_proceedToPayment')}
        </Button>
      </Box>
    </>
  );
};

export default PaymentTable;
