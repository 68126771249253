import { ReactNode } from 'react';
import clsx from 'clsx';
import { Box } from '@mui/material';
import { useStyles } from './styles';

interface DrawerFooterProps {
  footer?: ReactNode;
  ctaWrapperClassName?: string;
}

const DrawerFooter = ({ footer, ctaWrapperClassName }: DrawerFooterProps) => {
  const { classes } = useStyles();

  if (!footer) return null;

  return (
    <Box className={classes.drawerFooter}>
      <Box className={clsx(classes.ctaWrapper, ctaWrapperClassName)}>
        {footer}
      </Box>
    </Box>
  );
};

export default DrawerFooter;
