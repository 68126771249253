import { round } from 'lodash';
import { useRef } from 'react';

export type OnAudioPlay = (
  currentTimeInSeconds: number,
  durationInSeconds: number,
) => void;

interface UseAudioPlayTimeResult {
  handlePlaying: () => void;
  audioRef: any;
}

const useAudioPlayTime = (onPlay: OnAudioPlay): UseAudioPlayTimeResult => {
  const audioRef = useRef<HTMLAudioElement>(null);

  const handlePlaying = () => {
    const { currentTime = 0, duration = 0 } = audioRef?.current ?? {};

    if (typeof onPlay === 'function') {
      onPlay(round(currentTime, 0), round(duration, 0));
    }
  };

  return {
    audioRef,
    handlePlaying,
  };
};

export default useAudioPlayTime;
