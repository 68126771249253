import axios, { AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios';
import { captureException } from '@sentry/react';

const { VITE_FLAGR_API_URL } = import.meta.env;

const featureFlagApi = axios.create({
  withCredentials: false,
  baseURL: VITE_FLAGR_API_URL,
});

const onRequest = {
  success: async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
    return config;
  },
  error: (error: AxiosError) => {
    captureException(error);
    return Promise.reject(error);
  },
};

const onResponse = {
  success: (response: AxiosResponse) => {
    return response?.data;
  },
  error: (error: AxiosError) => {
    captureException(error);
    return Promise.reject(error);
  },
};

featureFlagApi.interceptors.request.use(onRequest.success, onRequest.error);
featureFlagApi.interceptors.response.use(onResponse.success, onResponse.error);

export default featureFlagApi;
