import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  iconWrapper: {
    verticalAlign: 'middle',
    marginTop: 8,
    marginRight: 10,
  },
  labelWrapper: {
    display: 'inline-block',
    color: theme.colors.darkestGray,
    fontWeight: 700,
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      whiteSpace: 'normal',
    },
  },
  tagList: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 24,
    paddingLeft: 14,
    paddingTop: 24,
  },
}));
