import { colors } from './colors';

const typography = {
  fontFamily: 'Aeonik',
  h1: {
    fontFamily: 'ITC Souvenir',
    fontSize: 48,
    lineHeight: '56px',
  },
  h2: {
    fontFamily: 'ITC Souvenir',
    fontSize: 34,
    lineHeight: '40px',
    letterSpacing: '0.25px',
  },
  h3: {
    fontFamily: 'ITC Souvenir',
    fontSize: 26,
    lineHeight: '32px',
    letterSpacing: '0.15px',
    color: colors.darkestGreen,
  },
  h324Bold: {
    fontFamily: 'Aeonik',
    fontSize: '24px',
    lineHeight: '32px',
    fontWeight: 700,
  },
  h4: {
    fontFamily: 'ITC Souvenir',
    fontSize: 20,
    lineHeight: '26px',
    letterSpacing: '0.15px',
  },
  h4Bold: {
    fontFamily: 'Aeonik',
    fontSize: 20,
    fontWeight: 'bold',
    lineHeight: '26px',
    letterSpacing: '0.15px',
  },
  subtitleCaps: {
    fontFamily: 'Aeonik',
    fontSize: 16,
    lineHeight: '21px',
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
  },
  subtitleBold: {
    fontFamily: 'Aeonik',
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: '21px',
    letterSpacing: '0.4px',
  },
  body1: {
    fontFamily: 'Aeonik',
    fontSize: 16,
    lineHeight: '21px',
    letterSpacing: '0.4px',
  },
  buttonDefault: {
    fontFamily: 'Aeonik',
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: '21px',
    letterSpacing: '0.4px',
  },
  buttonSmall: {
    fontFamily: 'Aeonik',
    fontSize: 14,
    fontWeight: 'bold',
    lineHeight: '18px',
    letterSpacing: '0.5px',
  },
  buttonSmallUnderlined: {
    fontFamily: 'Aeonik',
    fontSize: 14,
    fontWeight: 'bold',
    lineHeight: '18px',
    letterSpacing: '0.5px',
  },
  caption: {
    fontFamily: 'Aeonik',
    fontSize: 14,
    lineHeight: '18px',
    letterSpacing: '0.5px',
  },
  captionSmall: {
    fontFamily: 'Aeonik',
    fontSize: 12,
    lineHeight: '16px',
    letterSpacing: '0.4px',
  },
  captionSmallBold: {
    fontFamily: 'Aeonik',
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 'bold',
    letterSpacing: '0.4px',
  },
};

export default typography;
