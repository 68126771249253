import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import * as sessionReplay from '@amplitude/session-replay-browser';

const useLocationTracker = () => {
  const { pathname, search } = useLocation();

  useEffect(() => {
    const sessionReplayProperties = sessionReplay?.getSessionReplayProperties();

    if (window.analytics) {
      window.analytics.page({
        path: pathname,
        title: document.title,
        ...sessionReplayProperties,
      });
    }
  }, [pathname, search]);
};

export default useLocationTracker;
