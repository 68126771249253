import moment, { Moment } from 'moment-timezone';

interface IsInDateRangeProps {
  fromDate: string | undefined;
  days: number;
  toDate?: string;
  defaultValue?: boolean;
  format?: string;
}

export const getEventDate = (date: string) =>
  `${moment(date).local().format('ddd DD MMM, H:mm ')} ${moment()
    .tz(moment.tz.guess())
    .format('z')}`;

export const getDeadlineDate = (date: string) =>
  moment(date).local().format('DD MMM, YYYY');

export const getEventDateTime = (
  date: string,
  dateFormat = 'ddd DD MMM',
  timeFromat = 'H:mm',
) => ({
  date: moment(date).local().format(dateFormat),
  time: `${moment(date).local().format(timeFromat)} ${moment()
    .tz(moment.tz.guess())
    .format('z')}`,
});

export const isAfterDays = ({
  fromDate,
  days,
  toDate = moment().format(),
  defaultValue,
}: IsInDateRangeProps): boolean =>
  fromDate
    ? moment(toDate).isAfter(moment(fromDate).add(days, 'days'))
    : !!defaultValue;

export const isBeforeDays = ({
  fromDate,
  days,
  toDate = moment().format(),
  defaultValue,
  format = 'DD.MM.YYYY',
}: IsInDateRangeProps): boolean =>
  fromDate
    ? moment(toDate).isBefore(
        moment(fromDate, [moment.ISO_8601, format]).add(days, 'days'),
      )
    : !!defaultValue;

export const getTimeOfDay = (time: Moment) => {
  const MORNING = 6;
  const AFTERNOON = 12;
  const EVENING = 18;
  const NIGHT = 22;

  const hours = time.local().hours();

  if (hours >= MORNING && hours < AFTERNOON) {
    return 'morning';
  }
  if (hours >= AFTERNOON && hours < EVENING) {
    return 'afternoon';
  }

  if (hours >= EVENING && hours < NIGHT) {
    return 'evening';
  }
};
