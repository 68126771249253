import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { imprintURL, privacyPolicyURL, termsURL } from 'src/constants';
import FooterLink from './link-item';
import { useStyles } from './styles';

const LegalLinkFooter = () => {
  const { i18n, t } = useTranslation();
  const { classes } = useStyles();

  const language = i18n.language as 'en' | 'de';

  const links = [
    {
      label: t('v2.global.button_imprint'),
      href: imprintURL[language],
      target: '_blank',
    },
    {
      label: t('v2.global.button_privacy'),
      href: privacyPolicyURL[language],
      target: '_blank',
    },
    {
      label: t('v2.global.button_termsAndConditions'),
      href: termsURL[language],
      target: '_blank',
    },
  ];

  return (
    <Box className={classes.legalLinksWrapper}>
      {links.map((link, index: number) => {
        return (
          <React.Fragment key={link.label}>
            <FooterLink {...link} />
            {index < links.length - 1 && (
              <Typography component="span" className={classes.divider}>
                {' '}
                |{' '}
              </Typography>
            )}
          </React.Fragment>
        );
      })}
    </Box>
  );
};

export default LegalLinkFooter;
