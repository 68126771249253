import { Box, Typography } from '@mui/material';
import Accordion from 'src/components/shared/expansion-panel/expansion-panel';
import { lowerCase } from 'lodash';
import { useTranslation } from 'react-i18next';
import TimeSVG from 'src/components/event-details/assets/Time.svg?react';
import { useStyles } from '../group-sessions/styles';

interface LibraryFormatContentProps {
  durationLabel: string[];
  descriptionItems: string[];
}
enum LibraryItems {
  EXERCISE = 'EXERCISE',
  MEDITATION = 'MEDITATION',
  READING = 'READING',
  VIDEO = 'VIDEO',
}
interface LibraryFormatDetailsProps {
  icon?: React.ReactNode;
  title?: string;
  defaultOpen?: boolean;
  className?: string;
  libraryFormatDetails: {
    libraryFormat?: LibraryItems;
    descriptionItems?: string[];
    durationLabel?: string[];
  };
}
interface LibraryFormatTitleProps {
  libraryFormat: LibraryItems;
  title?: string;
}
export const LibraryFormatContent = ({
  durationLabel,
  descriptionItems,
}: LibraryFormatContentProps) => {
  const { classes } = useStyles();
  return (
    <>
      <Box className={classes.formatTagsContainer}>
        <Box>
          <Typography component="span" className={classes.iconWrapper}>
            <TimeSVG />
          </Typography>
          <Typography
            component="span"
            className={classes.labelWrapper}
            variant="subtitleBold"
          >
            {durationLabel}
          </Typography>
        </Box>
      </Box>
      <ul>
        {descriptionItems?.map((item: string) => (
          <li key={item}>{item}</li>
        ))}
      </ul>
    </>
  );
};
const LibraryFormatTitle = ({
  libraryFormat,
  title,
}: LibraryFormatTitleProps) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <Typography className={classes.expansionPanelTitle}>
      {title && t(`v2.exercises.tabMenu_label_${lowerCase(libraryFormat)}`)}
    </Typography>
  );
};
const LibraryFormatDetails = ({
  libraryFormatDetails,
  title,
  defaultOpen,
  icon,
  className,
}: LibraryFormatDetailsProps) => {
  return (
    <Accordion
      className={className}
      title={
        <LibraryFormatTitle
          libraryFormat={libraryFormatDetails?.libraryFormat}
          title={title}
        />
      }
      content={<LibraryFormatContent {...libraryFormatDetails} />}
      defaultOpen={defaultOpen}
      icon={icon}
    />
  );
};
export default LibraryFormatDetails;
