import { makeStyles } from 'tss-react/mui';

interface DrawerStylesProps {
  type: string;
}

export const useDrawerStyles = makeStyles<DrawerStylesProps>()(
  (theme, { type }) => ({
    drawer: {
      display: 'grid',
      padding: '24px 40px',
      gap: 24,
      '& >div:last-child': {
        marginBottom: 110,
      },
    },
    type: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 24,
      padding: '16px 40px',
      backgroundColor:
        theme.eventColors[type as keyof typeof theme.eventColors],
    },
    duration: {
      display: 'inline-flex',
      alignItems: 'center',
      gap: 8,
    },
    timeIcon: {
      width: 16,
    },
    image: {
      justifySelf: 'center',
      height: 300,
      width: 580,
      borderRadius: 8,
      maxWidth: '100%',
      objectFit: 'cover',
    },
    title: {
      marginBottom: 16,
      wordBreak: 'break-word',
    },
    section: {
      color: theme.colors.edward,
      marginBottom: 16,
    },
    viewExerciseContent: {
      backgroundColor: theme.colors.wildSand,
      color: theme.colors.darkestGreen,
      borderRadius: 8,
      padding: 16,
      display: 'flex',
      alignItems: 'center',
    },
    linkIcon: {
      marginLeft: 'auto',
    },
    exerciseIcon: {
      marginRight: 8,
      height: 16,
      width: 16,
      '& path': {
        stroke: theme.colors.darkestGreen,
      },
      '& rect': {
        fill: theme.colors.darkestGreen,
      },
    },
    viewExercise: {
      cursor: 'pointer',
    },
    button: {
      width: 'fit-content',
      padding: '12px 24px',
      display: 'inline-flex',
      alignItems: 'center',
      gap: 8,
      '& path': {
        fill: theme.colors.white,
      },
    },
    center: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
    },
    placeholderImage: {
      width: '100%',
      height: 'auto',
    },
    labelWrapper: {
      fontWeight: 700,
      fontSize: 24,
      color: theme.colors.curiousBlue,
    },
    recommendationTitle: {
      color: theme.colors.darkestGray,
    },
    recommendationSubtitle: {
      padding: '8px 0',
    },
    recommendationList: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 24,
    },
    recommended: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 42,
      color: theme.colors.bayofMany,
      backgroundColor: theme.colors.aliceBlue,
    },
  }),
);
