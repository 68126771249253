import { Route } from './routes';
import { companyAdminRoutes, userRoutes } from './private-routes';
import publicRoutes from './public-routes';

const getRoutesDetails = (routes: Route[]): any[] => {
  return routes
    .flatMap(({ title, path, subtitle, children }) => {
      return [
        { path, title, subtitle },
        ...(children
          ? getRoutesDetails(
              children.map((child) => ({
                ...child,
                path:
                  path === '/'
                    ? child?.path?.replace('*', '')
                    : `${path}/${child?.path ?? ''}`,
              })),
            )
          : []),
      ];
    })
    .filter(({ path }) => path);
};

export const routesDetails = getRoutesDetails([
  ...userRoutes,
  ...companyAdminRoutes,
  ...publicRoutes,
]);
