import { useState } from 'react';
import AdminContext from './admin-context';

// This context is used for testing/overriding specific app-states
// set/override such app-states at admin page
const AdminProvider = ({ children }: { children: React.ReactNode }) => {
  const [showSteps, setShowSteps] = useState(false);

  return (
    <AdminContext.Provider value={{ onboarding: { showSteps, setShowSteps } }}>
      {children}
    </AdminContext.Provider>
  );
};

export default AdminProvider;
