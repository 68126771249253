import usePrivacyInfo from 'src/components/privacy-info-drawer/use-privacy-info';
import useMentorDrawer from 'src/components/mentor-card/use-individual-session-drawer';
import useEmergencyContactInfo from 'src/components/emergency-contact-info/use-emergency-contact-info';
import useSelfPay from 'src/components/self-pay/use-self-pay';
import useWellbeingDrawer from 'src/pages/wellbeing/use-wellbeing-drawer';
import useSearchParams from 'src/hooks/use-search-params';
import Loader from 'src/components/shared/loader/loader';
import useBadHotline from 'src/components/bad-hotline-drawer/use-bad-hotline';
import useSectionInfo from 'src/components/format-info-drawer/format-info-drawer';
import useLibraryDrawer from 'src/pages/library/use-library-drawer';
import useOfferingDrawer from 'src/components/offering-drawer/use-offering-drawer';
import SideDrawer from '../components/shared/side-drawer/side-drawer';

const AppDrawer = () => {
  const { searchParams } = useSearchParams();
  const mentorDrawer = useMentorDrawer();
  const offeringDrawer = useOfferingDrawer();
  const privacyDrawer = usePrivacyInfo();
  const emergecyContactDrawer = useEmergencyContactInfo();
  const selfpayDrawer = useSelfPay();
  const infoDrawer = useSectionInfo(searchParams.get('info'));
  const wellbeingDrawer = useWellbeingDrawer();
  const libraryDrawer = useLibraryDrawer({});
  const badHotline = useBadHotline();

  const DRAWER_URL_PARAMS = [
    'event-id',
    'psychological-expert-id',
    'library-drawer',
    'self-pay',
    'info',
    'wellbeing-details',
    'bad-hotline',
    'emergency-contact',
    'privacy-info',
  ];

  const drawerName =
    DRAWER_URL_PARAMS.find((key: string) => searchParams.has(key)) ?? '';

  const drawerProps = {
    'event-id': offeringDrawer,
    'psychological-expert-id': mentorDrawer,
    'library-drawer': libraryDrawer,
    'self-pay': selfpayDrawer,
    info: infoDrawer,
    'wellbeing-details': wellbeingDrawer,
    'bad-hotline': badHotline,
    'emergency-contact': emergecyContactDrawer,
    'privacy-info': privacyDrawer,
  }[drawerName];

  return (
    <SideDrawer toggleDrawer={!!drawerName} {...drawerProps}>
      {drawerProps?.loading ? (
        <Loader center fullScreen />
      ) : (
        drawerProps?.content
      )}
    </SideDrawer>
  );
};

export default AppDrawer;
