export const FEEDBACK_RECOMMENDATIONS = {
  WEBINAR: [
    {
      rating: 5,
      description: 'v2.eventDetails.drawer_webinar_testimonials_one_label_body',
      offeringName:
        'v2.eventDetails.drawer_webinar_testimonials_one_label_offeringName',
    },
    {
      rating: 5,
      description: 'v2.eventDetails.drawer_webinar_testimonials_two_label_body',
      offeringName:
        'v2.eventDetails.drawer_webinar_testimonials_two_label_offeringName',
    },
    {
      rating: 4,
      description:
        'v2.eventDetails.drawer_webinar_testimonials_three_label_body',
      offeringName:
        'v2.eventDetails.drawer_webinar_testimonials_three_label_offeringName',
    },
    {
      rating: 4,
      description:
        'v2.eventDetails.drawer_webinar_testimonials_four_label_body',
      offeringName:
        'v2.eventDetails.drawer_webinar_testimonials_four_label_offeringName',
    },
    {
      rating: 5,
      description:
        'v2.eventDetails.drawer_webinar_testimonials_five_label_body',
      offeringName:
        'v2.eventDetails.drawer_webinar_testimonials_five_label_offeringName',
    },
  ],
  WORKSHOP: [
    {
      rating: 5,
      description:
        'v2.eventDetails.drawer_groupWorkshop_testimonials_one_label_body',
      offeringName:
        'v2.eventDetails.drawer_groupWorkshop_testimonials_one_label_offeringName',
    },
    {
      rating: 5,
      description:
        'v2.eventDetails.drawer_groupWorkshop_testimonials_two_label_body',
      offeringName:
        'v2.eventDetails.drawer_groupWorkshop_testimonials_two_label_offeringName',
    },
    {
      rating: 4,
      description:
        'v2.eventDetails.drawer_groupWorkshop_testimonials_three_label_body',
      offeringName:
        'v2.eventDetails.drawer_groupWorkshop_testimonials_three_label_offeringName',
    },
    {
      rating: 5,
      description:
        'v2.eventDetails.drawer_groupWorkshop_testimonials_four_label_body',
      offeringName:
        'v2.eventDetails.drawer_groupWorkshop_testimonials_four_label_offeringName',
    },
    {
      rating: 4,
      description:
        'v2.eventDetails.drawer_groupWorkshop_testimonials_five_label_body',
      offeringName:
        'v2.eventDetails.drawer_groupWorkshop_testimonials_five_label_offeringName',
    },
  ],

  MEDITATION: [
    {
      rating: 5,
      description:
        'v2.eventDetails.drawer_meditation_testimonials_one_label_body',
      offeringName:
        'v2.eventDetails.drawer_meditation_testimonials_one_label_offeringName',
    },
    {
      rating: 5,
      description:
        'v2.eventDetails.drawer_meditation_testimonials_two_label_body',
      offeringName:
        'v2.eventDetails.drawer_meditation_testimonials_two_label_offeringName',
    },
    {
      rating: 4,
      description:
        'v2.eventDetails.drawer_meditation_testimonials_three_label_body',
      offeringName:
        'v2.eventDetails.drawer_meditation_testimonials_three_label_offeringName',
    },
    {
      rating: 5,
      description:
        'v2.eventDetails.drawer_meditation_testimonials_four_label_body',
      offeringName:
        'v2.eventDetails.drawer_meditation_testimonials_three_label_offeringName',
    },
    {
      rating: 4,
      description:
        'v2.eventDetails.drawer_meditation_testimonials_five_label_body',
      offeringName:
        'v2.eventDetails.drawer_meditation_testimonials_one_label_offeringName',
    },
  ],
  EXCHANGE_GROUP: [
    {
      rating: 5,
      description:
        'v2.eventDetails.drawer_exchangeGroup_testimonials_one_label_body',
      offeringName:
        'v2.eventDetails.drawer_exchangeGroup_testimonials_one_label_offeringName',
    },
    {
      rating: 5,
      description:
        'v2.eventDetails.drawer_exchangeGroup_testimonials_two_label_body',
      offeringName:
        'v2.eventDetails.drawer_exchangeGroup_testimonials_two_label_offeringName',
    },
    {
      rating: 4,
      description:
        'v2.eventDetails.drawer_exchangeGroup_testimonials_three_label_body',
      offeringName:
        'v2.eventDetails.drawer_exchangeGroup_testimonials_three_label_offeringName',
    },
    {
      rating: 4,
      description:
        'v2.eventDetails.drawer_exchangeGroup_testimonials_four_label_body',
      offeringName:
        'v2.eventDetails.drawer_exchangeGroup_testimonials_one_label_offeringName',
    },
    {
      rating: 5,
      description:
        'v2.eventDetails.drawer_exchangeGroup_testimonials_five_label_body',
      offeringName:
        'v2.eventDetails.drawer_exchangeGroup_testimonials_five_label_offeringName',
    },
  ],

  EXERCISE: [
    {
      rating: 5,
      description:
        'v2.eventDetails.drawer_exercise_testimonials_one_label_body',
      offeringName:
        'v2.eventDetails.drawer_exercise_testimonials_one_label_offeringName',
    },
    {
      rating: 5,
      description:
        'v2.eventDetails.drawer_exercise_testimonials_two_label_body',
      offeringName:
        'v2.eventDetails.drawer_exercise_testimonials_two_label_offeringName',
    },
    {
      rating: 4,
      description:
        'v2.eventDetails.drawer_exercise_testimonials_three_label_body',
      offeringName:
        'v2.eventDetails.drawer_exercise_testimonials_three_label_offeringName',
    },
    {
      rating: 4,
      description:
        'v2.eventDetails.drawer_exercise_testimonials_four_label_body',
      offeringName:
        'v2.eventDetails.drawer_exercise_testimonials_four_label_offeringName',
    },
    {
      rating: 5,
      description:
        'v2.eventDetails.drawer_exercise_testimonials_five_label_body',
      offeringName:
        'v2.eventDetails.drawer_exercise_testimonials_two_label_offeringName',
    },
  ],
};
