import { analyticEvents } from 'src/utils/analytic-events';
import useSearchParams from './use-search-params';

const useResetFilters = (filterKeys: string[]) => {
  const { getParams, setSearchParam } = useSearchParams();

  const resetFilters = () => {
    filterKeys.forEach((key) => {
      setSearchParam({ [key]: null });
    });

    analyticEvents.filtersReset({
      location: location.pathname,
      ...getParams(filterKeys),
    });
  };

  return { resetFilters };
};

export default useResetFilters;
