import useAppDrawer from 'src/app/use-app-drawer';
import EmergencyContactInfo from './emergency-contact-info';

const useEmergencyContactInfo = () => {
  return useAppDrawer({
    params: ['emergency-contact'],
    track: {
      properties: {
        location: window.location.href,
        type: 'Emergency Contact Drawer',
      },
    },
    value: 'open',
    content: <EmergencyContactInfo />,
  });
};

export default useEmergencyContactInfo;
