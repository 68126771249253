import React from 'react';

type ShowAlertParams = {
  severity: 'error' | 'warning' | 'info' | 'success';
  message: string;
};

type ContextProps = {
  showAlert: (params: ShowAlertParams) => void;
};

const AlertContext = React.createContext<Partial<ContextProps>>({});

export default AlertContext;
