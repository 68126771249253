/* eslint-disable react/self-closing-comp */

import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Box, Typography } from '@mui/material';
import useSearchParams from 'src/hooks/use-search-params';
import { useGetUser } from 'src/apis/users-api';
import ChipInfo from 'src/components/shared/chip-info/chip-info';
import useStyles from './styles';
import PaymentTable from './payment-table';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'stripe-pricing-table': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}

const SelfPay = () => {
  const { searchParams } = useSearchParams();
  const selfPay = searchParams.get('self-pay');
  const { t } = useTranslation();
  const { classes } = useStyles({});
  const { data: user } = useGetUser();
  const subscriptionEndDate = moment(
    user?.subscription?.end,
    'YYYY-MM-DD',
  ).format('DD.MM.YYYY');

  return (
    <Box>
      <Box className={classes.drawerText}>
        <Typography variant="h3">
          {t('v2.paymentCheckout.drawer_label_title')}
        </Typography>
        <Typography className={classes.drawerTextDescription}>
          {t('v2.paymentCheckout.drawer_label_subTitle')}
        </Typography>
        <ChipInfo
          type="info"
          text={t('v2.account.wallet_banner_label_buyMoreCredits', {
            date: subscriptionEndDate,
          })}
        />
      </Box>

      {selfPay && <PaymentTable />}
    </Box>
  );
};

export default SelfPay;
