import React from 'react';
import { Button, Menu, MenuItem, Typography } from '@mui/material';
import GlobeIcon from 'src/assets/icons/globe.svg?react';
import { useGetUser, usePatchUser } from 'src/apis/users-api';
import { SUPPORTED_LANGUAGES } from 'src/translations/i18n';
import { OptionCheckmark } from 'src/components/shared/select/select';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';

const LanguageSelect = () => {
  const { mutate: patchUser } = usePatchUser();
  const { data: user } = useGetUser();
  const { i18n, t } = useTranslation();
  const { classes } = useStyles({});
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLanguageChange = (language: string) => {
    i18n.changeLanguage(language);
    patchUser({ locale: language });

    if (window.analytics && user) {
      window.analytics.identify(user?.id, {
        language,
      });
    }

    handleClose();
  };

  const selectedLanguageLabel = i18n.language.toUpperCase();

  return (
    <>
      <Button onClick={handleClick} className={classes.languageButton}>
        <Typography variant="subtitleBold">{selectedLanguageLabel}</Typography>
        <GlobeIcon />
      </Button>
      <Menu open={Boolean(anchorEl)} onClose={handleClose} anchorEl={anchorEl}>
        {SUPPORTED_LANGUAGES.map((language) => (
          <MenuItem
            onClick={() => onLanguageChange(language)}
            className={classes.languageOption}
            key={language}
          >
            {t(`v2.global.languageSwitcher.${language}`)}
            <OptionCheckmark isSelected={i18n.language === language} />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default LanguageSelect;
