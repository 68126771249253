import useAppDrawer from 'src/app/use-app-drawer';
import PrivacyInfoDrawer from './privacy-info-drawer';

const usePrivacyInfo = () => {
  return useAppDrawer({
    params: ['privacy-info'],
    value: 'open',
    content: <PrivacyInfoDrawer />,
    track: {
      properties: { location: window.location.href, type: 'Privacy Drawer' },
    },
  });
};

export default usePrivacyInfo;
