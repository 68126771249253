import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { find, snakeCase } from 'lodash';
import { EventType, ISession, IBookingEvent } from 'src/types';
import Accordion from 'src/components/shared/expansion-panel/expansion-panel';
import ChipList from 'src/components/shared/chip-list/chip-list';
import OfferingFormatDetails from 'src/components/format-info-drawer/group-sessions/offering-format-details';
import InfoSVG from 'src/assets/icons/Info.svg?react';
import { useGetMentors } from 'src/apis/mentors-api';
import OfferingHeaderShrinking from 'src/components/offering-drawer/offering-details/offering-header-shrinking';
import HostSVG from 'src/assets/icons/profile-green.svg?react';
import useGetOfferingFormatDetails from 'src/components/format-info-drawer/use-get-format-details';
import FeedbackRecommendations from 'src/components/feedback-recommendations/feedback-recommendations';
import { getEventLabel } from 'src/utils/event-type';
import MentorAvatar from 'src/components/shared/mentor-avatar/mentor-avatar';
import { FEEDBACK_RECOMMENDATIONS } from 'src/components/feedback-recommendations/constants';
import { AnonymousBanner } from 'src/components/shared/event-card-title/event-card-title';
import SessionDetails from './session-details';
import OfferingDetailsSection from './offering-details-section';
import { useStyles } from './styles';
import OfferingOtherDates from './offering-other-dates';
import OfferingRating from './offering-rating';

interface OfferingDetailsProps {
  offering: IBookingEvent;
  scrolled: boolean;
}

const OfferingDetails = ({ offering, scrolled }: OfferingDetailsProps) => {
  const { t } = useTranslation();

  const offeringType = offering.type;
  const { classes } = useStyles({ offeringType });
  const { data: mentors } = useGetMentors();
  const MINIMUM_RATING = 4.5;
  const isWorkshop = offering.type === EventType.WORKSHOP;

  const mentor = find(mentors, (item) => item.id === offering.mentor?.id);

  const offeringTopicList = offering.topics.map((topic) =>
    t(`v2.global.chip_label_topic_${topic}`),
  );

  const formatDetails = useGetOfferingFormatDetails();
  const offeringFormatDetail = formatDetails.find(
    (item) => item.offeringFormat === offering.type,
  );

  const offerinDetailsTitle = t(
    'v2.eventDetails.drawer_label_whatAreFormatName',
    {
      formatName:
        offering.type === EventType.EXCHANGE_GROUP
          ? t(`v2.global.label_exchangeGroup_plural`)
          : t(`v2.global.label_${snakeCase(offering.type)}_plural`),
    },
  );

  const mentorGender = t(
    `v2.global.label_gender_${mentor?.gender?.toLowerCase()}`,
  );

  const isAnonymous =
    offering.type === EventType.WEBINAR ||
    offering.type === EventType.MEDITATION;

  return (
    <Box className={classes.detailsContainer}>
      <Box className={classes.headerContainer}>
        <OfferingHeaderShrinking offering={offering} scrolled={scrolled} />
      </Box>
      <Box className={classes.contentContainer}>
        {offering.offeringSubject?.rating >= MINIMUM_RATING && (
          <OfferingRating bookings={offering.offeringSubject?.bookings} />
        )}
        <Typography variant="h2">{offering.name}</Typography>
        {isAnonymous && <AnonymousBanner />}
        <MentorAvatar
          name={mentor?.name}
          subtitle={t('v2.global.label_yourHost')}
          profileImage={mentor?.profileImage}
          size="medium"
          mentorId={mentor?.id}
        />

        {mentor && (
          <Box className={classes.mentorInfoWrapper}>
            <Accordion
              title={t('v2.global.label_hostDetails')}
              icon={<HostSVG />}
              content={
                <Box className={classes.expansionPanel}>
                  <Box className={classes.infoMentorWrapper}>
                    <Typography>
                      {mentor.name} ({mentorGender})
                    </Typography>
                    <Typography className={classes.label}>
                      {mentor?.education}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      className={classes.hostTopicsLabel}
                      variant="body2"
                    >
                      {t('v2.global.label_specialisedIn')}
                    </Typography>
                    <Box>
                      <ChipList list={mentor?.focusTopicsLabels} />
                    </Box>
                    <Typography className={classes.canSupport}>
                      {t('v2.global.label_canSupportOtherTopics')}
                    </Typography>
                  </Box>
                  <Box className={classes.infoMentorWrapper}>
                    <Typography>{mentor.details?.description}</Typography>
                  </Box>
                </Box>
              }
            />
          </Box>
        )}

        <OfferingFormatDetails
          offeringFormatDetails={offeringFormatDetail}
          title={offerinDetailsTitle}
          icon={<InfoSVG />}
        />

        <Box marginTop="24px">
          <Typography variant="buttonSmall" className={classes.sectionLabel}>
            {t('v2.global.label_details')}
          </Typography>
          <Box>
            <OfferingDetailsSection offering={offering} />
          </Box>
        </Box>

        {!!offeringTopicList?.length && (
          <Box className={classes.infoTopicsWrapper}>
            <Typography variant="buttonSmall" className={classes.sectionLabel}>
              {t('v2.global.label_topics')}
            </Typography>

            <ChipList list={offeringTopicList} />
          </Box>
        )}

        {isWorkshop && (
          <Box className={classes.infoWrapper}>
            <Typography variant="buttonSmall" className={classes.sectionLabel}>
              {t('v2.global.label_sessions')}
            </Typography>
            {offering.sessions.map((session: ISession) => (
              <SessionDetails session={session} />
            ))}
          </Box>
        )}

        <Box className={classes.infoWrapper}>
          <Typography variant="buttonSmall" className={classes.sectionLabel}>
            {t('v2.global.label_moreAboutTheFormat')}
          </Typography>
          <Box
            className={classes.offeringDescription}
            dangerouslySetInnerHTML={{
              __html: offering.description,
            }}
          />
        </Box>
        {offering.hasOtherDates && (
          <OfferingOtherDates
            offeringSubjectId={offering.offeringSubject?.id}
            offeringId={offering.id}
          />
        )}
        <FeedbackRecommendations
          formatName={getEventLabel(offering.type, { plural: true })}
          feedbackList={FEEDBACK_RECOMMENDATIONS[offering.type]}
          className={classes.feedbackRecommendations}
        />
      </Box>
    </Box>
  );
};

export default OfferingDetails;
