import { pick } from 'lodash';
import { useNavigate, useLocation } from 'react-router-dom';

type SearchParams = Record<string, string | null | undefined | string[]>;

const useSearchParams = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const setSearchParam = (
    params: SearchParams,
    state?: Record<string, string>,
  ) => {
    Object.entries(params).forEach(([key, value]) => {
      if (value) {
        searchParams.set(key, value);
      } else {
        searchParams.delete(key);
      }
    });

    navigate(
      { pathname: location.pathname, search: searchParams.toString() },
      { state },
    );
  };

  const search = Object.fromEntries(searchParams);
  const getParams = (keys: string[]) => pick(search, keys);

  return { setSearchParam, searchParams, getParams };
};

export default useSearchParams;
