import AppProviders from './app-providers';
import Routes from './routes/routes';

const App = () => {
  return (
    <AppProviders>
      <Routes />
    </AppProviders>
  );
};

export default App;
