import { useMutation } from '@tanstack/react-query';
import { useGetUser } from 'src/apis/users-api';
import { useEffect } from 'react';
import featureFlagApi from './config/feature-flag';

interface EvaluateParams {
  entityID?: string;
  entityType?: string;
  entityContext?: {
    company_id?: string;
  };
  flagID?: number;
  flagKey: string;
  flagTags?: Array<string>;
  flagTagsOperator?: 'ANY' | 'ALL';
  enableDebug?: boolean;
}

interface UseFeatureFlag {
  flagKey: string;
  flagID?: number;
  enableDebug?: boolean;
  fullResponse?: boolean;
}

export interface EvaluateResult {
  evalContext: {
    entityContext: {
      company_id: string;
    };
    entityID: string;
    entityType: string;
    flagKey: string;
  };
  flagID: number;
  flagKey: string;
  flagSnapshotID: number;
  segmentID: number;
  timestamp: string;
  variantAttachment: any;
  variantID: number;
  variantKey: string;
}

const evaluateFlag = async (parameters: EvaluateParams) => {
  const result = await featureFlagApi.post<EvaluateResult>(
    `/evaluation`,
    parameters,
  );

  return result;
};

export const useFeatureFlag = ({
  flagKey,
  flagID,
  enableDebug,
  fullResponse,
  ...mutationProperties
}: UseFeatureFlag): boolean | EvaluateResult | undefined => {
  const { data: user } = useGetUser();
  const mutation = useMutation(evaluateFlag, {
    ...mutationProperties,
  });

  useEffect(() => {
    if (user) {
      const entity = {
        entityID: user?.id,
        entityType: 'user',
        entityContext: {
          company_id: user?.companyId,
        },
        flagID,
        flagKey,
        enableDebug,
      };

      mutation?.mutate(entity);
    }
  }, [user]);

  const showFeature = mutation?.data?.variantKey === 'on';

  return fullResponse ? mutation : showFeature;
};
