import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  mentorsListWrapper: {
    marginTop: '40px',
  },
  backLink: {
    fontWeight: 'normal',
  },
  pageHeaderLink: {
    paddingLeft: 4,
  },
  pageTitle: {
    fontWeight: 700,
    paddingLeft: 4,
  },
  callInfo: {
    color: theme.colors.darkGray,
    textTransform: 'uppercase',
  },
  iconsContainer: {
    paddingTop: 8,
    display: 'inline-flex',
    gap: 16,
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
    },
    flexWrap: 'wrap',
  },
  iconWrapper: {
    display: 'inline-flex',
    alignItems: 'center',
    gap: 8,
    '& svg': {
      marginBottom: 2,
    },
  },
  mentorsSection: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '16px 0',
    [theme.breakpoints.up(1300)]: {
      width: 1200,
    },
    [theme.breakpoints.down(780)]: {
      display: 'block',
    },
  },
  item: {
    paddingRight: 24,
    paddingBottom: 24,
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
      width: '100%',
    },
  },
  availableSessions: {
    marginTop: 24,
  },
}));
