import { makeStyles } from 'tss-react/mui';
import { ITheme } from 'src/style/theme';

const useStyles = makeStyles()((theme: ITheme) => ({
  wellbeingResult: {
    maxWidth: 1200,
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: 72,
    overflow: 'visible',
    [theme.breakpoints.down(900)]: {
      gridTemplateColumns: '1fr',
    },
    '&:hover': {
      boxShadow: '0px 6px 12px rgb(27 27 28 / 15%)',
    },
  },
  description: {
    display: 'grid',
    gap: 24,
    position: 'relative',
    gridTemplateRows: 'auto auto auto minmax(100px,auto)',
    '&:after': {
      content: '""',
      right: -36,
      position: 'absolute',
      width: '2px',
      height: '100%',
      background: theme.colors.iron,
      [theme.breakpoints.down(900)]: {
        display: 'none',
      },
    },
  },
  descriptionTitle: {
    marginBottom: -16,
  },
  button: {
    height: 48,
    alignSelf: 'end',
    width: 'fit-content',
    justifySelf: 'center',
  },
  notice: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down(450)]: {
      flexWrap: 'wrap',
    },
    gap: 24,
    '& > svg': {
      flexShrink: 0,
      height: 64,
    },
  },
}));

interface WellbeingProgressStylesProps {
  type: 'horizontal' | 'vertical';
  showDetails?: boolean;
  showMore: boolean;
  firstTwoRowsHeight: number;
}

export const useWellbeingProgressStyles =
  makeStyles<WellbeingProgressStylesProps>()(
    (theme, { type, showDetails, showMore, firstTwoRowsHeight }) => ({
      wellbeingProgress: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      },
      wellbeingProgressBars: {
        position: 'relative',
        margin: showDetails ? '24px 40px' : 0,
        overflow: 'hidden',
        maxHeight:
          showMore || type === 'vertical' ? '100%' : 60 + firstTwoRowsHeight,
        [theme.breakpoints.down(850)]: {
          maxHeight:
            showMore || type === 'vertical' ? '100%' : firstTwoRowsHeight + 100,
        },
        [theme.breakpoints.down(524)]: {
          maxHeight: showMore ? '100%' : firstTwoRowsHeight + 130,
        },
        [theme.breakpoints.down(380)]: {
          maxHeight: showMore ? '100%' : firstTwoRowsHeight + 150,
        },
      },
      action: {
        display: 'inline-flex',
        alignItems: 'center',
        gap: 8,
        color: theme.colors.mediumGreen,
        '& path': {
          fill: theme.colors.mediumGreen,
        },
        cursor: 'pointer',
      },
      detailsTitle: {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        width: '100%',
        gap: 8,
        marginBottom: 24,
      },
      progress: {
        display: 'grid',
        gap: showDetails ? 40 : 24,
        [theme.breakpoints.up(850)]: {
          gridTemplateColumns: type === 'horizontal' ? '1fr 1fr' : '1fr',
        },
      },
      wellbeingDetailsFooter: {
        margin: 24,
      },
      showMoreIcon: {
        transform: showMore ? 'rotate(180deg)' : 'rotate(0deg)',
      },
    }),
  );

interface DimensionScoreStylesProps {
  showDetails?: boolean;
  disabled?: boolean;
  feedbackColor?: string;
}

export const useDimensionScoreStyles = makeStyles<DimensionScoreStylesProps>()(
  (theme, { showDetails, disabled, feedbackColor }: any) => ({
    progressGraph: {
      display: 'flex',
      flexWrap: 'wrap',
      alignContent: 'center',
      alignItems: showDetails ? 'flex-start' : 'center',
      gap: showDetails ? 32 : 24,
      [theme.breakpoints.down(450)]: {
        display: 'grid',
        gap: 8,
      },
      '& >svg path': {
        fill: disabled ? theme.colors.edward : 'auto',
      },
    },
    mentalAttribute: {
      flex: 1,
      display: 'grid',
      gap: 9,
      '& path': {
        fill: theme.colors.mediumGreen,
      },
    },
    mentalAttributeFeedback: {
      color: disabled ? theme.colors.edward : feedbackColor,
      display: 'inline-flex',
      gap: 8,
      alignItems: 'center',
    },

    mentalAttributeDescription: {
      display: 'inline-flex',
      gap: 8,
      flexWrap: 'wrap',
      color: disabled ? theme.colors.edward : 'inherit',
    },
    noResultTooltipContent: {
      cursor: 'pointer',
      display: 'flex',
    },
    detail: {
      color: disabled ? theme.colors.edward : 'auto',
    },
  }),
);

export default useStyles;
