import { makeStyles } from 'tss-react/mui';
import { ITheme } from 'src/style/theme';
import imageUrl from 'src/assets/icons/public-background.svg';

export const useStyles = makeStyles()((theme: ITheme) => ({
  mainWrapper: {
    height: '100vh',
    display: 'grid',
    gridTemplateColumns: '3fr 2fr',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'auto',
    },
  },
  contentWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  table: {
    display: 'table',
    height: '100%',
    margin: 'auto',
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
      width: '75%',
    },
  },
  tableRow: {
    display: 'table-row',
  },
  switcherWrapper: {
    float: 'right',
  },
  layoutheader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tableCellBody: {
    display: 'table-cell',
    height: '100%',
    verticalAlign: 'middle',
  },
  tableCellFooter: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 24,
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
    },
    color: theme.colors.darkGray,
  },
  illustrationSection: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    backgroundImage: `url(${imageUrl})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    // color: colors.yorkGreen,
  },
  proverb: {
    alignSelf: 'flex-end',
  },
  proverbText: {
    fontFamily: 'Aeonik',
    fontWeight: 'bold',
  },
  link: {
    fontSize: 14,
    color: theme.palette.primary.main,
  },
  languageOption: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px 24px',
    '&:hover': {
      backgroundColor: theme.colors.blueGreen,
    },
  },
  languageButton: {
    padding: '8px 16px',
    display: 'flex',
    gap: 8,
    alignItems: 'center',
    width: 'fit-content',
    '& svg': {
      flexShrink: 0,
      marginBottom: 2,
    },
    [theme.breakpoints.down('sm')]: {
      padding: '8px 8px',
    },
  },
}));
