import { useTranslation } from 'react-i18next';
import { lowerCase } from 'lodash';
import { parseJsonTranslation } from 'src/translations/i18n';

type LibraryItems = 'EXERCISES' | 'MEDITATIONS' | 'READINGS' | 'VIDEOS';

interface LibraryDetails {
  libraryFormat: LibraryItems;
  descriptionItems: string[];
  durationLabel: string;
}

const useGetLibraryDetails = (): LibraryDetails[] => {
  const { t } = useTranslation();
  const libraryContentType: LibraryItems[] = [
    'EXERCISES',
    'MEDITATIONS',
    'READINGS',
    'VIDEOS',
  ];

  const libraryDetails = libraryContentType.map((libraryFormat) => {
    const durationLabels = t(
      `v2.offeringOverview.drawer_library_label_duration_${lowerCase(
        libraryFormat,
      )}`,
    );
    return {
      libraryFormat,
      descriptionItems: parseJsonTranslation(
        t(
          `v2.offeringOverview.drawer_library_label_descriptionList_${lowerCase(
            libraryFormat,
          )}`,
        ),
      ),
      durationLabel: durationLabels,
    };
  });
  return libraryDetails;
};

export default useGetLibraryDetails;
