import { makeStyles } from 'tss-react/mui';
import { AlertSeverity } from 'src/style/theme';

interface StyleProps {
  severity: AlertSeverity;
  type?: 'popup' | 'static';
}

const snackBarShadow =
  '0px 3px 5px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)';

export const useStyles = makeStyles<StyleProps>()(
  (theme, { severity, type }) => ({
    root: {
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(0),
      alignItems: 'center',
      backgroundColor: theme.alertBackground[severity],
      boxShadow: type === 'popup' ? snackBarShadow : 'none',
      borderRadius: type === 'popup' ? 32 : 8,
      border: 'none',
      [theme.breakpoints.up('xs')]: {
        width: 321,
      },
      [theme.breakpoints.up('sm')]: {
        width: 516,
      },
      [theme.breakpoints.up('md')]: {
        width: 'auto',
        minWidth: 600,
        maxWidth: 710,
      },
    },

    icon: {
      padding: theme.spacing(1, 0),
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(type === 'popup' ? 2 : 1, 0),
      },
    },
    message: {
      fontSize: 16,
      lineHeight: '18px',
      [theme.breakpoints.up('sm')]: {
        padding: type === 'popup' ? theme.spacing(2, 0) : '8px 16px',
      },
      padding: theme.spacing(1, 0),
      color: theme.alertColor[severity],
    },
    action: {
      paddingRight: theme.spacing(1),
      '& path': {
        fill: theme.alertColor[severity],
      },
    },
  }),
);
