import React, { useState, useCallback } from 'react';
import { makeStyles } from 'tss-react/mui';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { colors } from 'src/style/colors';
import { Typography } from '@mui/material';
import EyeIconSVG from './assets/eye.svg?react';
import Input from '../input/input';

interface StylesProps {
  passwordVisible: boolean;
  inputValue: string;
}

const useStyles = makeStyles<StylesProps>()(
  (theme, { passwordVisible, inputValue }) => ({
    toggleIcon: {
      '& path': {
        fill: inputValue
          ? passwordVisible
            ? colors.gainsboro
            : colors.darkestGray
          : colors.lightGray,
      },
    },
  }),
);

interface FormPasswordInputProps {
  id: string;
  name: string;
  required?: boolean;
  minLength?: number;
  disabled?: boolean;
  validate?: () => boolean;
  info?: string | false;
}

const FormPasswordInput = ({
  id,
  name,
  required,
  minLength,
  disabled,
  validate,
  info,
  ...rest
}: FormPasswordInputProps) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const { watch } = useFormContext();
  const inputValue = watch(name);
  const inputType = passwordVisible ? 'text' : 'password';

  const { classes } = useStyles({
    passwordVisible,
    inputValue,
  });

  const { t } = useTranslation();
  const { errors, register } = useFormContext();

  const generateValidators = useCallback(() => {
    const validators = { required: '' };
    if (required && info === undefined) {
      validators.required = t('v2.global.label_helperText_mandatoryField');
    } else if (required && info) {
      validators.required = info;
    }
    if (minLength) {
      validators.minLength = minLength;
    }
    if (validate) {
      validators.validate = validate;
    }
    return validators;
  }, [required, minLength, validate, t]);
  return (
    <>
      <Input
        id={id}
        type={inputType}
        name={name}
        error={errors[name]}
        ref={register(generateValidators())}
        icon={
          <Box
            component={IconButton}
            size="small"
            mt={1}
            mr={1.5}
            ml={1.5}
            onClick={() =>
              setPasswordVisible((passwordVisible) => !passwordVisible)
            }
            disabled={!inputValue || disabled}
            aria-label={t('FormInput.showPassword')}
          >
            <EyeIconSVG className={classes.toggleIcon} />
          </Box>
        }
        required={required}
        {...rest}
      />

      {info && !errors[name] && (
        <Typography pt="8px" variant="caption">
          {info}
        </Typography>
      )}
    </>
  );
};

export default FormPasswordInput;
