import { Box, BoxProps, Typography } from '@mui/material';
import { useStyles } from './styles';

interface RegistrationHeaderProps extends BoxProps {
  title: string;
  subtitle?: string;
}

const FormHeader = ({
  title,
  subtitle,
  ...boxProps
}: RegistrationHeaderProps) => {
  const { classes } = useStyles();
  return (
    <Box className={classes.formTitle} {...boxProps}>
      <Typography variant="h2">{title}</Typography>
      {subtitle && <Typography>{subtitle}</Typography>}
    </Box>
  );
};

export default FormHeader;
