import { useQuery } from '@tanstack/react-query';
import backendApi from './config/backend';
import cache from './config/cache';
import { useGetUser } from './users-api';

export type RecommendationType = 'MENTOR' | 'COMMUNITY_SESSION' | 'LIBRARY';

const getRecommendedFormat = async (): Promise<RecommendationType> => {
  const result = await backendApi.get(`/recommendation/format`);

  return result;
};

export const useGetRecommendedFormat = () =>
  useQuery(['recommendedFormat'], getRecommendedFormat, {
    staleTime: cache.ONE_HOUR,
  });

export const useGetClusterRecommendations = (): 'ON' | 'OFF' => {
  const { data: user } = useGetUser();

  const clustering = user?.lastAssessment?.WEBAPP_ONBOARDING ? 'OFF' : 'ON';

  return clustering;
};
