import GroupSessionsInfo from '../group-sessions/group-sessions';
import MentorsInfo from '../mentors/mentors';
import LibraryInfo from '../library/library';

const AllFormatInfoDrawer = () => {
  return (
    <>
      <GroupSessionsInfo />
      <LibraryInfo />
      <MentorsInfo />
    </>
  );
};
export default AllFormatInfoDrawer;
