import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  wrapper: {
    width: '100%',
  },
  bar: {
    display: 'flex',
    gap: 8,
    alignItems: 'center',
  },
  item: {
    flex: '1 1 28px',
    backgroundColor: theme.colors.gainsboro,
    textAlign: 'center',
    height: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,
    border: `1px solid ${theme.colors.iron}`,
  },
  belowValue: {
    backgroundColor: theme.colors.havelockBlue,
    borderColor: theme.colors.havelockBlue,
  },
  active: {
    height: 42,
    backgroundColor: theme.colors.darkestGreen,
    borderRadius: 8,
    color: theme.colors.white,
    fontSize: 14,
  },
}));
