/* eslint-disable react-refresh/only-export-components */
import { lazy } from 'react';

const Login = lazy(() => import('src/pages/login/login'));
const Registration = lazy(() => import('src/pages/registration/registration'));
const SelectCompany = lazy(
  () => import('src/pages/select-company/select-company'),
);
const RegistrationSuccess = lazy(
  () => import('src/pages/registration/registration-success'),
);
const VerificationExpired = lazy(
  () => import('src/pages/registration/verification-expired'),
);
const ForgotPassword = lazy(
  () => import('src/pages/forgot-password/forgot-password'),
);
const ForgotPasswordSuccess = lazy(
  () => import('src/pages/forgot-password/forgot-password-success'),
);
const ResetPassword = lazy(
  () => import('src/pages/reset-password/reset-password'),
);
const ActivateAccount = lazy(
  () => import('src/pages/activate-account/activate-account'),
);

const publicRoutes = [
  {
    path: '/login',
    element: <Login />,
    title: 'v2.logIn.header_label_title',
  },
  {
    path: '/registration',
    title: 'v2.registration.header_label_title',
    children: [
      {
        index: true,
        element: <Registration />,
      },
      {
        path: 'select-company',
        title: 'v2.registration.header_label_title',
        element: <SelectCompany />,
      },
      {
        path: ':companyId',
        element: <Registration />,
        title: 'v2.registration.header_label_title',
        subtitle: 'v2.registration.header_label_subTitle',
      },
      {
        path: 'success',
        element: <RegistrationSuccess />,
        title: 'v2.verification.header_label_title',
      },
      {
        path: 'verification-expired',
        element: <VerificationExpired />,
        title: 'v2.verificationLinkExpired.header_label_title',
      },
    ],
  },
  {
    path: '/forgot-password',
    title: 'v2.forgotPassword.header_label_title',
    children: [
      {
        index: true,
        element: <ForgotPassword />,
      },
      {
        path: 'success',
        element: <ForgotPasswordSuccess />,
        title: 'v2.passwordEmailConfirmation.header_label_title',
      },
    ],
  },
  {
    path: '/reset-password',
    element: <ResetPassword />,
    title: 'ResetPassword.title',
  },
  {
    path: '/activate-account',
    element: <ActivateAccount />,
    title: 'ActivateAccount.title',
    subtitle: 'ActivateAccount.subtitle',
  },
];

export default publicRoutes;
