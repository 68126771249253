/* eslint-disable react/no-unused-prop-types */

import clsx from 'clsx';
import { Typography } from '@mui/material';
import { useStyles } from './styles';

interface HeaderItem {
  component: React.ReactNode;
  className: string;
  classNameScrolled?: string;
  show?: boolean;
  type: 'primary' | 'secondary' | 'tertiary';
}

interface DrawerHeaderProps {
  items: HeaderItem[];
  scrolled?: boolean;
}

const DrawerHeader = ({ items, scrolled }: DrawerHeaderProps) => {
  const hasSecondaryBanner = items.some(
    (item) => item.show && item.type === 'secondary',
  );
  const { classes } = useStyles({ hasSecondaryBanner });

  return (
    <>
      {items.map(
        ({
          component,
          className,
          classNameScrolled,
          show = true,
          type,
        }: HeaderItem) => {
          const baseClasses = {
            [classes.primaryHeader]: type === 'primary',
            [classes.secondaryHeader]: type === 'secondary',
            [className]: className,
          };

          return (
            <>
              {show && (
                <Typography
                  key={component}
                  className={clsx({
                    ...baseClasses,
                    [classes.hide]: scrolled,
                  })}
                  variant="buttonSmall"
                >
                  {component}
                </Typography>
              )}

              {scrolled && show && (
                <Typography
                  className={clsx({
                    ...baseClasses,
                    [classes.primaryHeaderScrolled]: type === 'primary',
                    [classes.secondaryHeaderScrolled]: type === 'secondary',
                    [classes.tertiaryHeaderScrolled]: type === 'tertiary',
                    [classNameScrolled]: classNameScrolled,
                  })}
                  variant="buttonSmall"
                >
                  {component}
                </Typography>
              )}
            </>
          );
        },
      )}
    </>
  );
};

export default DrawerHeader;
