import MediaChromeSkin from './media-chrome-skin';
import useAudioPlayTime, { OnAudioPlay } from './use-audio-play-time';

interface AudioPlayerProps {
  url?: string;
  onPlay: OnAudioPlay;
  onEnded: () => void;
}

export const AudioPlayer = ({ url, onPlay, onEnded }: AudioPlayerProps) => {
  const { audioRef, handlePlaying } = useAudioPlayTime(onPlay);

  return (
    <MediaChromeSkin>
      <audio
        slot="media"
        ref={audioRef}
        preload="metadata"
        key={url}
        controls
        controlsList="nofullscreen nodownload noremoteplayback noplaybackrate"
        onPlaying={handlePlaying}
        onEnded={onEnded}
      >
        <source src={url} type="audio/mp3" />
      </audio>
    </MediaChromeSkin>
  );
};
