const { VITE_WEBSITE_URL } = import.meta.env;

export const privacyPolicyURL = {
  de: `${VITE_WEBSITE_URL}/de/datenschutzerklarung`,
  en: `${VITE_WEBSITE_URL}/en/data-privacy`,
  es: `${VITE_WEBSITE_URL}/en/data-privacy`,
};

export const termsURL = {
  de: `${VITE_WEBSITE_URL}/de/agb`,
  en: `${VITE_WEBSITE_URL}/en/gtc`,
  es: `${VITE_WEBSITE_URL}/en/gtc`,
};

export const imprintURL = {
  de: `${VITE_WEBSITE_URL}/de/impressum`,
  en: `${VITE_WEBSITE_URL}/en/imprint`,
  es: `${VITE_WEBSITE_URL}/en/imprint`,
};

export const consentURL = {
  de: `${VITE_WEBSITE_URL}/de/einwilligung`,
  en: `${VITE_WEBSITE_URL}/en/consent`,
  es: `${VITE_WEBSITE_URL}/en/consent`,
};

export const iOSApp =
  'standalone' in window.navigator || window.navigator.standalone;
