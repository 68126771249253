import { useTranslation } from 'react-i18next';

const useCategoriesFilter = () => {
  const { t } = useTranslation();

  const options = [
    { value: 'recommended', name: t('v2.global.label_recommendedForYou') },
    { value: 'starters', name: t('v2.global.label_forStarters') },
    {
      value: 'recurring',
      name: t(
        'v2.global.filterChip_label_allCategories_forRegularParticipation',
      ),
    },
    { value: 'top-rated', name: t('v2.global.label_topRated') },
  ];

  return {
    name: 'categories',
    label: t('v2.global.filters_dropdown_label_allCategories_filterCount'),
    options,
    multiple: true,
    selectAll: {
      name: t('v2.global.filters_dropdown_label_allCategories'),
      value: '',
    },
    defaultValue: '',
    paperWidth: 310,
  };
};

export default useCategoriesFilter;
