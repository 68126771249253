import { round } from 'lodash';
import { useEffect, useState } from 'react';

const useAudioDuration = (mp3Url?: string | boolean) => {
  const [duration, setDuration] = useState<number | null>(null);

  useEffect(() => {
    if (typeof mp3Url === 'string' && mp3Url) {
      const audio = new Audio(mp3Url);

      const handleLoadedMetadata = () => {
        setDuration(round(audio.duration / 60));
        audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
      };

      audio.addEventListener('loadedmetadata', handleLoadedMetadata);

      return () => {
        audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
      };
    }
  }, [mp3Url]);

  return duration;
};

export default useAudioDuration;
