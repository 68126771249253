import { QueryClient } from '@tanstack/react-query';

import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import cache from './cache';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: cache.ONE_HOUR,
    },
  },
});

export const persister = createSyncStoragePersister({
  storage: window.localStorage,
});
