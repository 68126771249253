import { createContext } from 'react';

interface AdminContext {
  onboarding: {
    showSteps: boolean;
    setShowSteps: (value: boolean) => void;
  };
}

const ADMIN_CONTEXT: AdminContext = {
  onboarding: {
    showSteps: false,
    setShowSteps: (value) => value,
  },
};

const AdminContext = createContext<AdminContext>(ADMIN_CONTEXT);

export default AdminContext;
