import { useTranslation } from 'react-i18next';
import { Typography, Box, Button } from '@mui/material';
import buyMore from 'src/assets/images/buy-more.webp';
import useSearchParams from 'src/hooks/use-search-params';
import { useLocation } from 'react-router-dom';
import { useStyles } from './styles';

const RequestSessions = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { setSearchParam } = useSearchParams();
  const location = useLocation();

  const showSelfPayDrawer = () => {
    setSearchParam(
      {
        'self-pay': 'open',
        'psychological-expert-id': null,
        name: null,
        utm_campaign: null,
      },
      {
        previous: location.search,
      },
    );
  };

  return (
    <Box className={classes.requestSessionsContainer}>
      <img src={buyMore} alt="buy sessions" loading="lazy" />
      <Typography variant="body2">
        {t('v2.mentorOverview.drawer_mentorDetails_label_notEnoughCredits')}
      </Typography>
      <Button
        variant="contained"
        color="primary"
        className={classes.requestSessionsCTA}
        onClick={showSelfPayDrawer}
      >
        {t('v2.global.button_label_buyCredits')}
      </Button>
    </Box>
  );
};

export default RequestSessions;
