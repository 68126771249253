import { Box, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import clsx from 'clsx';
import FeedbackCard from './feedback-card';
import useStyles from './styles';

export interface Feedback {
  rating: number;
  description: string;
  offeringName: string;
}

interface FeedbackRecommendationsProps {
  feedbackList: Feedback[];
  formatName: string;
  className?: string;
}

const FeedbackRecommendations = ({
  feedbackList,
  formatName,
  className,
}: FeedbackRecommendationsProps) => {
  const { classes } = useStyles();

  return (
    <Box className={clsx(classes.feedbackRecommendations, className)}>
      <Typography variant="h324Bold">
        <Trans
          i18nKey="v2.eventDetails.drawer_testimonials_label_title"
          values={{ formatName }}
          components={{
            blue: <Box className={classes.blue} />,
          }}
        />
      </Typography>
      {feedbackList?.map((feedback) => (
        <FeedbackCard key={feedback.description} {...feedback} />
      ))}
    </Box>
  );
};

export default FeedbackRecommendations;
