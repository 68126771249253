import LocalStorage from './local-storage';

const getFromStorage = (key: string) => LocalStorage.getItem(key);
const setInStorage = (key: string, val: string) =>
  LocalStorage.setItem(key, val);
const removeFromStorage = (key: string) => LocalStorage.removeItem(key);

const setAuthInStorage = (accessToken: string, refreshToken: string) => {
  setInStorage('accessToken', accessToken);
  setInStorage('refreshToken', refreshToken);
};

const setUserInStorage = (user: {
  id: string;
  email: string;
  role: string;
}) => {
  setInStorage('userId', user?.id);
  setInStorage('email', user?.email);
  setInStorage('role', user?.role);
};

const removeAuthFromStorage = () => {
  removeFromStorage('accessToken');
  removeFromStorage('refreshToken');
};

const hasAuthInStorage = () => {
  if (getFromStorage('accessToken') && getFromStorage('refreshToken')) {
    return true;
  }
  removeAuthFromStorage();

  return false;
};

export default {
  getFromStorage,
  setInStorage,
  removeFromStorage,
  setAuthInStorage,
  removeAuthFromStorage,
  hasAuthInStorage,
  setUserInStorage,
};
