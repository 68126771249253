import React from 'react';
import { Box, Typography } from '@mui/material';
import MentorSVG from 'src/assets/icons/mentor-placeholder.svg?react';
import { useLocation, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import useStyles from './styles';

interface MentorAvatarProps {
  name?: string;
  subtitle?: string;
  profileImage?: string;
  style?: React.CSSProperties;
  size: 'small' | 'medium' | 'large';
  className?: string;
  mentorId?: string;
}

const MentorAvatar = ({
  profileImage,
  name,
  subtitle,
  style,
  size,
  className,
  mentorId,
}: MentorAvatarProps) => {
  const { classes } = useStyles({ size });
  const navigate = useNavigate();
  const location = useLocation();

  const titleVariant = size === 'large' ? 'h3' : 'subtitleBold';
  const subtitleVariant = size === 'large' ? 'body1' : 'subtitleBold';
  const handleClick = () => {
    if (mentorId) {
      navigate(`?psychological-expert-id=${mentorId}`, {
        state: { previous: location.pathname },
      });
    }
  };

  return (
    <Box
      className={clsx(
        classes.mentorBanner,
        mentorId && classes.clickable,
        className,
      )}
      style={style}
      onClick={handleClick}
    >
      {profileImage ? (
        <img alt="Mentor" className={classes.mentorImage} src={profileImage} />
      ) : (
        <MentorSVG className={classes.mentorImage} />
      )}
      <Box className={classes.ellipsis}>
        <Typography variant={titleVariant} className={classes.ellipsis}>
          {name}
        </Typography>
        <Typography
          variant={subtitleVariant}
          className={clsx(classes.ellipsis, classes.subtitle)}
        >
          {subtitle}
        </Typography>
      </Box>
    </Box>
  );
};

export default MentorAvatar;
