import { Card } from '@mui/material';
import clsx from 'clsx';
import SmallCardOffering from './small-card-offering';
import useStyles from './styles';

interface SmallCardProps {
  children?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  className?: string;
}

const SmallCard = ({ children, onClick, className }: SmallCardProps) => {
  const { classes } = useStyles({ clickable: typeof onClick === 'function' });
  return (
    <Card className={clsx(classes.smallCard, className)} onClick={onClick}>
      {children}
    </Card>
  );
};

SmallCard.Offering = SmallCardOffering;

export default SmallCard;
