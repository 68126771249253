import { makeStyles } from 'tss-react/mui';

interface StyleProps {
  clickable?: boolean;
}

const useStyles = makeStyles<StyleProps>()((theme, { clickable }) => ({
  smallCard: {
    width: '100%',
    cursor: clickable ? 'pointer' : 'default',
    padding: 24,
    display: 'grid',
    gap: 16,
    borderColor: 'transparent',
  },
  header: {
    display: 'flex',
    gap: 16,
    color: theme.colors.curiousBlue,
  },
  duration: {
    display: 'inline-flex',
    alignItems: 'center',
    gap: 8,
    '& svg': {
      width: 16,
      height: 16,
    },
    '& path': {
      fill: theme.colors.curiousBlue,
    },
  },
  title: {
    display: '-webkit-box',
    WebkitLineClamp: `${2}`,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  },
  subtitle: {
    display: 'flex',
    gap: 16,
    color: theme.colors.edward,
    flexWrap: 'wrap',
  },
  subtitleDetail: {
    display: 'inline-flex',
    alignItems: 'center',
    gap: 8,
    '& svg': {
      width: 16,
      height: 16,
    },
    '& path': {
      fill: theme.colors.edward,
    },
  },
  prebook: {
    margin: '16px 0',
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
  },
  seeDetails: {
    color: theme.colors.mediumGreen,
    alignSelf: 'center',
    cursor: 'pointer',
  },
}));

export default useStyles;
