import { makeStyles } from 'tss-react/mui';

const mentorImageSize = {
  large: 64,
  medium: 48,
  small: 32,
};

interface StylesProps {
  size: keyof typeof mentorImageSize;
}

const useStyles = makeStyles<StylesProps>()((theme, { size }) => ({
  banner: {
    height: 72,
    background: theme.eventColors.ONE_ON_ONE,
    borderRadius: '16px 16px 0px 0px',
  },
  mentorBanner: {
    display: 'flex',
    margin: size === 'large' ? '24px 0' : '16px 0',
    gap: size === 'large' ? 16 : 8,
    alignItems: 'center',
  },
  mentorImage: {
    width: mentorImageSize[size],
    height: mentorImageSize[size],
    borderRadius: '100%',
  },
  ellipsis: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: 200,
  },
  subtitle: {
    color: size === 'large' ? 'inherit' : theme.colors.darkGray,
  },
  clickable: {
    cursor: 'pointer',
  },
}));

export default useStyles;
