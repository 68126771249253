import {
  Tooltip as MuiTooltip,
  TooltipProps as MuiTooltipProps,
  tooltipClasses,
} from '@mui/material';
import { styled } from '@mui/material/styles';

type TooltipVariant = 'captionSmallBold' | 'buttonSmall';

interface TooltipProps extends MuiTooltipProps {
  variant?: TooltipVariant;
}

const Tooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))(({ theme, variant }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 8,
    boxShadow: '0px 6px 12px rgba(27, 27, 28, 0.15)',
    padding: 16,
    color: theme.colors.darkestGray,
    fontSize: 12,
    fontWeight: 700,
    letterSpacing: 0.4,
    lineHeight: '16px',
    width: 'fit-content',
    height: 'fit-content',
    textAlign: 'left',
    boxSizing: 'content-box',
    ...theme.typography[variant],
  },
}));

export default Tooltip;
