import { BookingSource } from 'src/apis/bookings-api';
import useAppDrawer from 'src/app/use-app-drawer';
import GroupSessionsInfo from './group-sessions/group-sessions';
import LibraryInfo from './library/library';
import MentorsInfo from './mentors/mentors';
import AllFormatInfoDrawer from './all-formats-info-drawer/all-format-info-drawer';

const useSectionInfo = (
  type:
    | 'GROUP_SESSIONS_EXPLANATION'
    | 'INDIVIDUAL_SESSIONS_EXPLANATION'
    | 'LIBRARY_EXPLANATION'
    | 'ALL_FORMATS_EXPLANATION',
  source: BookingSource,
) => {
  const content = {
    GROUP_SESSIONS_EXPLANATION: <GroupSessionsInfo />,
    INDIVIDUAL_SESSIONS_EXPLANATION: <MentorsInfo />,
    LIBRARY_EXPLANATION: <LibraryInfo />,
    ALL_FORMATS_EXPLANATION: <AllFormatInfoDrawer />,
  }[type];

  return useAppDrawer({
    params: ['info'],
    value: type,
    content,
    track: {
      properties: { location: window.location.href, type, source },
    },
  });
};

export default useSectionInfo;
