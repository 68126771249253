import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  title: {
    display: '-webkit-box',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  },
  subtitle: {
    color: theme.colors.darkGray,
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  },
  date: {
    display: 'inline-flex',
    alignItems: 'center',
    gap: 8,
    marginBottom: 16,
  },
  mentor: {
    margin: '8px 0 0 0',
  },
  anonymity: {
    margin: '8px 0',
    color: theme.colors.edward,
    display: 'flex',
    gap: 8,
    alignItems: 'center',
  },
  recurringIcon: {
    display: 'flex',
  },
  rating: {
    display: 'inline-flex',
    gap: 4,
    alignItems: 'center',
  },
  ratingLabel: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

export default useStyles;
