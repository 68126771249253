import { makeStyles } from 'tss-react/mui';

interface StyleProps {
  open?: boolean;
}

export const useStyles = makeStyles<StyleProps>()((theme, { open }) => ({
  bar: {
    backgroundColor: theme.colors.gainsboro,
    height: 53,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: 8,
    paddingLeft: 17,
    paddingRight: 17,
  },
  expansionPanelHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  expansionPanelTitle: {
    color: theme.colors.darkestGreen,
    paddingLeft: 9,
    fontWeight: 700,
  },
  arrow: {
    transform: open ? 'rotate(180deg)' : '',
    transition: 'transform 200ms ease',
    '& path': {
      fill: theme.colors.darkestGreen,
    },
  },
}));
