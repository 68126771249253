import { useTranslation } from 'react-i18next';
import { getLanguageLabel } from 'src/utils';
import { IBookingEvent } from 'src/types';

const useLanguageFilter = (upcomingEvents: Array<IBookingEvent>) => {
  const { t } = useTranslation();

  const sessionsLanguages = [
    ...new Set(upcomingEvents.map((event) => event.language).filter(Boolean)),
  ];

  const options = sessionsLanguages.map((value) => ({
    value,
    name: getLanguageLabel(value),
  }));

  return {
    name: 'language',
    label: t('v2.global.filters_dropdown_label_languages_filterCount'),
    options,
    multiple: true,
    selectAll: {
      name: t('v2.global.filterChip_label_allLanguages'),
      value: '',
    },
    defaultValue: '',
  };
};

export default useLanguageFilter;
