import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { Mentor } from 'src/apis/mentors-api';
import { getLanguageLabel } from 'src/utils';
import LanguageSVG from './assets/language.svg?react';
import GenderSVG from './assets/gender.svg?react';
import { useStylesMentorTags } from './styles';

interface MentorTagsProps {
  mentor: Mentor;
}

const MentorTags = ({ mentor }: MentorTagsProps) => {
  const { classes } = useStylesMentorTags();
  const { t } = useTranslation();

  return (
    <Box className={classes.mentorTagsContainer}>
      <Box component="span" className={classes.languageWrapper}>
        <Box component="span" className={classes.iconWrapper}>
          <LanguageSVG className={classes.icon} />
        </Box>
        {mentor?.languages &&
          mentor?.languages.map((language: string, index: number) => {
            return (
              <React.Fragment key={language}>
                <Typography component="span" className={classes.languageLabel}>
                  {language && getLanguageLabel(language)}
                </Typography>
                {index < mentor?.languages!.length - 1 && (
                  <Typography component="span" className={classes.divider}>
                    {' '}
                    |{' '}
                  </Typography>
                )}
              </React.Fragment>
            );
          })}
      </Box>
      <Box className={classes.genderWrapper}>
        <Box component="span" className={classes.iconWrapper}>
          <GenderSVG className={classes.icon} />
        </Box>
        <Typography component="span" className={classes.languageLabel}>
          {t(`v2.global.label_gender_${mentor?.gender.toLowerCase()}`)}
        </Typography>
      </Box>
    </Box>
  );
};

export default MentorTags;
