import { useEffect, useState, UIEvent } from 'react';

const useDrawerScroll = ({ resetBy }: { resetBy?: string | null }) => {
  const [scrolled, setScrolled] = useState(false);

  const onScroll = (e: UIEvent<HTMLElement>) => {
    const { scrollTop } = e.target as HTMLElement;
    if (!scrolled && scrollTop > 5) {
      setScrolled(true);
    }
    if (scrolled && scrollTop < 5) {
      setScrolled(false);
    }
  };

  useEffect(() => {
    setScrolled(false);
  }, [resetBy]);

  return { scrolled, onScroll };
};

export default useDrawerScroll;
