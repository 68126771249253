import { useTranslation } from 'react-i18next';
import { BookingSource } from 'src/apis/bookings-api';
import useSectionInfo from 'src/components/format-info-drawer/format-info-drawer';
import PageHeader from 'src/components/shared/page-header/page-header';

interface LearnMoreAboutIndividualSessionsProps {
  wrapperClassName?: string;
  source: BookingSource;
}

const LearnMoreAboutIndividualSessions = ({
  wrapperClassName,
  source,
}: LearnMoreAboutIndividualSessionsProps) => {
  const { t } = useTranslation();
  const infoDrawer = useSectionInfo('INDIVIDUAL_SESSIONS_EXPLANATION', source);

  const onLearnMore = () => {
    infoDrawer.onOpen();
  };

  return (
    <PageHeader.LearnMore
      description={t('v2.mentorOverview.header_label_button_learnMoreAbout')}
      onClick={onLearnMore}
      wrapperClassName={wrapperClassName}
    />
  );
};

export default LearnMoreAboutIndividualSessions;
