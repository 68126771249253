import { alpha } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  mediaController: {
    '--media-primary-color': theme.colors.mediumGreen,
    '--media-secondary-color': alpha(theme.colors.mediumGreen, 0.2),
    '--media-control-background': theme.colors.white,
    '--media-control-bar-background': theme.colors.white,
    '--media-control-hover-background': theme.colors.white,
    '--media-range-track-background': alpha(theme.colors.mediumGreen, 0.1),
    '--media-range-track-border-radius': '200px',
    '--media-control-height': '48px',
    '--media-control-bar-display': 'flex',
    '--media-range-track-height': '8px',
    '--media-range-thumb-width': '16px',
    '--media-range-thumb-height': '16px',
    '--media-time-range-buffered-color': alpha(theme.colors.mediumGreen, 0.2),
    '--media-preview-time-text-shadow': 'none',
    '--media-preview-time-padding': '0 12px',
    '--media-preview-time-margin': 0,
    '--media-preview-time-border-radius': '4px',
    '--media-preview-thumbnail-border': '2px solid red',
    '--media-control-padding': 0,
    width: '100%',
    background: theme.colors.white,
    [theme.breakpoints.down('sm')]: {
      '--media-control-height': '40px',
      '--media-range-thumb-width': '12px',
      '--media-range-thumb-height': '12px',
      '--media-font-size': '12px',
      '--media-range-track-height': '6px',
    },
    '& media-mute-button': {
      height: 24,
      alignSelf: 'center',
    },
    '& media-control-bar': {
      display: 'flex',
      background: theme.colors.white,
      gap: 16,
      [theme.breakpoints.down('sm')]: {
        gap: 8,
      },
    },
    '& media-time-range': {
      marginLeft: 8,
      flex: 1,
      '--media-font-size': '12px',
      '--media-control-padding': 0,
    },
    '& media-time-display': {
      color: '#0e352d',
    },
    '& media-volume-range': {
      [theme.breakpoints.down('sm')]: {
        width: 50,
      },
    },
  },
}));

export default useStyles;
