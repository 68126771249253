import clsx from 'clsx';
import { Box, Chip, Typography } from '@mui/material';
import InfoCircle from 'src/assets/icons/Info.svg?react';
import { useStyles } from './styles';

interface PageHeaderProps {
  title: string;
  description?: React.ReactNode;
  children?: React.ReactNode;
  id?: string;
  className?: string;
  chipLabel?: string | false;
}

interface LearnMoreProps {
  description: string;
  onClick: () => void;
  wrapperClassName?: string;
}

const LearnMore = ({
  description,
  onClick,
  wrapperClassName,
}: LearnMoreProps) => {
  const { classes } = useStyles();

  return (
    <Box className={clsx(classes.learnMoreWrapper, wrapperClassName)}>
      <Box component="span" className={classes.iconWrapper}>
        <InfoCircle />
      </Box>
      <Typography
        component="span"
        variant="buttonDefault"
        className={classes.learnMore}
        onClick={onClick}
      >
        {description}
      </Typography>
    </Box>
  );
};

const PageHeader = ({
  id,
  title,
  description,
  children,
  className,
  chipLabel,
}: PageHeaderProps) => {
  const { classes } = useStyles();

  return (
    <Box className={className}>
      <Typography
        variant="h3"
        id={id}
        className={classes.title}
        component="div"
      >
        {title}
        {chipLabel && <Chip label={chipLabel} color="skyBlue" />}
      </Typography>

      {description && (
        <Box className={classes.descriptionWrapper}>
          <Typography>{description}</Typography>
          {children}
        </Box>
      )}
    </Box>
  );
};

PageHeader.LearnMore = LearnMore;

export default PageHeader;
