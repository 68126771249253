import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LikeMindedState } from './store';
import { AlertSeverity } from '../style/theme';

interface ToastInfo {
  open: boolean;
  type?: AlertSeverity;
  content?: string;
  autohideDuration?: number;
}

interface RedirectUrl {
  pathname: string;
  search: string;
}

interface CommonSlice {
  redirectUrl?: RedirectUrl;
  toast?: ToastInfo;
}

const initialState = {} as CommonSlice;

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    addToastInfo(state, action: PayloadAction<{ toastInfo?: ToastInfo }>) {
      state.toast = state.toast?.open
        ? { ...state.toast, ...action.payload.toastInfo }
        : action.payload.toastInfo;
    },
    addRedirectUrl(state, action: PayloadAction<RedirectUrl>) {
      state.redirectUrl = action.payload;
    },
  },
});

export const commonReducer = commonSlice.reducer;
export const selectCommon = (state: LikeMindedState) => state.common;
export const { addToastInfo, addRedirectUrl } = commonSlice.actions;
