import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  chipWrapper: {
    display: 'flex',
    gap: 8,
    flexWrap: 'wrap',
    margin: '8px 0',
  },
  chip: {
    background: theme?.eventColors?.default,
    padding: '10px 16px',
    borderRadius: 80,
    whiteSpace: 'nowrap',
  },
  info: {
    background: theme?.colors?.skyBlue,
    color: theme?.colors?.darkBlue,
  },
  success: {
    background: theme?.colors?.icebergGreen,
    color: theme.colors.darkGreen,
  },
  error: {
    background: theme.colors.lightRed,
    color: theme.colors.darkRed,
  },
}));
