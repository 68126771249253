import { makeStyles } from 'tss-react/mui';

interface StyleProps {
  checked?: boolean;
}

const useStyles = makeStyles<StyleProps>()((theme, { checked }) => ({
  selfPay: {
    marginLeft: 20,
    display: 'flex',
    height: '100vh',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 48,
    '& p': {
      textAlign: 'center',
    },
  },
  image: {
    width: 190,
    margin: '72px 0 24px 0',
  },
  descriptionHint: {
    marginBottom: 8,
  },
  button: {
    marginTop: 24,
    width: 220,
  },
  drawerText: {
    padding: 24,
  },
  drawerTextDescription: {
    margin: '8px 0',
  },
  paymentTable: {
    display: 'grid',
    gap: 24,
    padding: '0 24px 24px 24px',
  },
  paymentOption: {
    position: 'relative',
    display: 'grid',
    gap: 8,
    minHeight: 175,
    border: `2px solid ${theme.colors.lightGray}`,
    padding: 24,
    borderRadius: 16,
    cursor: 'pointer',
  },
  checked: {
    borderColor: theme.colors.darkGreen,
  },
  empty: {
    borderColor: theme.colors.darkRed,
  },
  price: {
    display: 'grid',
  },
  check: {
    position: 'absolute',
    top: 24,
    right: 24,
    border: `2px solid ${
      checked ? theme.colors.darkGreen : theme.colors.edward
    }`,
    borderRadius: '100%',
    width: 22,
    height: 22,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  priceValue: {
    fontSize: '48px',
    fontWeight: 'bold',
    lineHeight: '56px',
    '&:after': {
      content: '"€"',
    },
  },
  product: {
    fontSize: '24px',
    fontWeight: 'bold',
    lineHeight: '32px',
    '&:before': {
      content: '""',
    },
  },
  priceTag: {
    display: 'inline-flex',
    alignItems: 'baseline',
    gap: 16,
    flexWrap: 'wrap',
    [theme.breakpoints.down(400)]: {
      gap: 0,
    },
  },
  footer: {
    position: 'sticky',
    bottom: 0,
    backgroundColor: theme.colors.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderTop: `1px solid ${theme.colors.iron}`,
  },
  footerButton: {
    width: 'fit-content',
    margin: 24,
  },
  previousPrice: {
    textDecoration: 'line-through',
    fontWeight: 'normal',
    marginRight: 16,
    '&:after': {
      content: '"€"',
    },
  },
}));

export default useStyles;
