import useAppDrawer from 'src/app/use-app-drawer';
import WellbeingDetails from './wellbeing-result/wellbeing-details';

const useWellbeingDrawer = () =>
  useAppDrawer({
    params: ['wellbeing-details'],
    track: {
      properties: { type: 'Wellbeing details Drawer' },
    },
    content: <WellbeingDetails />,
    value: 'show',
  });

export default useWellbeingDrawer;
