import React from 'react';

type AuthContextProps = {
  authUser: any;
  reloadAuthUser: () => void;
};

const AuthContext = React.createContext<Partial<AuthContextProps>>({});

export default AuthContext;
