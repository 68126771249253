import { makeStyles } from 'tss-react/mui';
import { ITheme } from 'src/style/theme';

export const useStyles = makeStyles()((theme: ITheme) => ({
  line: {
    position: 'fixed',
    top: 0,
    right: 0,
    left: 0,
    zIndex: 1000,
    width: '100%',
    height: 64,
    padding: theme.spacing(2.5, 3),
    backgroundColor: theme.colors.white,
    boxShadow: '0 8 24 rgba(205, 205, 205, 0.16)',
    borderBottom: `4px solid ${theme.colors.white}`,
  },
  lineBorderless: {
    position: 'fixed',
    top: 0,
    right: 0,
    left: 0,
    zIndex: 1000,
    width: '100%',
    height: 64,
    padding: theme.spacing(2.5, 3),
    backgroundColor: theme.colors.white,
    boxShadow: '0 8 24 rgba(205, 205, 205, 0.16)',
    borderBottom: `4px solid ${theme.colors.white}`,
  },
  menu: {
    position: 'fixed',
    top: 64,
    right: 0,
    bottom: 0,
    left: 0,
    padding: theme.spacing(3),
    backgroundColor: theme.colors.white,
    zIndex: 4,
    overflowY: 'scroll',
  },
  logo: {
    marginTop: 6,
    width: 'auto',
    height: 18,
  },
  action: {
    float: 'right',
  },
  actionButton: {
    padding: 0,
    backgroundColor: 'transparent',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    textDecoration: 'none',
    color: theme.colors.mediumGreen,
    '&:hover': {
      opacity: 0.7,
    },
  },
}));
