import moment from 'moment';
import { usePatchUser } from 'src/apis/users-api';
import { useGetOfferings } from 'src/apis/offerings-api';
import { useCreateBooking, useGetBookingHistory } from 'src/apis/bookings-api';
import useRecommendations from 'src/pages/your-recommendations/use-recommendations';
import { analyticEvents } from 'src/utils/analytic-events';
import { IBookingEvent } from 'src/types';
import useOfferingCard from '../offering-card/use-offering-card';

const useBookingWidget = () => {
  const { refetch: refetchBookingHistory } = useGetBookingHistory();
  const { mutate: patchUser } = usePatchUser();
  const { recommendedGroupSessions } = useRecommendations();
  const { data: offerings } = useGetOfferings();

  const { handleShowOfferingDrawer } = useOfferingCard(
    'WEBAPP_PREBOOKED_EVENT',
  );
  const { mutate: createBooking } = useCreateBooking({
    onClose: () => refetchBookingHistory(),
  });

  const recommendedFirstOffering = recommendedGroupSessions?.find((offering) =>
    moment(offering.sessions[0].startsAt).isAfter(moment().add(1, 'days')),
  );
  const beginnerFriendlyOffering = offerings?.find(
    (offering) => offering.isBeginnerFriendly === true && !offering?.isFull,
  );

  const widgetOffering = beginnerFriendlyOffering || recommendedFirstOffering;

  const handleDismiss = () => {
    patchUser({ bookingWidgetDismissedOn: new Date().toISOString() });
    analyticEvents.widgetStatusChanged({
      status: 'dismissed',
    });
  };

  const handleConfirm = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    createBooking({
      ...widgetOffering,
      bookingSource: 'WEBAPP_PREBOOKED_EVENT',
    });
  };

  const handleSeeDetails = (item: IBookingEvent) => {
    handleShowOfferingDrawer(item);
  };

  const trackWidgetExpanded = (open: boolean) => {
    analyticEvents.widgetStatusChanged({
      properties: { status: open ? 'expanded' : 'collapsed' },
    });
  };

  return {
    handleDismiss,
    handleSeeDetails,
    handleConfirm,
    widgetOffering,
    trackWidgetExpanded,
  };
};

export default useBookingWidget;
