import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  root: {
    height: '284px',
    width: '593px',
    padding: 0,
    backgroundColor: theme.colors.white,
    border: `1px solid ${theme.colors.lightGray}`,
    borderRadius: 8,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      placeContent: 'center',
      placeItems: 'flex-start',
      width: '100%',
      height: '100%',
    },
  },
  leftSection: {
    [theme.breakpoints.up('xs')]: {
      height: '100%',
      width: 120,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  rightSection: {
    height: '100%',
    width: '100%',
    padding: 24,
  },
  dateWrapper: {
    width: '100%',
    height: '80%',
    margin: 'auto',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      paddingTop: 24,
    },
    [theme.breakpoints.down('lg')]: {
      padding: '12px 0',
    },
  },
  typeWrapper: {
    width: '100%',
    textAlign: 'center',
    height: '20%',
    [theme.breakpoints.down('sm')]: {
      height: 37,
    },
  },
  eventType: {
    fontWeight: 'bold',
    alignSelf: 'center',
    margin: 'auto',
    padding: '16px 0',
    [theme.breakpoints.down('sm')]: {
      padding: '8px 0',
    },
  },
  cardHeader: {
    display: 'flex',
    placeContent: 'space-between',
  },
  cardDescription: {
    padding: '8px 0',
  },
  mentorImage: {
    width: 48,
    height: 48,
    objectFit: 'cover',
    borderRadius: 60,
    marginRight: theme.spacing(2),
  },
  listContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '75%',
    },
  },
  listUnlimitedContainer: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    paddingTop: 16,
  },
  iconsContainer: {
    display: 'flex',
    width: '50%',
  },
  iconItemContainer: {
    paddingBottom: 16,
    display: 'flex',
    alignItems: 'flex-center',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
      paddingTop: 1,
    },
  },
  labelWrapper: {
    display: 'inline-block',
    whiteSpace: 'nowrap',
    paddingRight: 25,
    [theme.breakpoints.down('sm')]: {
      whiteSpace: 'normal',
    },
  },
  iconWrapper: {
    display: 'flex',
    verticalAlign: 'middle',
    paddingRight: 9,
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  ctaWrapper: {
    marginTop: '5%',
    [theme.breakpoints.up('xs')]: {
      textAlign: 'right',
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  cardButton: {
    width: 'fit-content',
    height: 42,
    fontSize: 14,
    fontWeight: 'bold',
  },
}));
