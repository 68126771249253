import { Box, Typography } from '@mui/material';
import StarReview from 'src/components/shared/star-review/star-review.tsx';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';

interface OfferingRatingProps {
  bookings: number;
}

const OfferingRating = ({ bookings }: OfferingRatingProps) => {
  const { classes } = useStyles({});
  const { t } = useTranslation();
  const MINIMUM_BOOKINGS = 10;
  return (
    <Box className={classes.offeringRating}>
      <StarReview rating={5} label={t('v2.global.label_topRated')} />

      {bookings >= MINIMUM_BOOKINGS && (
        <Typography className={classes.bookedBy} variant="buttonSmall">
          {t('v2.global.label_bookedBy', { count: bookings })}
        </Typography>
      )}
    </Box>
  );
};

export default OfferingRating;
