import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import GroupSessionsIcon from 'src/assets/icons/group-sessions.svg?react';
import { SubscriptionTierOption, useGetUser } from 'src/apis/users-api';
import useStyles from './styles';
import AvailableSessions from './available-sessions';

interface AvailableGroupSessionsProps {
  className?: string;
  hasBackground?: boolean;
  showGroupSessionTimeLabel?: boolean;
}

const AvailableGroupSessions = ({
  className,
  hasBackground,
  showGroupSessionTimeLabel,
}: AvailableGroupSessionsProps) => {
  const { t } = useTranslation();
  const { classes } = useStyles({});
  const { data: user } = useGetUser();

  const hasSubscriptionPlan = user?.subscription?.plan?.tiers?.length;
  const plan = user?.subscription?.plan?.tiers?.find(
    (plan) => plan.type === 'COMMUNITY_SESSION',
  );

  const getSessions = () => {
    if (!hasSubscriptionPlan) {
      return t('v2.global.unlimited');
    }

    return {
      NotAvailable: 0,
      Unlimited: t('v2.global.unlimited'),
      Limited: plan?.limit,
    }[plan?.option as SubscriptionTierOption];
  };

  return (
    <AvailableSessions
      className={className}
      hasBackground={hasBackground}
      showGroupSessionTimeLabel={showGroupSessionTimeLabel}
    >
      <GroupSessionsIcon className={classes.groupSessionsIcon} />
      <Typography variant="subtitleBold" className={classes.groupSessionsLabel}>
        {`${t(
          'v2.dashboard.groupSessions_label_details_availableSessions',
        )} ${getSessions()}`}
      </Typography>
    </AvailableSessions>
  );
};

export default AvailableGroupSessions;
