import i18n from '../translations/i18n';

export const getLanguageLabel = (languageCode: string) => {
  return i18n.t(`v2.global.label_language_${languageCode}`);
};

export const getAvailableSessions = (credits: number | undefined): number =>
  Math.floor((credits ?? 0) / 3);

export const sortByOrder = (
  itemsArray: Record<string, any>[],
  sortingArr: string[],
  key: string,
): any =>
  itemsArray.sort(
    (a: any, b: any) => sortingArr.indexOf(a[key]) - sortingArr.indexOf(b[key]),
  );
