import React from 'react';
import clsx from 'clsx';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import TimeIcon from 'src/assets/icons/time.svg?react';
import useStyles from './styles';

interface AvailableSessionsProps {
  hasBackground?: boolean;
  duration?: number | string;
  children?: React.ReactNode;
  className?: string;
  showGroupSessionTimeLabel?: boolean;
}

const AvailableSessions = ({
  hasBackground,
  duration,
  children,
  className,
  showGroupSessionTimeLabel,
}: AvailableSessionsProps) => {
  const { classes } = useStyles({ hasBackground });
  const { t } = useTranslation();

  return (
    <Box className={clsx(classes.availableSessionsWrapper, className)}>
      <Box className={classes.availableSessions}>{children}</Box>

      <Typography variant="subtitleBold" className={classes.duration}>
        {duration && (
          <>
            <TimeIcon />
            {t('v2.global.label_minutes', { count: duration })}
          </>
        )}
        {showGroupSessionTimeLabel && (
          <>
            <TimeIcon />
            {t('v2.global.label_minutes_groupSessions')}
          </>
        )}
      </Typography>
    </Box>
  );
};

export { default as AvailableIndividualSessions } from './available-individual-sessions';
export { default as AvailableGroupSessions } from './available-group-sessions';

export default AvailableSessions;
