import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  videoContainer: {
    position: 'relative',
    width: '100%',
    height: 'auto',
    '& video': {
      display: 'block',
      width: '100%',
      height: 'auto',
    },
  },
  customPlayButton: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '46px',
    backgroundPosition: 'center',
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8,
    },
  },
}));
export default useStyles;
