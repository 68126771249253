import { Box, Typography } from '@mui/material';
import clsx from 'clsx';
import CheckSVG from 'src/assets/icons/check.svg?react';
import useStyles from './styles';

interface PaymentOptionProps {
  id: string;
  checked: boolean;
  empty?: boolean;
  title?: string;
  price?: number;
  description?: string;
  onSelect: (id: string) => void;
  rate?: string;
  previousPrice?: string;
}

const PaymentOption = ({
  id,
  checked,
  empty,
  title,
  price,
  description,
  onSelect,
  rate,
  previousPrice,
}: PaymentOptionProps) => {
  const { classes } = useStyles({ checked });
  return (
    <Box
      className={clsx(
        classes.paymentOption,
        checked && classes.checked,
        empty && classes.empty,
      )}
      onClick={() => onSelect(id)}
    >
      <Box className={classes.check}>{checked && <CheckSVG />}</Box>
      <Typography variant="subtitleBold">{title}</Typography>

      <Box className={classes.price}>
        <Box className={classes.priceTag}>
          <Box className={classes.priceValue}>
            {previousPrice && (
              <span className={classes.previousPrice}>{previousPrice}</span>
            )}
            {price}
          </Box>
          {rate && <Box className={classes.product}>{rate}</Box>}
        </Box>
        <Typography variant="captionSmallBold">{description}</Typography>
      </Box>
    </Box>
  );
};

export default PaymentOption;
