import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  paper: {
    height: 580,
    width: 400,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: theme.spacing(2),
    },
    textAlign: 'left',
    paddingTop: 0,
    display: 'flex',
    flexDirection: 'column',
    placeContent: 'center',
    placeItems: 'center',
    overflowY: 'hidden',
    borderRadius: theme.spacing(2),
  },
  boldTypography: {
    fontWeight: 'bold',
  },
  description: {
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down(320)]: {
      padding: 0,
    },
  },
  topSection: {
    [theme.breakpoints.down(320)]: {
      padding: `0 ${theme.spacing(3)}`,
    },
    padding: theme.spacing(3),
    position: 'absolute',
    top: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    placeContent: 'space-between',
  },
  bottomSection: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3),
    width: '100%',
    paddingBottom: 0,
    bottom: theme.spacing(3),
    marginTop: 'auto',
    textAlign: 'center',
  },
  title: {
    color: theme.colors.darkestGray,
    [theme.breakpoints.down(320)]: {
      padding: 0,
    },
  },
  submitButton: {
    margin: 0,
    fontSize: 16,
    lineHeight: '21px',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    '&:disabled': {
      background: theme.colors.tintedGreen,
      opacity: 0.5,
    },
    boxShadow: 'none',
  },
  links: {
    paddingTop: 24,
    '&:hover': {
      color: theme.colors.mediumDarkGreen,
    },
  },
}));
