import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  contentContainer: {
    marginTop: 40,
    minHeight: '100vh',
  },
  info: {
    marginTop: 16,
  },
  feedback: {
    margin: '42px 0',
    display: 'inline-flex',
    gap: 8,
    alignItems: 'center',
    fontWeight: 700,
    cursor: 'pointer',
    color: theme.colors.mediumGreen,
    '&:hover': {
      color: theme.colors.mediumDarkGreen,
      '& path': {
        fill: theme.colors.mediumDarkGreen,
      },
    },
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
  },
  item: {
    margin: '0 24px 24px 0',
  },
  row: {
    marginBottom: 24,
  },
  dropdownFilters: {
    marginBottom: 24,
  },
  libraryTabs: {
    '& .MuiTabs-scroller': {
      border: 0,
    },
    '& .MuiButtonBase-root': {
      color: theme.colors.darkestGreen,
      '&:not(.Mui-selected)': {
        opacity: 0.7,
      },
    },
    '& .MuiTabs-indicator': {
      height: 4,
      borderRadius: 50,
    },
    '& .MuiTabs-flexContainer': {
      gap: 16,
    },
  },
  libraryTabContent: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    gap: 40,
    marginTop: 40,
  },
  themeList: {
    '& .react-horizontal-scrolling-menu--inner-wrapper': {
      margin: '0 -24px',
    },
  },
  header: {
    marginBottom: -16,
  },
  seeAllContent: {
    width: 'fit-content',
    alignSelf: 'center',
  },
  card: {
    [theme.breakpoints.down('sm')]: {
      minWidth: 300,
    },
  },
  availableLibrary: {
    marginTop: 16,
  },
  timeBox: {
    display: 'inline-flex',
    color: theme.colors.darkestGreen,
    alignItems: 'center',
    gap: 8,
  },
  availableLibraryIcon: {
    width: 22,
    height: 22,
    '& circle': {
      stroke: theme.colors.darkestGreen,
    },
  },
  availableLibraryLabel: {
    display: 'inline-flex',
    flexWrap: 'wrap',
    '& span': {
      textAlign: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      gap: 8,
    },
  },
  divider: {
    height: 18,
    width: 2,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '2px',
      backgroundColor: theme.colors.iron,
    },
  },
}));
