import { useTranslation } from 'react-i18next';
import {
  LibraryItem,
  useGetLibraryItemRecommendation,
} from 'src/apis/library-item-api';
import useSearchParams from 'src/hooks/use-search-params';
import useTrackLibrarySource from '../use-track-library-source';

const useTrackLibraryItemProperties = (libraryItem?: LibraryItem) => {
  const source = useTrackLibrarySource();
  const { searchParams } = useSearchParams();
  const search = searchParams.get('search');
  const {
    i18n: { language },
  } = useTranslation();
  const { data: libraryItemList } = useGetLibraryItemRecommendation({
    language,
  });

  if (!libraryItem) {
    return {};
  }

  const ranking = libraryItemList?.find(
    (item) => item?.id === libraryItem?.id,
  )?.ranking;

  const type = {
    LIBRARY_MEDITATION: 'meditation',
    LIBRARY_EXERCISE: 'exercise',
    LIBRARY_READING: 'reading',
    LIBRARY_RECORDING: 'recording',
  }[libraryItem?.type];

  const duration = {
    LIBRARY_MEDITATION: undefined,
    LIBRARY_EXERCISE: undefined,
    LIBRARY_READING: libraryItem?.readingTime,
    LIBRARY_RECORDING: undefined,
  }[libraryItem?.type];

  const trackProperties = {
    type: 'Library Drawer',
    name: libraryItem?.title,
    topic: libraryItem?.topicKey,
    language: libraryItem?.language,
    isNew: libraryItem?.isNew,
    source,
    itemType: type,
    duration,
    ...(libraryItem?.category && { category: libraryItem?.category }),
    ...(source === 'Home' ? { ranking } : {}),
    search,
  };

  return trackProperties;
};

export default useTrackLibraryItemProperties;
