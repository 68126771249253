import { makeStyles } from 'tss-react/mui';
import { ITheme } from 'src/style/theme';

export const useStyles = makeStyles()((theme: ITheme) => ({
  root: {
    padding: '24px 40px',
  },
  title: {
    fontWeight: 700,
  },
  subtitle: {
    paddingTop: 8,
  },
  callsTitle: {
    paddingTop: 24,
    fontWeight: 700,
  },
  iconsContainer: {
    paddingTop: 8,
  },
  iconWrapper: {
    verticalAlign: 'middle',
    marginTop: 8,
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  content: {
    paddingTop: 16,
  },
  videoContainer: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      display: 'flex',
    },
    marginTop: 24,
  },
}));
