import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useGetAuthUser } from 'src/apis/authentication-api';
import { useLocation, useNavigate } from 'react-router-dom';
import SelectInput from '../shared/select/select';
import useStyles from './styles';

const GreetingBanner = () => {
  const { data: authUser } = useGetAuthUser();
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const isAtDashboardMenu =
    pathname === '/company-admin' && !search.includes('dashboard=company');

  const roles =
    authUser?.signInUserSession?.accessToken?.payload['cognito:groups'];

  const handleChange = ({
    target: { value = 'personal' },
  }: React.ChangeEvent<{ value: unknown }>) => {
    const to = {
      company: {
        pathname: '/company-admin',
        search: 'dashboard=company',
      },
      personal: {
        pathname: '/',
        search: 'dashboard=personal',
      },
    }[value as 'company' | 'personal'];

    navigate(to);
  };

  const personalLabel = t(
    'v2.global.label_dashboardSwitchDropDown_label_personal',
  );
  const companyLabel = t(
    'v2.global.label_dashboardSwitchDropDown_label_company',
  );

  const options = [
    { value: 'personal', name: personalLabel },
    { value: 'company', name: companyLabel },
  ];

  const showSelect = roles?.includes('company_admin') && !isAtDashboardMenu;

  return (
    <Box className={classes.greetingsContainer}>
      {authUser && (
        <Typography variant="subtitleBold">
          {t('GreetingBanner.title')} {authUser.attributes.given_name},{' '}
          {t('ModalFeedback.title')}
        </Typography>
      )}

      {showSelect && (
        <SelectInput
          name="dashboard"
          defaultValue="personal"
          onChange={handleChange}
          options={options}
          dark
        />
      )}
    </Box>
  );
};

export default GreetingBanner;
