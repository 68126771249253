import MuiAlert, { Color } from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import { AlertSeverity } from 'src/style/theme';
import SuccessIcon from './assets/success.svg?react';
import ErrorIcon from './assets/error.svg?react';
import InfoIcon from './assets/info.svg?react';
import WarningIcon from './assets/warning.svg?react';
import CloseIcon from './assets/close.svg?react';
import { useStyles } from './styles';

interface AlertProps {
  severity?: AlertSeverity;
  children: React.ReactNode;
  onClose?: () => void;
  type?: 'popup' | 'static';
  variant?: 'filled' | 'outlined' | 'standard';
}

const Alert = ({
  severity = 'success',
  onClose,
  children,
  type = 'popup',
  variant,
  ...rest
}: AlertProps) => {
  const { classes } = useStyles({ severity, type });

  return (
    <MuiAlert
      classes={classes}
      severity={severity as Color}
      variant={variant}
      iconMapping={{
        success: <SuccessIcon />,
        error: <ErrorIcon />,
        info: <InfoIcon />,
        warning: <WarningIcon />,
      }}
      action={
        onClose ? (
          <IconButton color="inherit" onClick={onClose} size="large">
            <CloseIcon />
          </IconButton>
        ) : null
      }
      {...rest}
    >
      {children}
    </MuiAlert>
  );
};

Alert.defaultProps = {
  severity: 'success',
  onClose: null,
};

export default Alert;
