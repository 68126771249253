import Loader from 'src/components/shared/loader/loader';
import Login from 'src/pages/login/login';
import { useGetAuthUser } from 'src/apis/authentication-api';
import AuthContext from './auth-context';

const AuthProvider = ({ children }: any) => {
  const { data: authUser, status, reloadAuthUser } = useGetAuthUser();
  if (status === 'error') {
    return <Login />;
  }

  if (status === 'loading') {
    return <Loader fullScreen center />;
  }

  return (
    <AuthContext.Provider value={{ authUser, reloadAuthUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
