import { kebabCase } from 'lodash';
import { useLocation } from 'react-router-dom';
import { analyticEvents } from 'src/utils/analytic-events';
import useSearchParams from 'src/hooks/use-search-params';
import { IBookingEvent } from 'src/types';

const useOfferingCard = (source?: string) => {
  const { setSearchParam, searchParams } = useSearchParams();
  const { state, pathname } = useLocation();
  const search = searchParams.get('search');

  const handleShowOfferingDrawer = (
    offering: IBookingEvent,
    historyState?: Record<string, string | boolean>,
  ) => {
    const previous = state?.previous ?? window.location.search;

    analyticEvents.drawerOpened({
      type: 'Group Session Drawer',
      name: offering.name,
      language: offering.language,
      duration: offering.duration,
      itemType: offering.type,
      mentorName: offering?.mentor?.name,
      id: offering.id,
      source: source ?? pathname,
      starter: offering.isBeginnerFriendly,
      recommended: offering.isRecommended,
      isNew: offering.isNew,
      ranking: offering.ranking,
      search,
    });

    setSearchParam(
      {
        'event-id': offering.id,
        name: kebabCase(offering.name),
        utm_campaign: source,
      },
      { previous, ...historyState },
    );
  };

  return { handleShowOfferingDrawer };
};

export default useOfferingCard;
