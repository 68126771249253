import { useTranslation } from 'react-i18next';
import { Box, IconButton, Typography } from '@mui/material';
import IconClose from 'src/assets/icons/close.svg?react';
import { useStyles } from './styles';

interface CloseButtonProps {
  onClose: () => void;
}

const CloseButton = ({ onClose }: CloseButtonProps) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.closeBtnWrapper}>
      <IconButton color="inherit" onClick={onClose} size="large">
        <IconClose className={classes.icon} />
        <Typography variant="buttonDefault">
          {t('v2.global.button_close')}
        </Typography>
      </IconButton>
    </Box>
  );
};

export default CloseButton;
