import clsx from 'clsx';
import { Box } from '@mui/material';
import { scale } from 'src/utils/wellbeing';
import { useStyles } from './styles';

interface ProgressBarPillsProps {
  value: number;
  min?: number;
  max?: number;
  step?: number;
  originalScale: [number, number];
  disabled?: boolean;
}

const ProgressBarPills = ({
  min = 1,
  max = 12,
  step = 1,
  value,
  originalScale,
  disabled,
}: ProgressBarPillsProps) => {
  const { classes } = useStyles();

  const range = Array.from(
    { length: max - min + 1 / step },
    (_, index) => min + index * step,
  );

  const scaledValue = scale(value, originalScale, [0, 12]);

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.bar}>
        {range.map((item, index) => {
          const isFirstBar = index === 0 && !disabled;

          if (isFirstBar)
            return (
              <Box
                key={item}
                className={clsx(classes.item, classes.belowValue)}
              />
            );

          return (
            <Box
              key={item}
              className={clsx(
                classes.item,
                item <= scaledValue && classes.belowValue,
              )}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default ProgressBarPills;
