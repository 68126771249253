import { Box, Typography } from '@mui/material';
import MuiLink from '@mui/material/Link';
import { useStyles } from './styles';

interface FooterLinkProps {
  href?: string;
  onClick?: () => void;
  label: string;
  target?: string;
}

const LinkItem = ({ href, onClick, label, target }: FooterLinkProps) => {
  const { classes } = useStyles();
  return (
    <Box className={classes.linkItem}>
      <MuiLink
        href={href}
        onClick={onClick}
        underline="none"
        className={classes.linkLabel}
        target={target}
      >
        <Typography variant="buttonSmall" component="span">
          {label}
        </Typography>
      </MuiLink>
    </Box>
  );
};

export default LinkItem;
