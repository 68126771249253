import { makeStyles } from 'tss-react/mui';
import { ITheme } from 'src/style/theme';

const useStyles = makeStyles()((theme: ITheme) => ({
  emergencyContact: {
    padding: '15px 24px 24px 23px',
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
  },
  image: {
    width: '100%',
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    padding: '24px 24px 0 24px',
  },
  sectionTitle: {
    fontSize: '20px',
    lineHeight: '26px',
    fontWeight: 700,
  },
  bold: {
    fontWeight: 700,
  },
  helplineLink: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    '& path': {
      fill: theme.colors.mediumGreen,
    },
  },
}));

export default useStyles;
