import React from 'react';
import clsx from 'clsx';
import { Box, Typography } from '@mui/material';
import FullStarIcon from 'src/assets/icons/full-star.svg?react';
import HalfStarIcon from 'src/assets/icons/half-star.svg?react';
import useStyles from './styles';

interface StarReviewProps {
  label?: string;
  rating: number;
  maxScore?: number;
}

const StarReview = ({ label, rating, maxScore = 5 }: StarReviewProps) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.starReview}>
      <Box className={classes.stars}>
        {Array.from({ length: maxScore }).map((_, index) => (
          <React.Fragment key={window.crypto.randomUUID()}>
            {rating > index && rating < index + 1 ? (
              <HalfStarIcon />
            ) : (
              <FullStarIcon
                className={clsx({ [classes.emptyStar]: rating < index + 1 })}
              />
            )}
          </React.Fragment>
        ))}
      </Box>
      <Typography variant="subtitleBold">
        {label ?? rating?.toFixed(1)}
      </Typography>
    </Box>
  );
};

export default StarReview;
