import { useTranslation } from 'react-i18next';
import { useGetUser } from 'src/apis/users-api';
import PageHeader from 'src/components/shared/page-header/page-header';
import AvailableGroupSessions from 'src/components/shared/available-sessions/available-group-sessions';
import LearnMoreAboutGroupSessions from './learn-more-about-group-sessions';
import { useStyles } from './styles';

const GroupSessionsHeader = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { data: user } = useGetUser();

  const option = user?.subscription?.plan?.tiers?.find(
    (plan) => plan.type === 'COMMUNITY_SESSION',
  )?.option;

  return (
    <PageHeader
      title={t('v2.dashboard.section_eventList_label_title')}
      description={t<string>(
        'v2.dashboard.section_eventList_label_body_description_workshopsAndWebinars',
      )}
      id="group-sessions"
      chipLabel={
        option === 'NotAvailable' &&
        t<string>('v2.global.chip_label_plan_notIncluded')
      }
    >
      <LearnMoreAboutGroupSessions source="WEBAPP_GROUP_SESSION_TAB" />
      <AvailableGroupSessions
        className={classes.availableGroupSessions}
        hasBackground
        showGroupSessionTimeLabel
      />
    </PageHeader>
  );
};

export default GroupSessionsHeader;
