import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import authService from 'src/services/auth-service';
import { useAppDispatch } from 'src/store/store';
import { addRedirectUrl } from 'src/store/common';

const PUBLIC_ROUTES = [
  '/page-not-found',
  '/login',
  '/registration',
  '/forgot-password',
  '/reset-password',
  '/activate-account',
];

const useRedirectProtectedRoute = (): void => {
  const dispatch = useAppDispatch();
  const { pathname, search } = useLocation();

  useEffect(() => {
    const skipRedirect = [
      ...PUBLIC_ROUTES,
      '/onboarding',
      '/new-onboarding',
    ].includes(pathname);

    if (!authService.hasAuthInStorage() && !skipRedirect && pathname !== '/') {
      dispatch(addRedirectUrl({ search, pathname }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useRedirectProtectedRoute;
