import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormContext, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { Grid, Box, Button, Snackbar, Typography } from '@mui/material';
import MuiLink from '@mui/material/Link';
import FormInput from 'src/components/shared/form/form-input';
import FormPasswordInput from 'src/components/shared/form/form-password-input';
import { FormBox } from 'src/components/shared/form-box/form-box';
import {
  useAuthenticateUser,
  useResendEmail,
} from 'src/apis/authentication-api';
import FormHeader from 'src/components/shared/form/form-header';
import { useStyles } from './styles';

interface LoginFormFields {
  email: string;
  password: string;
}

const Login = () => {
  const [isUnverifiedEmail, setIsEmailUnverified] = useState<boolean>(false);
  const { classes } = useStyles();
  const { t } = useTranslation();

  const { mutate: authenticateUser, isLoading: authLoading } =
    useAuthenticateUser({
      onUnverified: () => setIsEmailUnverified(true),
    });
  const { mutate: resendEmail } = useResendEmail();

  const methods = useForm<LoginFormFields>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    submitFocusError: false,
  });

  const { handleSubmit } = methods;

  const onSubmit = ({ email, password }: LoginFormFields) => {
    authenticateUser({ email, password });
  };

  const handleResendEmail = () => {
    const email = methods.getValues('email');
    resendEmail(email);
  };

  return (
    <>
      <FormHeader
        title={t('v2.logIn.header_label_title')}
        subtitle={t('v2.logIn.header_label_subTitle')}
      />
      <FormContext {...methods}>
        <FormBox
          component="form"
          p={3}
          noValidate
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {/* @ts-ignore next */}
              <FormInput
                id="login-email"
                type="email"
                name="email"
                label={t('v2.global.textField_label_privateEmail')}
                placeholder={t('v2.global.textField_placeholder_privateEmail')}
                required
              />
              {isUnverifiedEmail && (
                <p className={classes.errorMsg}>
                  {t(
                    'v2.global.textField_errorHandling_helperText_companyEmail_emailNotVerified',
                  )}
                </p>
              )}
            </Grid>
            <Grid item xs={12}>
              {/* @ts-ignore next */}
              <FormPasswordInput
                id="login-password"
                name="password"
                label={t('v2.global.textField_label_password')}
                placeholder={t('v2.global.textField_placeholder_password')}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Box pt={2}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={authLoading}
                >
                  {t('v2.logIn.button_label_logIn')}
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12}>
              {t('v2.global.label_forgotPassword')}
              <MuiLink
                component={Link}
                to="/forgot-password"
                className={classes.links}
              >
                {t('v2.global.button_forgotPassword')}
              </MuiLink>
            </Grid>
            <Grid item xs={12}>
              {t('v2.global.label_createNewAccount')}
              <MuiLink
                component={Link}
                to="/registration"
                className={classes.links}
              >
                {t('v2.global.button_createNewAccount')}
              </MuiLink>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                {t('v2.global.label_needHelp')}
                <MuiLink
                  component="a"
                  className={classes.links}
                  href={`mailto:${import.meta.env.VITE_CONTACT_EMAIL}`}
                >
                  {t('v2.global.button_needHelp')}
                </MuiLink>
              </Typography>
            </Grid>
          </Grid>
        </FormBox>
      </FormContext>
      <Snackbar
        open={isUnverifiedEmail}
        onClose={() => setIsEmailUnverified(false)}
        message={t(
          'v2.global.snackbar_label_verification_resendVerificationEmail',
        )}
        action={
          <Button onClick={handleResendEmail} style={{ color: '#5CABFF' }}>
            {t(
              'v2.global.snackbar_button_verification_resendVerificationEmail',
            )}
          </Button>
        }
      />
    </>
  );
};

export default Login;
