import { IBooking } from 'src/types';
import OfferingDetails from './offering-details/offering-details';
import RequestSessionsModal from '../request-sessions-modal/request-sessions-modal';

interface OfferingDrawerProps {
  scrolled: boolean;
  onClose: () => void;
  isBuyMoreModalOpen: boolean;
  toggleBuyMoreModalOpen: () => void;
  offering: IBooking;
}

const OfferingDrawer = ({
  scrolled,
  onClose,
  isBuyMoreModalOpen,
  toggleBuyMoreModalOpen,
  offering,
}: OfferingDrawerProps) => {
  return (
    <>
      <RequestSessionsModal
        showModal={isBuyMoreModalOpen}
        toggleShow={toggleBuyMoreModalOpen}
        offeringType={offering?.type}
      />

      <OfferingDetails
        offering={offering}
        onClose={onClose}
        scrolled={scrolled}
      />
    </>
  );
};

export default OfferingDrawer;
