import { Box, Snackbar } from '@mui/material';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'src/store/store';
import { addToastInfo, selectCommon } from 'src/store/common';
import Notification from '../components/shared/notification/notification';

export const LikeMindedApp = () => {
  const { toast } = useSelector(selectCommon);

  const dispatch = useAppDispatch();

  const onSnackbarClose = () => {
    dispatch(addToastInfo({ toastInfo: { open: false } }));
  };

  return (
    <>
      <Snackbar
        open={toast?.open}
        autoHideDuration={toast?.autohideDuration ?? 3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={onSnackbarClose}
      >
        <Box>
          {/* @ts-ignore next */}
          <Notification
            variant="outlined"
            severity={toast?.type}
            onClose={onSnackbarClose}
          >
            {toast?.content}
          </Notification>
        </Box>
      </Snackbar>
    </>
  );
};
