/* eslint-disable react/require-default-props */

import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import RecurringIcon from 'src/assets/icons/recurring.svg?react';
import NoVideoIcon from 'src/assets/icons/no-video.svg?react';
import RatingStarIcon from 'src/assets/icons/rating-star.svg?react';
import useStyles from './styles';
import Tooltip from '../tooltip/tooltip';
import MentorAvatar from '../mentor-avatar/mentor-avatar';

interface EventCardTitleProps {
  date: string;
  title: string;
  subtitle?: string;
  isRecurring?: boolean;
  mentor?: {
    name: string;
    profileImage: string;
    id?: string;
  };
  isAnonymous?: boolean;
  rating?: number;
}

export const AnonymousBanner = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <Typography variant="buttonSmall" className={classes.anonymity}>
      <NoVideoIcon />
      {t('v2.global.label_label_anonymousParticipation')}
    </Typography>
  );
};

const EventCardTitle = ({
  date,
  title,
  subtitle,
  isRecurring,
  mentor,
  isAnonymous,
  rating,
}: EventCardTitleProps) => {
  const tooltipTitle = (str: string, maxSize = 55) =>
    str?.length > maxSize && str;

  const { classes } = useStyles();
  const { t } = useTranslation();
  const MINIMUM_RATING = 4.5;

  return (
    <>
      <Typography
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Typography
          variant="buttonSmall"
          className={classes.date}
          component="div"
          style={{
            display: 'block',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            marginRight: 8,
          }}
        >
          {date}
        </Typography>
        <Typography
          variant="buttonSmall"
          className={classes.date}
          component="div"
          style={{
            whiteSpace: 'nowrap',
          }}
        >
          {isRecurring && (
            <Tooltip
              title={t(
                'v2.global.filterChip_label_allCategories_forRegularParticipation',
              )}
            >
              <Box className={classes.recurringIcon}>
                <RecurringIcon />
              </Box>
            </Tooltip>
          )}
          {rating >= MINIMUM_RATING && (
            <Box className={classes.rating}>
              <RatingStarIcon />
              <Typography variant="buttonSmall" className={classes.ratingLabel}>
                {t('v2.global.label_topRated')}
              </Typography>
            </Box>
          )}
        </Typography>
      </Typography>
      {subtitle && (
        <Tooltip title={tooltipTitle(subtitle, 28)}>
          <Typography variant="subtitleBold" className={classes.subtitle}>
            {subtitle}
          </Typography>
        </Tooltip>
      )}
      <Tooltip title={tooltipTitle(title)}>
        <Typography variant="h3" className={classes.title}>
          {title}
        </Typography>
      </Tooltip>
      {isAnonymous && <AnonymousBanner />}
      {mentor?.name && (
        <MentorAvatar
          name={t('v2.global.label_mitAuthor', {
            Author: mentor?.name,
          })}
          profileImage={mentor?.profileImage}
          className={classes.mentor}
          size="small"
        />
      )}
    </>
  );
};

export default EventCardTitle;
