import { useTranslation } from 'react-i18next';
import { useAppConfiguration } from 'src/hooks/use-app-configuration';
import PageHeader from 'src/components/shared/page-header/page-header';
import { AvailableIndividualSessions } from 'src/components/shared/available-sessions/available-sessions';
import { useGetUser } from 'src/apis/users-api';
import { iOSApp } from 'src/constants';
import { useStyles } from './styles';
import LearnMoreAboutIndividualSessions from './learn-more-about-individual-sessions';

const MentorsPageHeader = () => {
  const { t } = useTranslation();
  const { withUnlimitedSessions } = useAppConfiguration();
  const { classes } = useStyles();
  const { data: user } = useGetUser();

  const option = user?.subscription?.plan?.tiers?.find(
    (plan) => plan.type === 'INDIVIDUAL_SESSION',
  )?.option;

  return (
    <PageHeader
      title={t('v2.mentorOverview.header_label_title')}
      description={t<string>('v2.mentorOverview.header_label_subtitle')}
      chipLabel={
        option === 'NotAvailable' &&
        t<string>('v2.global.chip_label_plan_notIncluded')
      }
    >
      <LearnMoreAboutIndividualSessions source="WEBAPP_INDIVIDUAL_SESSION_TAB" />
      {!withUnlimitedSessions && (
        <AvailableIndividualSessions
          hasBuyMore={!iOSApp}
          duration={45}
          className={classes.availableSessions}
        />
      )}
    </PageHeader>
  );
};

export default MentorsPageHeader;
