import clsx from 'clsx';
import { Box, Typography } from '@mui/material';
import { useStyles } from './styles';

export type ChipStatus = 'info' | 'success' | 'error';

interface ChipWithStatus {
  label: string;
  status: ChipStatus;
}

interface ChipListProps {
  list: (ChipWithStatus | string | undefined)[] | undefined;
  style?: React.CSSProperties;
  className?: string;
}

const ChipList = ({ list, style, className }: ChipListProps) => {
  const { classes } = useStyles();

  return (
    <Box className={clsx(classes.chipWrapper, className)} style={style}>
      {list?.filter(Boolean).map((item) => {
        return (
          <Typography
            key={item?.label ?? item}
            className={clsx({
              [classes.chip]: true,
              [classes.info]: item?.status === 'info',
              [classes.success]: item?.status === 'success',
              [classes.error]: item?.status === 'error',
            })}
            variant="buttonSmall"
          >
            {item?.label ?? item}
          </Typography>
        );
      })}
    </Box>
  );
};

export default ChipList;
