import { makeStyles } from 'tss-react/mui';

interface StyleProps {
  active?: boolean;
}

export const useStyles = makeStyles<StyleProps>()(
  (theme, { active }, classes) => ({
    new: {
      '&span': {
        overflow: 'visible',
      },
      '&:after': {
        content: '""',
        position: 'absolute',
        top: 2,
        right: 2,
        width: 8,
        height: 8,
        borderRadius: '100%',
        backgroundColor: theme.colors.curiousBlue,
        zIndex: 3,
      },
    },
    menuItem: {
      position: 'relative',
      marginBottom: theme.spacing(5),
      [theme.breakpoints.up('sm')]: {
        marginBottom: theme.spacing(3),
      },
    },

    menuIcon: {
      marginRight: theme.spacing(1.5),
      padding: theme.spacing(1),
      backgroundColor: active ? theme.colors.blueGreen : 'transparent',
    },
    menuMobileBottom: {
      display: 'flex',
      alignItems: 'center',
      width: 360,
      height: 72,
      padding: '8px 0px 8px 0px',
      gap: 16,
      flexShrink: 0,
    },

    menuLink: {
      display: 'block',
      fontSize: 16,
      lineHeight: '24px',
      '& path': {
        fill: theme.colors.darkestGreen,
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: 14,
        lineHeight: '22px',
      },
      fontWeight: 'bold',
      textDecoration: 'none',
      color: theme.colors.darkestGreen,
      [`&:not(.${classes.menuLinkActive}):hover`]: {
        '&$logoutIcon': {
          backgroundColor: theme.colors.darkestGray,
        },
      },
    },
    menuLinkActive: {
      '& path': {
        fill: theme.colors.white,
      },
    },
    menuLabel: {
      fontWeight: active ? 'bold' : 'normal',
      color: theme.colors.darkestGreen,
    },
    menuButton: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
);
