import { useLocation } from 'react-router-dom';

const useTrackLibrarySource = () => {
  const location = useLocation();

  const source = {
    'booking-confirmation': 'Confirmation',
    '/library': 'Library',
    '/leadership/library': 'Leadership',
  }[location.pathname];

  return location?.state?.source || source;
};

export default useTrackLibrarySource;
