// @ts-nocheck
import React, { useEffect, useRef, useState } from 'react';
import Hls from 'hls.js';
import clsx from 'clsx';
import PlayButton from './assets/play.svg?react';
import useStyles from './style';

interface VideoPlayerProps {
  width?: number;
  height?: number;
  url?: string;
  autoplay?: boolean;
  onPlay?: () => void;
  className?: string;
  poster?: string;
  onEnded?: () => void;
}

const VideoPlayer = ({
  width,
  height,
  url,
  autoplay,
  onPlay,
  poster,
  onEnded,
  className,
}: VideoPlayerProps) => {
  const { classes } = useStyles();
  const videoRef = useRef(null);
  const hlsRef = useRef(null); // Create a ref for the Hls instance
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    const videoElement = videoRef.current;

    if (Hls.isSupported() && url) {
      const hls = new Hls();
      hlsRef.current = hls; // Store the hls instance in the ref
      hls.loadSource(url);
      hls.attachMedia(videoElement);
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        if (autoplay) {
          videoElement.play();
        }
      });
    } else if (videoElement.canPlayType('application/vnd.apple.mpegurl')) {
      videoElement.src = url;
      if (autoplay) {
        videoElement.play();
      }
    }

    return () => {
      // Cleanup Hls.js instance
      if (hlsRef.current) {
        hlsRef.current.destroy();
        hlsRef.current = null;
      }
    };
  }, [url, autoplay]);

  const handlePlay = () => {
    const videoElement = videoRef.current;
    videoElement.play();
    setIsPlaying(true);
    if (onPlay) {
      onPlay();
    }
  };
  return (
    <div
      className={clsx(classes.videoContainer, className, {
        playing: isPlaying,
      })}
    >
      <video
        width={width}
        height={height}
        controls
        poster={poster}
        ref={videoRef}
        onEnded={onEnded}
        onPlay={() => setIsPlaying(true)}
        onPause={() => setIsPlaying(false)}
      />
      {!isPlaying && (
        <PlayButton
          className={classes.customPlayButton}
          onClick={handlePlay}
          aria-label="Play"
        />
      )}
    </div>
  );
};

export default VideoPlayer;
