import { makeStyles } from 'tss-react/mui';
import Box from '@mui/material/Box';
import { ITheme } from 'src/style/theme';
import MenuDesktop from './menu-desktop/menu-desktop';

const useStyles = makeStyles()((theme: ITheme) => ({
  sidebarNavigation: {
    gridArea: 'sidebar',
    display: 'flex',
    width: 'fit-content',
    padding: '0 16px',
    backgroundColor: theme.colors.white,
    height: '95%',
  },
  logo: {
    width: 129,
    height: 18,
  },
}));

const SideBar = () => {
  const { classes } = useStyles();

  return (
    <Box component="aside" className={classes.sidebarNavigation}>
      <MenuDesktop />
    </Box>
  );
};

export default SideBar;
