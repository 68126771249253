import { RefObject, useEffect, useState } from 'react';

const useContainerDimensions = (refList: RefObject<HTMLElement>[]) => {
  const [dimensions, setDimensions] = useState([{ width: 0, height: 0 }]);

  useEffect(() => {
    const getDimensions = () =>
      refList.map((ref) => ({
        width: ref?.current?.offsetWidth ?? 0,
        height: ref?.current?.offsetHeight ?? 0,
      }));

    const handleResize = () => {
      setDimensions(getDimensions());
    };

    if (refList[0].current) {
      setDimensions(getDimensions());
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [refList?.[0]]);

  return dimensions;
};

export default useContainerDimensions;
