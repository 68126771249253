import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { parseJsonTranslation } from 'src/translations/i18n';
import { OfferingFormatContent } from '../group-sessions/offering-format-details';
import FormatInfoDrawerHeader from '../format-info-drawer-header';
import { useStyles } from './styles';

export const MentorsInfo = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const descriptionItems = parseJsonTranslation(
    t('v2.offeringOverview.drawer_descriptionList_individualSessions'),
  );

  return (
    <Box className={classes.root}>
      <FormatInfoDrawerHeader
        title={t('v2.dashboard.section_mentorBanner_label_title')}
        subtitle={t('v2.mentorOverview.header_label_subtitle')}
      />
      <OfferingFormatContent
        descriptionItems={descriptionItems}
        duration={45}
        sessions={1}
      />
    </Box>
  );
};

export default MentorsInfo;
