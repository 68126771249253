import { Mentor } from 'src/apis/mentors-api';

type statuValue = string;
type statusCode = null | undefined | string | number;

export interface IStatus {
  value: statuValue;
  code: statusCode;
}

export interface IError {
  message: string;
  status: string;
  code?: string;
  __type?: string;
}

export enum EventType {
  ONE_ON_ONE = 'ONE_ON_ONE',
  WORKSHOP = 'WORKSHOP',
  WEBINAR = 'WEBINAR',
  EXERCISE = 'EXERCISE',
  EXCHANGE_GROUP = 'EXCHANGE_GROUP',
  MEDITATION = 'MEDITATION',
  READING = 'READING',
  RECORDING = 'RECORDING',
}

export interface IBooking {
  bookingId: string;
  offeringId?: string;
  cancelUrl: string;
  endsAt: string;
  index: number;
  joinUrl: string;
  mentor: {
    id: string;
    name: string;
    profileImage: string;
  };
  name: string;
  startsAt: string;
  type: EventType;
  duration?: number | string;
  sessionInviteId: string;
  offeringName?: string;
  isRecurring?: boolean;
  calendarUrls: {
    google_url: string;
    yahoo_url: string;
    outlook_url: string;
  };
}
export interface ISession {
  id: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  index: number;
  offeringId: string;
  startsAt: string;
  endsAt: string;
  mentorId: string;
}

interface OfferingSubject {
  id: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  beginnerFriendly: boolean;
  isNew?: boolean;
  recurring: boolean;
  rating?: number;
}

export interface IBookingEvent {
  id: string;
  name: string;
  type: EventType;
  description: string;
  maxParticipants: number;
  numberOfBookings: number;
  price: number;
  duration: string;
  bookingUrl: string;
  startDate: string;
  sessions: ISession[];
  language: string;
  topic: string;
  topics: string[];
  mentor?: Mentor;
  isRecommended?: boolean;
  ranking?: number;
  isRecurring?: boolean;
  hasOtherDates?: boolean;
  offeringSubject: OfferingSubject;
  isBeginnerFriendly?: boolean;
  isNew?: boolean;
  isFull?: boolean;
}

export enum cognitoErrorType {
  USER_NOT_FOUND_EXCEPTION = 'UserNotFoundException',
  NOT_AUTHORIZED_EXCEPTION = 'NotAuthorizedException',
  USER_NOT_CONFIRMED_EXCEPTION = 'UserNotConfirmedException',
  USERNAME_EXISTS_EXCEPTION = 'UsernameExistsException',
  USER_LAMBDA_VALIDATION_EXCEPTION = 'UserLambdaValidationException',
  EXPIRED_CODE_EXCEPTION = 'ExpiredCodeException',
  LIMIT_EXCEED_EXCEPTION = 'LimitExceededException',
}

export const invalidBusinessEmail = [
  'PreSignUp failed with error ERROR_INVALID_EMAIL.',
  'PreSignUp failed with error Unknown Domain.',
];

export const invalidEmployeeId = [
  'PreSignUp failed with error ERROR_INVALID_EMPLOYEE_ID.',
];

interface IFormatDescriptionItem {
  value: string;
}

interface IBookingHistoryEvent {
  id: string;
  offeringId: string;
  offeringName: string;
  offeringType: EventType;
  offeringPrice: number;
  bookedAt: string;
  startsAt: string;
  endsAt: string;
  mentorId: string;
  cancelled: boolean;
}

export type IBookingHistory = Array<IBookingHistoryEvent>;

export interface FormatDescription {
  offeringFormat: EventType;
  description: string;
  descriptionItems: IFormatDescriptionItem[];
  videoUrl: string;
  credits: number;
  sessions: number;
  duration: number;
}
