import useAppDrawer from 'src/app/use-app-drawer';
import { useFeatureFlag } from 'src/apis/feature-flag-api';
import BadHotline from './bad-hotline-drawer';
import Footer from './footer';
import { BAD_HOTLINE_FLAG_KEY } from './constants';

export const useShowBADHotline = () =>
  useFeatureFlag({
    flagKey: BAD_HOTLINE_FLAG_KEY,
  });

const useBadHotline = () =>
  useAppDrawer({
    params: ['bad-hotline'],
    track: {
      properties: { type: 'Bad Hotline Drawer' },
    },
    content: <BadHotline />,
    value: 'show',
    footer: <Footer />,
  });

export default useBadHotline;
