import { Typography } from '@mui/material';
import { useStyles } from './group-sessions/styles';

interface FormatInfoDrawerHeaderProps {
  title: string;
  subtitle: string;
}

const FormatInfoDrawerHeader = ({
  title,
  subtitle,
}: FormatInfoDrawerHeaderProps) => {
  const { classes } = useStyles();
  return (
    <>
      <Typography variant="h3" className={classes.title}>
        {title}
      </Typography>
      <Typography className={classes.subtitle} variant="body">
        {subtitle}
      </Typography>
    </>
  );
};

export default FormatInfoDrawerHeader;
