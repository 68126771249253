import * as sessionReplay from '@amplitude/session-replay-browser';
import { TrackProperties, AnalyticsEventName } from 'src/utils/analytic-events';
import backendApi from './config/backend';

interface TrackParams {
  name: AnalyticsEventName;
  properties: TrackProperties;
}

interface RecordSessionReplayParams {
  sessionId?: number;
  deviceId: string;
}

// session-replay documentation
// https://www.docs.developers.amplitude.com/session-replay/sdks/standalone/
export const recordSessionReplay = async ({
  deviceId,
  sessionId = Date.now(),
}: RecordSessionReplayParams) => {
  const { VITE_AMPLITUDE_API_KEY } = import.meta.env;

  await sessionReplay.init(VITE_AMPLITUDE_API_KEY, {
    deviceId,
    sessionId,
    sampleRate: 0.5,
  }).promise;

  const sessionReplayProperties = sessionReplay.getSessionReplayProperties();

  return sessionReplayProperties;
};

export const analyticsTrack = async (params: TrackParams) => {
  const result = await backendApi.post(`/stats/event`, params);
  return result;
};
