import useAppDrawer from 'src/app/use-app-drawer';
import SelfPay from './self-pay';

const useSelfPay = () => {
  return useAppDrawer({
    params: ['self-pay'],
    track: {
      properties: { location: window.location.href, type: 'Self Pay Drawer' },
    },
    content: <SelfPay />,
    value: 'open',
  });
};

export default useSelfPay;
