import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  badHotline: {
    padding: 24,
    '& .vjs-text-track-display': {
      backgroundColor: 'black',
      opacity: 0.05,
    },
  },
  title: {
    marginBottom: 8,
  },
  sections: {
    display: 'grid',
    marginTop: 24,
    gap: 24,
    marginBottom: 100,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 140,
    },
  },
  tags: {
    marginBottom: 24,
    paddingLeft: '0px!important',
  },
  subtitle: {
    color: theme.colors.edward,
  },
  info: {
    color: theme.colors.edward,
  },
  sectionContent: {
    padding: '0 16px',
    '& ul': {
      margin: '24px 0 16px 0',
      paddingLeft: 24,
    },
  },
  footer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      gap: 24,
    },
  },
  telephoneNumber: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    '&:hover': {
      '& path:first-of-type': {
        fill: theme.colors.mediumDarkGreen,
      },
    },
  },
  callNow: {
    width: 'fit-content',
    gap: 8,
    '& path': {
      fill: theme.colors.white,
    },
  },
}));

export default useStyles;
