import { makeStyles } from 'tss-react/mui';

export const useStylesMentorTags = makeStyles()((theme) => ({
  languageLabel: {
    fontWeight: 'bold',
  },
  divider: {
    color: theme.colors.lightGray,
  },
  iconWrapper: {
    verticalAlign: 'middle',
    marginTop: 8,
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  mentorTagsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.up('sm')]: {
      alignItems: 'baseline',
    },
    [theme.breakpoints.down('sm')]: {
      alignItems: 'left',
      flexDirection: 'column',
    },
  },
  languageWrapper: {
    paddingRight: 18,
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 10,
    },
  },
  genderWrapper: {
    display: 'inline-block',
    whiteSpace: 'nowrap',
  },
}));

export const useStylesMentorCard = makeStyles()((theme) => ({
  header: {
    position: 'relative',
    margin: '0 -24px 60px -24px',
  },
  banner: {
    height: 72,
    background: theme.eventColors.ONE_ON_ONE,
    borderRadius: '16px 16px 0px 0px',
  },
  content: {
    minHeight: 340,
  },
  mentorBanner: {
    display: 'flex',
    margin: '24px 0',
    gap: 16,
    alignItems: 'center',
  },
  mentorImage: {
    border: `4px solid ${theme.colors.white}`,
    width: 64,
    height: 64,
    borderRadius: '100%',
  },
  ellipsis: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: 200,
  },
  education: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 280,
    },
    marginBottom: 4,
  },
  description: {
    width: '100%',
    display: '-webkit-box',
    WebkitLineClamp: `${2}`,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    marginBottom: 16,
  },
  section: { margin: '12px 0' },
  sectionTitle: {
    fontSize: 14,
    letterSpacing: 0.25,
    fontWeight: 'bold',
    color: theme.colors.darkGray,
  },
  info: {
    fontSize: 12,
    letterSpacing: 0.4,
    color: theme.colors.darkGray,
    marginBottom: 8,
  },
  mentorCard: {
    height: '100%',
  },
}));
