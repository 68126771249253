import { useEffect } from 'react';
import { Location, useLocation, useNavigate } from 'react-router-dom';

type RedirectForLegacyUrls = Pick<Location, 'pathname' | 'search'>;

export const getRedirectForLegacyUrls = (location: RedirectForLegacyUrls) => {
  const legacyToNewSearch = {
    offeringId: 'event-id',
    mentorId: 'psychological-expert-id',
  };

  const legacyToNewPathname = {
    '/product-assessment': '/onboarding',
    '/mentors': '/psychological-experts',
  };

  const urlParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(urlParams.entries());

  const hasLegacySearch = Object.keys(params).some(
    (key) => legacyToNewSearch[key],
  );

  const hasLegacyUrl =
    !!legacyToNewPathname[location.pathname] || hasLegacySearch;

  const newParams = Object.entries(params).reduce((acc, [key, value]) => {
    const newKey = legacyToNewSearch[key] || key;
    acc[newKey] = value;
    return acc;
  }, {});

  const newPathname =
    legacyToNewPathname[location.pathname] || location.pathname;

  const newUrl = {
    pathname: newPathname,
    search: hasLegacySearch
      ? `?${new URLSearchParams(newParams).toString()}`
      : '',
  };

  return hasLegacyUrl ? newUrl : null;
};

const useRedirectLegacyRoute = (): void => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const legacyUrl = getRedirectForLegacyUrls({ pathname, search });
  useEffect(() => {
    if (legacyUrl) {
      navigate(legacyUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);
};

export default useRedirectLegacyRoute;
