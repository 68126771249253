import { useState } from 'react';
import useResultsFiltered from 'src/hooks/use-results-filtered';
import useSearchParams from 'src/hooks/use-search-params';

const useTopicFilter = () => {
  const [loading, setLoading] = useState(false);
  const { searchParams } = useSearchParams();
  const { resultsFiltered } = useResultsFiltered();

  const handleChangeTopic = (selection: string[]) => {
    resultsFiltered({ topics: selection?.toString() });

    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  const filterByTopic = (events = [], topicKey = 'topicKey') => {
    const topics = searchParams.get('topics');

    if (topics?.includes('allEvents') || !topics) {
      return events;
    }

    const eventsFilteredByTopic = events.filter((item) => {
      if (typeof item[topicKey] === 'string') {
        return topics?.includes(item[topicKey]);
      }

      if (Array.isArray(item[topicKey])) {
        return topics
          .split(',')
          ?.some((topic) => item[topicKey].includes(topic));
      }

      return true;
    });

    return eventsFilteredByTopic;
  };

  return {
    handleChangeTopic,
    loading,
    filterByTopic,
  };
};

export default useTopicFilter;
